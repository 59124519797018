import { Box, Typography, Link } from "@mui/material";

const Footer = () => {
  return (
    <Box 
      component="footer" 
      sx={{ 
        textAlign: "center", 
        p: 1, 
        backgroundColor: "#f8f9fa", 
        mt: 4
      }}
    >
      <Typography variant="body2" color="textSecondary">
        &copy; {new Date().getFullYear()} BotBridge by{" "}
        <Link href="https://www.bizcollab360.com" target="_blank" rel="noopener noreferrer">
          Bizcollab360 Tech Pvt Ltd
        </Link>. All Rights Reserved.
      </Typography>
    </Box>
  );
};

export default Footer;