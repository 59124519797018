import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import { DAILY_HOLDING_ENDPOINT } from '../config/urls';

const columns = [
  { id: ['client.client_code'], label: 'Client Code' },
  { id: 'name', label: 'Name' },
  { id: 'phone', label: 'Phone Number' },
  { id: 'total_portfolio_val', label: 'Total Portfolio' },
  { id: 'asset_precent', label: 'Assets %' },
  { id: 'market_val', label: 'Market Value' },
  { id: 'gain_loss', label: 'Gain/Loss' },
  { id: 'asset_class', label: 'Asset Class' },
  { id: 'symbol_code', label: 'Symbol of Code' },
  { id: 'symbol_name', label: 'Symbol of Name' },
  { id: 'strategy_id', label: 'Strategy ID' },
  { id: 'strategy_name', label: 'Strategy name' },
  { id: 'holding_date', label: 'Holding Date' },
  { id: 'uploaded_on', label: 'Uploaded On ' },
];

export default function HoldingsTable({ searchInput, refreshKey, dateInput }) {
  const [rowCount, setRowCount] = useState(0);
  const [holdings, setHoldings] = useState([]);
  const [searchInputVar, setSearchInputVar] = useState('');

  const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:8000/api`;

  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 20
  });

  useEffect(() => {
    let searchURL = '';
    let searchDateURL = '';
    

    if (searchInput) {
      searchURL = `&search_value=${searchInput}`;
    }

    if (dateInput)  {
      let dateString = dayjs(dateInput).format("YYYY-MM-DD");
        searchDateURL = `&search_date=${dateString}`;
    }
    const tenant = localStorage.getItem('tenant');

    const headers = {
      // "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "X-Tenant-Id": tenant
    };


    const fetchData = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(DAILY_HOLDING_ENDPOINT + `?user_id=${user.id}&limit=${controller.rowsPerPage}&offset=${controller.page * controller.rowsPerPage}` + searchURL + searchDateURL, {headers});
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        setHoldings(jsonData.results);
        setRowCount(jsonData.count);
      } catch (error) {
        console.error('Error fetching data:', error.message);
        setHoldings([]); // Clear holdings on error
        setRowCount(0); // Reset row count on error
      }
    };
    fetchData();
  }, [controller, searchInput, refreshKey, dateInput]);

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, border: '1px solid rgba(224, 224, 224, 1)' }}
                >
                  <h3>{column.label}</h3>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {holdings.length > 0 ? (
              holdings.map((holding, index) => (
                <TableRow key={index}>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{holding.client.client_code}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{holding.client.client_name}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{holding.client.phone_no}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{holding.total_portfolio_val}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{holding.asset_precent}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{holding.market_val}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{holding.gain_loss}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{holding.asset_class}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{holding.symbol_code}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{holding.symbol_name}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{holding.strategy_id}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{holding.strategy_name}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{holding.holding_date}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{holding.file.upload_date}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} align="center" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                  No match found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={rowCount}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

