import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  FormControl,
  FormHelperText,
  Collapse,
  Alert,
  Box,
  TextField,
  FormControlLabel,
  Switch,
  MenuItem,
  Select,
  InputLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { USERS_CREATE_ENDPOINT, USERS_EDIT_ENDPOINT } from '../../config/urls';
import { styled } from '@mui/material/styles';
import { useAuth } from '../../hooks/AuthProvider';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': { padding: theme.spacing(2) },
  '& .MuiDialogActions-root': { padding: theme.spacing(1) },
}));

let ROLES = ["admin", "manager", "user"];

const UserFormDialog = ({ modalState, handleModalState, selectedUser, refreshTable }) => {
  const { getValidToken } = useAuth();
  const [alertObj, setAlertObj] = useState({ active: false, msg: "", type: "" });
  
  const initialFormState = {
    name: '',
    email: '',
    country_code: '91',
    role: 'admin',
    dial_code: '',
    is_active: true,
  };

  const [formData, setFormData] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const tenant = localStorage.getItem("tenant") || null;
  const activePlan = localStorage.getItem("plan") || "whatsapp_lite"

  // PLAN DETAILS (Might change in future)

  if (activePlan === "whatsapp_lite") {
    ROLES = ["admin"];
  }
  else if (activePlan === "whatsapp_advanced") {
    ROLES = ["admin", "user"];
  }
  else if (activePlan === "whatsapp_advanced_custom_solution") {
    ROLES = ["admin", "manager", "user"];
  }

  useEffect(() => {
    if (selectedUser) {
      setFormData({
        name: selectedUser.name || '',
        email: selectedUser.email || '',
        country_code: selectedUser.country_code || '91',
        role: selectedUser.role || 'admin',
        dial_code: selectedUser.dial_code || '',
        is_active: selectedUser.is_active ?? true,
      });
    } else {
      setFormData(initialFormState);
    }
    setFormErrors({}); // Reset errors on modal open
  }, [selectedUser, modalState.isOpen]);

  const handleClose = () => {
    setFormData(initialFormState);
    setFormErrors({});
    handleModalState({ isOpen: false });
  };

  const handleAlertClose = () => {
    setAlertObj({ active: false, type: "", msg: "" });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === "email" ? value?.toLowerCase() : value,
    }));
  };
  

  const handleToggleChange = () => {
    setFormData((prev) => ({ ...prev, is_active: !prev.is_active }));
  };

  const validateForm = () => {
    const newErrors = {};
    let hasErrors = false;
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const phonePattern = /^[0-9]{10}$/;

    if (!formData.name.trim()) {
      newErrors.name = 'Please enter a name';
      hasErrors = true;
    }
    if (!formData.email.trim() || !emailPattern.test(formData.email.trim())) {
      newErrors.email = 'Please enter a valid email';
      hasErrors = true;
    }
    if (!formData.role) {
      newErrors.role = 'Please select a role';
      hasErrors = true;
    }
    if (!formData.dial_code || !phonePattern.test(formData.dial_code)) {
      newErrors.dial_code = 'Enter a valid 10-digit phone number';
      hasErrors = true;
    }

    setFormErrors(newErrors);
    return !hasErrors;
  };

  const handleSubmit = async () => {
    if (!validateForm() || !modalState?.mode) return;

    const apiUrl = modalState.mode === "edit"
      ? `${USERS_EDIT_ENDPOINT}${selectedUser.id}/`
      : USERS_CREATE_ENDPOINT;

    const token = await getValidToken();
    const tenant = localStorage.getItem('tenant');

    axios({
      method: modalState.mode === "edit" ? 'patch' : 'post',
      url: apiUrl,
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "X-Tenant-ID": tenant,
      },
    })
      .then(() => {
        setAlertObj({ active: true, type: "success", msg: `User ${modalState.mode === "edit" ? 'updated' : 'created'} successfully` });
        refreshTable((key) => key + 1);
        
        handleClose();
        setAlertObj({ active: false, type: "success", msg: "" });
      })
      .catch((error) => {
        const errorMessage = error.response?.data || {};
        const errorMessages = Object.values(errorMessage).join(' ');
        setAlertObj({ active: true, type: "error", msg: errorMessages || "Operation failed" });
      });
  };

  useEffect(() => {
      let timeoutId;
      
      if (alertObj.active) {
      timeoutId = setTimeout(() => {
          handleAlertClose();
      }, 5000);
      }
      
      // Cleanup function to clear the timeout when component unmounts or when alertObj changes
      return () => {
          if (timeoutId) {
              clearTimeout(timeoutId);
          }
      };
  }, [alertObj]);

  return (
    <BootstrapDialog onClose={handleClose} open={modalState.isOpen} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2, position: 'relative' }}>
        {modalState.mode === 'edit' ? 'Edit User' : 'Create User'}
        <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Collapse in={alertObj.active}>
          <Alert severity={alertObj.type} onClose={() => setAlertObj({ active: false })}>
            {alertObj.msg}
          </Alert>
        </Collapse>

        <Box sx={{ m: 3 }}>
          <TextField fullWidth margin="normal" label="Name *" name="name" value={formData.name} onChange={handleChange} error={!!formErrors.name} helperText={formErrors.name} />
          <TextField fullWidth margin="normal" label="Email *" name="email" type="email" value={formData.email} onChange={handleChange} error={!!formErrors.email} helperText={formErrors.email} />
          <TextField fullWidth margin="normal" select label="Role *" name="role" value={formData.role} onChange={handleChange} error={!!formErrors.role} helperText={formErrors.role}>
            {ROLES.map((role) => (<MenuItem key={role} value={role}>{role}</MenuItem>))}
          </TextField>
          <FormControl fullWidth margin="normal" error={!!formErrors.country_code}>
             <InputLabel id="country-code-label">Country Code *</InputLabel>
             <Select
               labelId="country-code-label"
               name="country_code"
               value={formData.country_code}
               onChange={handleChange}
             >
               <MenuItem value="91">+91 (IN)</MenuItem>
               <MenuItem value="1">+1 (USA)</MenuItem>
               <MenuItem value="971">+971 (UAE)</MenuItem>
             </Select>
             <FormHelperText>{formErrors.country_code}</FormHelperText>
           </FormControl>
          <TextField fullWidth margin="normal" label="Phone Number *" name="dial_code" value={formData.dial_code} onChange={handleChange} error={!!formErrors.dial_code} helperText={formErrors.dial_code} />
          <FormControlLabel control={<Switch checked={formData.is_active} onChange={handleToggleChange} color="primary" />} label="Active" />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={handleSubmit}>{modalState.mode === 'edit' ? 'Edit' : 'Add'}</Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default UserFormDialog;