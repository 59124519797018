import React, { useState } from 'react';
import { useEffect } from 'react';
import {
    Card,
    CardContent,
    TextField,
    Button,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Alert,
    IconButton,
    Grid
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import QrCodeIcon from '@mui/icons-material/QrCode';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import sampleQRImage from './SampleFiles/SampleQR.png';  // Import the sample image
import { Delete } from '@mui/icons-material';
import { useAuth } from '../../hooks/AuthProvider';

import { GENERATE_QR_ENDPOINT } from '../../config/urls';

const GenerateQRForm = () => {
    const { getValidToken } = useAuth();

    const [formState, setFormState] = useState({
        url: 'https://wa.me/+916005161710',
        color: '#000000',
        logo: null,
        logoUrl: ''
    });
  
    const [isUrlValid, setIsUrlValid] = useState(true);
    
    const [error, setError] = useState('');
    const [qrSuccessMessage, setqrSuccessMessage] = useState('');
    const [qrErrorMessage, setqrErrorMessage] = useState('');

    const [expanded, setExpanded] = useState(false);
    const [logoFile, setLogoFile] = useState(null);

    const [qrFileUrl, setQrFileUrl] = useState(sampleQRImage);

    const validateUrl = (url) => {
        try {
        new URL(url);
        return true;
        } catch {
        return false;
        }
    };

    const handleGenerateQRCode = async () => {
        if (!isUrlValid || formState.url === '') {
            setError('Please enter a valid URL');
            setqrSuccessMessage('');
        } else if (error !== '') {
            setqrSuccessMessage('');
        } else {
            setError('');
            const formData = new FormData();
            formData.append('url', formState.url);
            formData.append('fill_color', formState.color);
            formData.append('file', logoFile);

            const token = await getValidToken();

            // console.log('Payload:', formData);

            const tenant = localStorage.getItem('tenant');

            fetch(GENERATE_QR_ENDPOINT, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    "X-Tenant-ID": tenant
                },
                body: formData,
            })
            .then(response => {
                console.log("API Response:", response);
                if (response.ok) {
                    setqrSuccessMessage('QR code generated successfully');
                    setqrErrorMessage('');
                }
                else {
                    setqrSuccessMessage('');
                    setqrErrorMessage('Failed to generate QR code');
                }
                return response.json();
            })
            .then(data => {
                setQrFileUrl(data.qr_file_url);
            })
            .catch(error => {
                console.error('Error generating QR code:', error);
            });
        }
    };

    const handleDownloadQRCode = () => {
        console.log("qrFileUrl: ", qrFileUrl);
        if (qrFileUrl === sampleQRImage) {
            setqrSuccessMessage('');
            setqrErrorMessage('QR code not generated');
        } else {
            fetch(qrFileUrl)
            .then(response => response.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'QR_code.png'; // Set the desired file name
                a.click();
                URL.revokeObjectURL(url);
            });
        }
    };

    const handleUrlChange = (e) => {
        const newUrl = e.target.value;
        setFormState(prev => ({ ...prev, url: newUrl }));
        setIsUrlValid(validateUrl(newUrl));
    };

    const handleColorChange = (color) => {
        setFormState(prev => ({ ...prev, color }));
    };

    const handleLogoUpload = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        if (!['image/jpeg', 'image/png'].includes(file.type)) {
            setError('Please upload only JPG or PNG files');
            return;
        }

        if (file.size > 5 * 1024 * 1024) {
            setError('Please upload a file less than 5MB');
            return;
        }

        // check if image file is squared
        const reader = new FileReader();
        reader.onload = () => {
            const img = new Image();
            img.onload = () => {
                const { width, height } = img;
                if (width !== height) {
                    setError('Please upload a squared image');
                } else {
                    setError('');
                }
            };
            img.src = reader.result;
        };
        reader.readAsDataURL(file);

        setLogoFile(file);
    };

    const handleDeleteLogo = () => {
        setFormState(prev => ({
            ...prev,
            logo: null,
            logoUrl: ''
        }));

        setLogoFile(null);
    };

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
    }, [
        formState.url,
        formState.color,
        formState.logo,
        qrFileUrl,
        logoFile,
        error,
    ]);

    const handleAlertClose = () => {
        setError('');
        setqrSuccessMessage('');
        setqrErrorMessage('');
    }

    useEffect(() => {
        let timeoutId;
        
        if (error || qrSuccessMessage || qrErrorMessage) {
        timeoutId = setTimeout(() => {
            handleAlertClose();
        }, 5000);
        }
        
        // Cleanup function to clear the timeout when component unmounts or when alert object changes
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [
        error,
        qrSuccessMessage,
        qrErrorMessage
    ]);

    return (
        <Card sx={{ maxWidth: 1200, margin: 'left', mt: 2 }}>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={7}>
                        {/* URL Input Section */}
                        <Accordion 
                            expanded={expanded === 'content'} 
                            onChange={handleAccordionChange('content')}
                        >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Enter Content</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TextField
                                fullWidth
                                label="URL"
                                value={formState.url}
                                onChange={handleUrlChange}
                                error={!isUrlValid && formState.url !== ''}
                                helperText={!isUrlValid && formState.url !== '' ? 'Please enter a valid URL' : ''}
                                placeholder="https://example.com"
                                required
                            />
                        </AccordionDetails>
                        </Accordion>

                        {/* Color Selection Section */}
                        {/* <Accordion 
                        expanded={expanded === 'colors'} 
                        onChange={handleAccordionChange('colors')}
                        >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Set Colors</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', gap: 1 }}>
                            {colors.map((color) => (
                                <IconButton
                                key={color}
                                onClick={() => handleColorChange(color)}
                                sx={{
                                    width: 40,
                                    height: 40,
                                    backgroundColor: color,
                                    border: formState.color === color ? '2px solid #1976d2' : '1px solid #ddd',
                                    '&:hover': {
                                    backgroundColor: color
                                    }
                                }}
                                />
                            ))}
                            </Box>
                        </AccordionDetails>
                        </Accordion> */}

                        {/* Logo Upload Section */}
                        <Accordion 
                            expanded={expanded === 'logo'} 
                            onChange={handleAccordionChange('logo')}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>Add Logo Image</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{ textAlign: 'center' }}>
                                    <input
                                        accept="image/jpeg,image/png"
                                        style={{ display: 'none' }}
                                        id="logo-upload"
                                        type="file"
                                        onChange={handleLogoUpload}
                                    />
                                    <label htmlFor="logo-upload">
                                        <Button
                                            variant="outlined"
                                            component="span"
                                            startIcon={<FileUploadIcon />}
                                        >
                                            Upload PNG or JPG
                                        </Button>
                                    </label>
                                    
                                    {/* simple file name display with delete button */}
                                    {logoFile && (
                                        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="body2" sx={{ mr: 1 }}>
                                            {logoFile.name}
                                        </Typography>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            onClick={handleDeleteLogo}
                                        >
                                            <DeleteIcon 
                                                sx={{ fontSize: 16 }} 
                                            />
                                        </Button>
                                        </Box>
                                    )}
                                </Box>
                            </AccordionDetails>
                        </Accordion>

                        {error && (
                            <Alert severity="error" sx={{ mt: 2 }}>
                                {error}
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    sx={{ ml: 50 }}
                                    onClick={() => setError('')}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            </Alert>
                        )}
                    </Grid>

                    <Grid item xs={12} md={5}>
                        {/* QR Code Preview */}
                        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                            <img 
                                src={qrFileUrl}
                                alt="QR Code"
                                style={{
                                width: 264,
                                height: 264,
                                objectFit: 'contain'
                                }}
                            />
                        </Box>

                        {/* Generate QR Code Button */}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleGenerateQRCode}
                            sx={{ mt: 3, mr: 3 }}
                            startIcon={<QrCodeIcon />}
                        >
                            Generate QR Code
                        </Button>

                        {/* Download QR Code File */}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleDownloadQRCode}
                            sx={{ mt: 3 }}
                            startIcon={<DownloadIcon />}
                        >
                            Download QR Code
                        </Button>

                        {/* Success Message */}
                        {qrSuccessMessage && (
                            <Alert severity="success" sx={{ mt: 2 }}>
                                {qrSuccessMessage}
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    sx={{ ml: 20 }}
                                    onClick={() => setqrSuccessMessage('')}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            </Alert>
                        )}

                        {/* QR code error message */}
                        {qrErrorMessage && (
                            <Alert severity="error" sx={{ mt: 2 }}>
                                {qrErrorMessage}
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    sx={{ ml: 20 }}
                                    onClick={() => setqrErrorMessage('')}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            </Alert>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default GenerateQRForm;