import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer } from 'recharts';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ButtonGroup from '@mui/material/ButtonGroup';
import Select from '@mui/material/Select';


import axios from 'axios';
import { useAuth } from '../../hooks/AuthProvider';

import { LIST_TEMPLATES_ANALYTICS_ENDPOINT } from '../../config/urls';

import MessageNode from './MessageNode';

const getButtonColor = (index) => {
    const colors = ['#00BFA5', '#FF5252', '#651FFF', '#FFC107', '#3F51B5', '#009688'];
    return colors[index % colors.length];
};

const TemplateAnalyticsTab = ({ template }) => {
    // State for date selection (from and to)
    const [fromDate, setFromDate] = useState(dayjs().subtract(6, 'day'));
    const [toDate, setToDate] = useState(dayjs());

    const { getValidToken } = useAuth();

    const [chartLoading, setChartLoading] = useState(false);
    const [chartError, setChartError] = useState(null);
    const [metricsLoading, setMetricsLoading] = useState(false);
    const [metricsError, setMetricsError] = useState(null);

    // Mock data for the graph (would be fetched from API in real implementation)
    const [analyticsData, setAnalyticsData] = useState([]);

    // State for series visibility in chart
    const [visibleSeries, setVisibleSeries] = useState({
        messagesSent: true,
        messagesDelivered: true,
        messagesRead: true,
        replies: true
    });

    // State for dropdown menu to toggle series
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    // Mock performance metrics
    const [performanceMetrics, setPerformanceMetrics] = useState({
        messagesSent: { count: 0, percentage: 0 },
        messagesDelivered: { count: 0, percentage: 0 },
        messagesRead: { count: 0, percentage: 0, readRate: 0 },
        replies: { count: 0, percentage: 0 },
        clicked: []
    });

    const [buttonClicksView, setButtonClicksView] = useState('table'); // 'table' or 'graph'
    const [buttonClicks, setButtonClicks] = useState([]);
    const [buttonClicksLoading, setButtonClicksLoading] = useState(false);
    const [buttonClicksError, setButtonClicksError] = useState(null);
    const [buttonClicksGraphData, setButtonClicksGraphData] = useState([]);
    const [totalClicksType, setTotalClicksType] = useState('total'); // 'total' or 'unique'
    const [buttonAnchorEl, setButtonAnchorEl] = useState(null); // For button toggle menu
    const [visibleButtons, setVisibleButtons] = useState({}); // Track which buttons are visible in the graph

    // for chart data
    useEffect(() => {
        const fetchChartData = async () => {
            try {
                // Reset error state
                setChartError(null);
                setChartLoading(true);
                
                // Format dates to YYYY-MM-DD
                const formattedFromDate = fromDate.format('YYYY-MM-DD');
                const formattedToDate = toDate.format('YYYY-MM-DD');
    
                const token = await getValidToken();
                const tenant = localStorage.getItem('tenant');
                
                // Construct query parameters
                const queryParams = new URLSearchParams({
                    from_date: formattedFromDate,
                    to_date: formattedToDate
                });
                
                // Fetch chart data from the backend
                const response = await axios.get(
                    `${LIST_TEMPLATES_ANALYTICS_ENDPOINT}/${template.template_id}/chart/?${queryParams}`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                            'X-Tenant-Id': tenant
                        }
                    }
                );
                
                // Update state with fetched data
                setAnalyticsData(response.data.analytics_data || []);
                
            } catch (error) {
                console.error('Error fetching chart data:', error);
                // Set error message and empty data
                setChartError(error.response?.data?.error || 'Failed to fetch chart data');
                setAnalyticsData([]);
            } finally {
                setChartLoading(false);
            }
        };
        
        if (template?.template_id) {
            fetchChartData();
        }
    }, [fromDate, toDate, template?.template_id, getValidToken]);

    // for metrics data
    useEffect(() => {
        const fetchMetricsData = async () => {
            try {
                // Reset error state
                setMetricsError(null);
                setMetricsLoading(true);
                
                // Format dates to YYYY-MM-DD
                const formattedFromDate = fromDate.format('YYYY-MM-DD');
                const formattedToDate = toDate.format('YYYY-MM-DD');
    
                const token = await getValidToken();
                const tenant = localStorage.getItem('tenant');
                
                // Construct query parameters
                const queryParams = new URLSearchParams({
                    from_date: formattedFromDate,
                    to_date: formattedToDate
                });
                
                // Fetch metrics data from the backend
                const response = await axios.get(
                    `${LIST_TEMPLATES_ANALYTICS_ENDPOINT}/${template.template_id}/metrics/?${queryParams}`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                            'X-Tenant-Id': tenant
                        }
                    }
                );
                
                // Get metrics data from response
                let apiMetrics = response.data.performance_metrics;
                
                // Add static percentages for frontend display (kept for UI consistency)
                const metricsWithPercentages = {
                    messagesSent: {
                        ...apiMetrics.messagesSent,
                        percentage: 460 // Static value for UI consistency
                    },
                    messagesDelivered: {
                        ...apiMetrics.messagesDelivered,
                        percentage: 460 // Static value for UI consistency
                    },
                    messagesRead: {
                        ...apiMetrics.messagesRead,
                        percentage: 180 // Static value for UI consistency
                    },
                    replies: {
                        ...apiMetrics.replies,
                        percentage: 0 // Static value for UI consistency
                    },
                    clicked: apiMetrics.clicked || []
                };
                
                // Update state with enhanced metrics
                setPerformanceMetrics(metricsWithPercentages);
                
            } catch (error) {
                console.error('Error fetching metrics data:', error);
                // Set error message and empty metrics
                setMetricsError(error.response?.data?.error || 'Failed to fetch metrics data');
                setPerformanceMetrics({
                    messagesSent: { count: 0, percentage: 0 },
                    messagesDelivered: { count: 0, percentage: 0 },
                    messagesRead: { count: 0, percentage: 0, readRate: 0 },
                    replies: { count: 0, percentage: 0 },
                    clicked: []
                });
            } finally {
                setMetricsLoading(false);
            }
        };
        
        if (template?.template_id) {
            fetchMetricsData();
        }
    }, [fromDate, toDate, template?.template_id, getValidToken]);

    // for button click data
    useEffect(() => {
        
        // Check if we have button click data in performanceMetrics
        if (performanceMetrics && performanceMetrics.clicked && performanceMetrics.clicked.length > 0) {
            const clickedData = performanceMetrics.clicked;
            setButtonClicks(performanceMetrics.clicked);

            // Initialize visible buttons state
            const initialVisibleButtons = {};
            clickedData.forEach(button => {
                initialVisibleButtons[button.button_content] = true;
            });
            setVisibleButtons(initialVisibleButtons);
        } else {
            setButtonClicks([]);
            setVisibleButtons({});
        }
        
        // Process button clicks data for graph
        if (analyticsData && analyticsData.length > 0 && analyticsData[0].clicked) {
            // Transform data for Recharts
            const graphData = analyticsData.map(day => {
                const result = { date: day.date };
                if (day.clicked && Array.isArray(day.clicked)) {
                    day.clicked.forEach(button => {
                        // Use button content as the key
                        result[button.button_content] = button.count;
                    });
                }
                return result;
            });
            
            setButtonClicksGraphData(graphData);
        }
    }, [performanceMetrics, analyticsData]);

    // helper function to generate mock chart data
    const generateMockChartData = () => {
        const startDate = dayjs(fromDate).isAfter(toDate) ? toDate : fromDate;
        const endDate = dayjs(fromDate).isAfter(toDate) ? fromDate : toDate;
        
        // Calculate the number of days between dates
        const days = endDate.diff(startDate, 'day') + 1;
        
        const data = [];
        for (let i = 0; i < days; i++) {
            const currentDate = dayjs(startDate).add(i, 'day');
            const formattedDate = currentDate.format('D MMM');
            
            // Generate random data
            const sentCount = Math.floor(Math.random() * 20) + 5;
            const deliveredCount = sentCount - Math.floor(Math.random() * 3);
            const readCount = deliveredCount - Math.floor(Math.random() * 5);
            const replyCount = Math.floor(Math.random() * 3);
            
            data.push({
                date: formattedDate,
                messagesSent: sentCount,
                messagesDelivered: deliveredCount,
                messagesRead: readCount,
                replies: replyCount
            });
        }
        
        return data;
    };

    // Handlers
    const handleFromDateChange = (newDate) => {
        setFromDate(newDate);
    };
    
    const handleToDateChange = (newDate) => {
        setToDate(newDate);
    };

    const handleButtonClicksViewChange = (view) => {
        setButtonClicksView(view);
    };

    const handleTotalClicksTypeChange = (type) => {
        setTotalClicksType(type);
    };

    // Handlers for the button toggle menu
    const handleButtonToggleClick = (event) => {
        setButtonAnchorEl(event.currentTarget);
    };

    const handleButtonToggleClose = () => {
        setButtonAnchorEl(null);
    };

    const handleButtonToggle = (buttonName) => {
        setVisibleButtons({
            ...visibleButtons,
            [buttonName]: !visibleButtons[buttonName]
        });
    };

    const handleExportCSV = async () => {
        try {
            const token = await getValidToken();
            const tenant = localStorage.getItem('tenant');
            
            // Format dates for API query
            const formattedFromDate = dayjs(fromDate).isSame(toDate, 'day') ? 
                fromDate.format('YYYY-MM-DD') : 
                dayjs(fromDate).add(1, 'day').format('YYYY-MM-DD');
            const formattedToDate = toDate.format('YYYY-MM-DD');
            
            const queryParams = new URLSearchParams({
                from_date: formattedFromDate,
                to_date: formattedToDate
            });
            
            // Make API request to export endpoint
            const response = await fetch(`${LIST_TEMPLATES_ANALYTICS_ENDPOINT}/${template.template_id}/export/?${queryParams}`, {
                headers: {
                'Authorization': `Bearer ${token}`,
                'X-Tenant-Id': tenant
                }
            });
            
            if (!response.ok) {
                throw new Error('Failed to export analytics data');
            }
            
            // Create a blob from the response
            const blob = await response.blob();
            
            // Create a link element to download the file
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `template-analytics-${template.template_name}-${formattedFromDate}-to-${formattedToDate}.csv`;
            
            // Append to the document and trigger download
            document.body.appendChild(a);
            a.click();
            
            // Clean up
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error('Error exporting analytics data:', error.message);
            // Add proper error handling/notification here
        }
    };

    const handleEditTemplate = () => {
        console.log('Edit template clicked for:', template);
        // This would navigate to template editing page in the real implementation
    };

    const handleSeriesToggleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSeriesToggleClose = () => {
        setAnchorEl(null);
    };

    const handleSeriesToggle = (series) => {
        setVisibleSeries({
        ...visibleSeries,
        [series]: !visibleSeries[series]
        });
    };

    // Component for metric display
    const MetricDisplay = ({ label, count, percentage, readRate }) => (
        <Box sx={{ textAlign: 'center', p: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
            <Typography variant="subtitle1">
            {label}
            </Typography>
            <Tooltip title={`This shows the ${label.toLowerCase()}`}>
            <InfoIcon fontSize="small" sx={{ ml: 1, color: 'text.secondary' }} />
            </Tooltip>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'center' }}>
            <Typography variant="h4" component="span" sx={{ mr: 1 }}>
            {readRate !== undefined ? `${count} (${readRate}%)` : count}
            </Typography>
            {/* {percentage > 0 && (
            <Typography variant="body2" color="success.main" sx={{ display: 'flex', alignItems: 'center' }}>
                ↑ {percentage}%
            </Typography>
            )} */}
        </Box>
        </Box>
    );

    // CustomTooltip component for the chart
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
        return (
            <Paper sx={{ p: 2, boxShadow: 3 }}>
            <Typography variant="subtitle2">{label}</Typography>
            {payload.map((entry, index) => (
                <Typography key={index} variant="body2" style={{ color: entry.color }}>
                {entry.name}: {entry.value}
                </Typography>
            ))}
            </Paper>
        );
        }
        return null;
    };

    return (
        <Paper sx={{ p: 3, width: '100%' }}>
        {/* Template Header - Name, Language, Status, etc. */}
        <Box sx={{ mb: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h5" component="h2">
                        {template?.template_name || 'Template Name'} • {template?.language || 'English'}
                    </Typography>
                    <Box 
                        sx={{ 
                            ml: 2, 
                            display: 'inline-flex', 
                            backgroundColor: 'success.light', 
                            borderRadius: 1,
                            px: 1, 
                            py: 0.5 
                        }}
                    >
                        <Typography variant="body2" color="common.white">
                            {template?.status || 'Active - Quality Pending'}
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Button 
                        variant="contained" 
                        startIcon={<DownloadIcon />} 
                        onClick={handleExportCSV}
                    >
                        Export CSV
                    </Button>
                </Box>
            </Box>
            <Typography variant="body2" color="text.secondary">
                {/* {template?.category || 'Utility'} • Updated {template?.lastUpdated || '22 Feb 2025'} */}
                {template?.category || 'Utility'}
            </Typography>
        </Box>

        {/* Main content grid */}
        <Grid container spacing={3}>
            {/* Left column */}
            <Grid item xs={12} md={3.5}>
            {/* Template Preview Panel (placeholder) */}
            <Paper sx={{ p: 2, mb: 3, minHeight: 300, bgcolor: 'grey.100' }}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>Your template</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <MessageNode 
                        templateName={template.template_preview_json.templateName}
                        language={template.template_preview_json.language}
                        headerType={template.template_preview_json.headerType}
                        headerText={template.template_preview_json.headerText}
                        headerFile={template.template_preview_json.headerFile}
                        body={template.template_preview_json.body}
                        sampleBodyFields={template.template_preview_json.sampleBodyFields}
                        sampleHeaderFields={template.template_preview_json.sampleHeaderFields}
                        footer={template.template_preview_json.footer}
                        allButtons={template.template_preview_json.allButtons}
                    />
                </Box>
            </Paper>

            {/* Top Block Reason Section (placeholder) */}
            <Paper sx={{ p: 2, minHeight: 100, bgcolor: 'grey.100' }}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                Top block reason
                <Tooltip title="Most common reasons why messages were blocked">
                    <InfoIcon fontSize="small" sx={{ ml: 1, color: 'text.secondary' }} />
                </Tooltip>
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography variant="body2" color="text.secondary">
                    --
                </Typography>
                </Box>
            </Paper>
            </Grid>

            {/* Right column */}
            <Grid item xs={12} md={8.5}>
            {/* Performance Metrics Section */}
            <Paper sx={{ p: 2, mb: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="subtitle1">Performance</Typography>
                    <Tooltip title="Performance metrics for this template">
                    <InfoIcon fontSize="small" sx={{ ml: 1, color: 'text.secondary' }} />
                    </Tooltip>
                </Box>
                
                {/* Date Range Selectors */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack direction="row" spacing={2} alignItems="center">
                    <DatePicker
                        label="From Date"
                        value={fromDate}
                        onChange={handleFromDateChange}
                        slotProps={{
                        textField: { 
                            size: 'small',
                            sx: { width: 150 }
                        }
                        }}
                    />
                    <DatePicker
                        label="To Date"
                        value={toDate}
                        onChange={handleToDateChange}
                        slotProps={{
                        textField: { 
                            size: 'small',
                            sx: { width: 150 }
                        }
                        }}
                    />
                    </Stack>
                </LocalizationProvider>
                </Box>

                {/* Metrics Display */}
                {metricsLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                        <CircularProgress />
                    </Box>
                ) : metricsError ? (
                    <Box sx={{ p: 3, textAlign: 'center' }}>
                        <Typography color="error">{metricsError}</Typography>
                    </Box>
                ) : (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <MetricDisplay 
                            label="Messages sent" 
                            count={performanceMetrics.messagesSent.count} 
                            // percentage={performanceMetrics.messagesSent.percentage} 
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <MetricDisplay 
                            label="Messages delivered" 
                            count={performanceMetrics.messagesDelivered.count} 
                            // percentage={performanceMetrics.messagesDelivered.percentage} 
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <MetricDisplay 
                            label="Messages read" 
                            count={performanceMetrics.messagesRead.count} 
                            percentage={performanceMetrics.messagesRead.percentage}
                            // readRate={performanceMetrics.messagesRead.readRate}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <MetricDisplay 
                            label="Replies" 
                            count={performanceMetrics.replies.count} 
                            // percentage={performanceMetrics.replies.percentage} 
                            />
                        </Grid>
                    </Grid>
                )}
            </Paper>

            {/* Graphical Representation */}
            <Paper sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="subtitle1">Message performance over time</Typography>
                
                {/* Toggle series dropdown */}
                <Box>
                    <Button 
                    variant="outlined" 
                    size="small" 
                    onClick={handleSeriesToggleClick}
                    endIcon={<MoreVertIcon />}
                    >
                    Toggle metrics
                    </Button>
                    <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleSeriesToggleClose}
                    >
                    <MenuItem onClick={() => handleSeriesToggle('messagesSent')}>
                        <FormControlLabel
                        control={
                            <Checkbox 
                            checked={visibleSeries.messagesSent} 
                            onChange={() => handleSeriesToggle('messagesSent')}
                            sx={{ mr: 1 }}
                            />
                        }
                        label="Messages sent"
                        />
                    </MenuItem>
                    <MenuItem onClick={() => handleSeriesToggle('messagesDelivered')}>
                        <FormControlLabel
                        control={
                            <Checkbox 
                            checked={visibleSeries.messagesDelivered} 
                            onChange={() => handleSeriesToggle('messagesDelivered')}
                            sx={{ mr: 1 }}
                            />
                        }
                        label="Messages delivered"
                        />
                    </MenuItem>
                    <MenuItem onClick={() => handleSeriesToggle('messagesRead')}>
                        <FormControlLabel
                        control={
                            <Checkbox 
                            checked={visibleSeries.messagesRead} 
                            onChange={() => handleSeriesToggle('messagesRead')}
                            sx={{ mr: 1 }}
                            />
                        }
                        label="Messages read"
                        />
                    </MenuItem>
                    <MenuItem onClick={() => handleSeriesToggle('replies')}>
                        <FormControlLabel
                        control={
                            <Checkbox 
                            checked={visibleSeries.replies} 
                            onChange={() => handleSeriesToggle('replies')}
                            sx={{ mr: 1 }}
                            />
                        }
                        label="Replies"
                        />
                    </MenuItem>
                    </Menu>
                </Box>
                </Box>

                {/* Line Chart */}
                {chartLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                        <CircularProgress />
                    </Box>
                ) : chartError ? (
                    <Box sx={{ p: 3, textAlign: 'center' }}>
                        <Typography color="error">{chartError}</Typography>
                    </Box>
                ) : (
                    <Box sx={{ height: 300, width: '100%' }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                            data={analyticsData}
                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                            >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <RechartsTooltip content={<CustomTooltip />} />
                            <Legend />
                            {visibleSeries.messagesSent && (
                                <Line 
                                type="monotone" 
                                dataKey="messagesSent" 
                                stroke="#FF5252" 
                                name="Messages sent" 
                                activeDot={{ r: 8 }}
                                />
                            )}
                            {visibleSeries.messagesDelivered && (
                                <Line 
                                type="monotone" 
                                dataKey="messagesDelivered" 
                                stroke="#651FFF" 
                                name="Messages delivered" 
                                />
                            )}
                            {visibleSeries.messagesRead && (
                                <Line 
                                type="monotone" 
                                dataKey="messagesRead" 
                                stroke="#00BFA5" 
                                name="Messages read" 
                                />
                            )}
                            {visibleSeries.replies && (
                                <Line 
                                type="monotone" 
                                dataKey="replies" 
                                stroke="#00796B" 
                                name="Replies" 
                                />
                            )}
                            </LineChart>
                        </ResponsiveContainer>
                    </Box>
                )}
            </Paper>

            {/* Button Clicks Section - Only show if the template has buttons */}
            {buttonClicks.length > 0 && (
                <Paper sx={{ p: 2, mt: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="subtitle1">Button clicks</Typography>
                            <Tooltip title="Button clicks are reported up to 7 days after a message is sent.">
                                <InfoIcon fontSize="small" sx={{ ml: 1, color: 'text.secondary' }} />
                            </Tooltip>
                        </Box>

                        {/* <p>{template?.category}</p> */}
                        
                        {/* View toggle buttons */}
                        <Box>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                {/* Total clicks type selector (shown only for marketing templates) */}
                                {template?.category === 'MARKETING' && (
                                    <FormControl size="small">
                                        <Select
                                            value={totalClicksType}
                                            onChange={(e) => handleTotalClicksTypeChange(e.target.value)}
                                            displayEmpty
                                            sx={{ minWidth: 130 }}
                                        >
                                            <MenuItem value="total">Total clicks</MenuItem>
                                            {/* <MenuItem value="unique">Unique clicks</MenuItem> */}
                                        </Select>
                                    </FormControl>
                                )}
                                
                                {/* Table/Graph toggle */}
                                <ButtonGroup variant="outlined" size="small">
                                    <Button 
                                        variant={buttonClicksView === 'table' ? 'contained' : 'outlined'}
                                        onClick={() => handleButtonClicksViewChange('table')}
                                    >
                                        Table
                                    </Button>
                                    <Button 
                                        variant={buttonClicksView === 'graph' ? 'contained' : 'outlined'}
                                        onClick={() => handleButtonClicksViewChange('graph')}
                                    >
                                        Graph
                                    </Button>
                                </ButtonGroup>
                            </Box>
                        </Box>
                    </Box>

                    {/* Display based on view selection */}
                    {buttonClicksLoading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                            <CircularProgress />
                        </Box>
                    ) : buttonClicksError ? (
                        <Box sx={{ p: 3, textAlign: 'center' }}>
                            <Typography color="error">{buttonClicksError}</Typography>
                        </Box>
                    ) : buttonClicksView === 'table' ? (
                        /* Table View */
                        <TableContainer component={Paper} variant="outlined">
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Label</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Total clicks</TableCell>
                                        <TableCell>Clicks vs previous period</TableCell>
                                        <TableCell>Click rate</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {buttonClicks.length > 0 ? (
                                        buttonClicks.map((button, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{button.button_content}</TableCell>
                                                <TableCell>{button.type}</TableCell>
                                                <TableCell>{button.count}</TableCell>
                                                <TableCell>--</TableCell>
                                                <TableCell>
                                                    {performanceMetrics.messagesSent.count > 0 
                                                        ? `${Math.round((button.count / performanceMetrics.messagesSent.count) * 100)}%` 
                                                        : '0%'
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={5} align="center">No button click data available</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <>
                            {/* Button metrics display and toggle */}
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                                {/* Individual button metrics display */}
                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                    {buttonClicks.map((button, index) => (
                                        <Grid item xs={12} sm={6} md={3} key={index}>
                                            <Box sx={{ 
                                                textAlign: 'center', 
                                                p: 2, 
                                                border: '1px solid', 
                                                borderColor: 'divider',
                                                borderRadius: 1,
                                                backgroundColor: visibleButtons[button.button_content] ? 'rgba(0, 0, 0, 0.04)' : 'transparent'
                                            }}>
                                                <Typography variant="subtitle2">{button.button_content}</Typography>
                                                <Typography variant="caption" display="block" color="text.secondary">
                                                    {button.type.replace('_', ' ')}
                                                </Typography>
                                                <Typography variant="h5" sx={{ mt: 1 }}>
                                                    {button.count} <Typography component="span" variant="caption" color="text.secondary">
                                                        ({performanceMetrics.messagesSent.count > 0 
                                                            ? `${Math.round((button.count / performanceMetrics.messagesSent.count) * 100)}%` 
                                                            : '0%'})
                                                    </Typography>
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                                
                                {/* Toggle buttons control */}
                                <Box>
                                    <Button 
                                        variant="outlined" 
                                        size="small" 
                                        onClick={handleButtonToggleClick}
                                        endIcon={<MoreVertIcon />}
                                        sx={{ width: 170 }}
                                    >
                                        Toggle buttons
                                    </Button>
                                    <Menu
                                        anchorEl={buttonAnchorEl}
                                        open={Boolean(buttonAnchorEl)}
                                        onClose={handleButtonToggleClose}
                                    >
                                        {buttonClicks.map((button, index) => (
                                            <MenuItem key={index} onClick={() => handleButtonToggle(button.button_content)}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox 
                                                            checked={visibleButtons[button.button_content]} 
                                                            onChange={() => handleButtonToggle(button.button_content)}
                                                            sx={{ mr: 1 }}
                                                        />
                                                    }
                                                    label={button.button_content}
                                                />
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>
                            </Box>
                            
                            {/* Line Chart */}
                            <Box sx={{ height: 300, width: '100%' }}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart
                                        data={buttonClicksGraphData}
                                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="date" />
                                        <YAxis />
                                        <RechartsTooltip content={<CustomTooltip />} />
                                        <Legend />
                                        {buttonClicks.map((button, index) => (
                                            visibleButtons[button.button_content] && (
                                                <Line 
                                                    key={index}
                                                    type="monotone" 
                                                    dataKey={button.button_content} 
                                                    stroke={getButtonColor(index)} 
                                                    name={button.button_content} 
                                                />
                                            )
                                        ))}
                                    </LineChart>
                                </ResponsiveContainer>
                            </Box>
                        </>
                    )}
                </Paper>
            )}
            </Grid>
        </Grid>
        </Paper>
    );
};

export default TemplateAnalyticsTab;