import { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Alert, InputAdornment, CircularProgress, Collapse, Box, Paper } from '@mui/material';
import { useAuth } from "../hooks/AuthProvider";
import Logo from "./Logo.js";
import Person from '@mui/icons-material/Person';
import axios from 'axios';
import Footer from './Footer.js';
import { LOGIN_ENDPOINT } from '../config/urls';

const Login = () => {
  const [input, setInput] = useState({
    email: "",
    key: "",
    otp: ""
  });

  const auth = useAuth();
  const [email, setEmail] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [message, setMessage] = useState('OTP sent to your email.');
  const [error, setError] = useState(false);
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false); // Separate loading state for Resend OTP
  const [open, setOpen] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let interval = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0 && interval) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleGenerateOTP = async (event) => {
    event.preventDefault();
    if (!email.trim()) {
      setError(true);
      setMessage('Enter a valid business email address.');
      return;
    }

    setLoadingResend(true); // Use loadingResend for Resend OTP
    try {
      const response = await axios.post(LOGIN_ENDPOINT, { email });
      setOtpSent(true);
      setError(false);
      sessionStorage.setItem("key", response.data.key);
      setMessage(response.data.msg);
      setTimer(60);
    } catch (error) {
      setOtpSent(false);
      setError(true);
      setMessage('Invalid email, please contact administrator.');
    } finally {
      setLoadingResend(false);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,6}$/.test(value)) {
      setOtp(value);
      setError(false);
      setMessage('');
    } else {
      setError(true);
      setMessage('Please enter a 6-digit numeric OTP.');
    }
  };

  const handleVerifyOTP = async (event) => {
    event.preventDefault();
    if (!otp.trim() || !/^\d{6}$/.test(otp)) {
      setError(true);
      setMessage('Enter a valid 6-digit OTP.');
      return;
    }

    setLoading(true);
    try {
      const sessionKey = sessionStorage.getItem('key');
      const updatedInput = { ...input, key: sessionKey, otp };
      setInput(updatedInput);
      let actionLogin = await auth.loginAction(updatedInput);
      actionLogin.then((result) => {
        if (result.type === "error") {
          setError(true);
          setMessage('Invalid OTP.');
        } else {
          setError(false);
          setIsLoggedIn(true);
        }
      });
    } catch (error) {
      setError(true);
      setMessage('Invalid OTP.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <Paper elevation={6} sx={{ p: 5, maxWidth: 375, textAlign: 'center', borderRadius: 3, backgroundColor: 'white' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <Logo />
        </Box>
        <Typography variant="h5" fontWeight={600} gutterBottom>
          {otpSent ? 'Verify OTP' : 'Sign In'}
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Secure access to your account
        </Typography>
        <hr />
        {otpSent ? (
          <form onSubmit={handleVerifyOTP}>
            <Collapse in={open}>
              <Alert severity="success" onClose={() => setOpen(false)}>
                OTP sent to your email
              </Alert>
            </Collapse>
            <TextField
              id="otp"
              name="otp"
              label="Enter OTP"
              variant="outlined"
              size="small"
              fullWidth
              value={otp}
              error={error}
              helperText={error ? message : ""}
              onChange={handleChange}
              sx={{ my: 2 }}
            />
            <Button type="submit" variant="contained" size="large" color="primary" disabled={otp.length !== 6 || loading} fullWidth sx={{ borderRadius: 2, mt: 1 }}>
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Verify OTP'}
            </Button>
            <Button variant="text" size="large" fullWidth disabled={timer > 0 || loadingResend} onClick={handleGenerateOTP}>
              {loadingResend ? <CircularProgress size={24} color="inherit" /> : `Resend OTP ${timer > 0 ? `(${timer}s)` : ''}`}
            </Button>
          </form>
        ) : (
          <form onSubmit={handleGenerateOTP}>
            <TextField
              id="email"
              name="email"
              label="Business Email"
              variant="outlined"
              size="small"
              type="email"
              fullWidth
              error={error}
              helperText={error ? message : ""}
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Person />
                  </InputAdornment>
                ),
              }}
              sx={{ my: 2 }}
            />
            <Button type="submit" variant="contained" size="large" color="primary" fullWidth sx={{ borderRadius: 2, mt: 1 }}>
              {loadingResend ? <CircularProgress size={24} color="inherit" /> : 'Send OTP'}
            </Button>
          </form>
        )}
      <Footer />
      </Paper>
    </Container>
    </>
  );
};

export default Login;
