import React from 'react';
import { CssBaseline, Container, Box, Typography } from '@mui/material';
import ChatHistoryWindow from './ChatHistoryWindow';
import ChatHistoryList from './ChatHistoryList';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ChatHistoryProvider } from '../hooks/ChatHistoryProvider';

const theme = createTheme({
    palette: {
    primary: {
      main: '#1976d2',
    },
    background: {
      default: '#f5f5f5',
    },
  },
});

export default function ChatHistoryHome() {
    return (
        // <ThemeProvider theme={theme}>
        // <CssBaseline />
      <ChatHistoryProvider>
        <Container maxWidth="">
        <Box display="flex" height="80vh">
          <Box width="25%" bgcolor="primary.main" color="white">
            <Typography variant="h6" component="div" padding={2}>
              Chats
            </Typography>
            <ChatHistoryList />
          </Box>
          <Box width="75%" bgcolor="white">

            <ChatHistoryWindow />
          </Box>
        </Box>
      </Container>
      </ChatHistoryProvider>
        // </ThemeProvider>
    )
}

