import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Paper,
  IconButton,
  Typography,
  Box,
  Chip,
  Divider,
  Grid
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

// Styled components
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    maxWidth: '800px',
    width: '100%'
  }
}));

const FeedbackItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1.5),
  borderBottom: `1px solid ${theme.palette.divider}`
}));

const FeedbackResults = ({ 
  open, 
  onClose,
  feedback
}) => {
  const getChipColor = (value) => {
    if (!value) return 'default';
    value = value.toLowerCase();
    if (value === 'satisfied' || value === 'happy' || value === 'yes') {
      return 'success';
    } else if (value === 'not_satisfied' || value === 'unhappy' || value === 'no') {
      return 'error';
    } else if (value === 'no_comments') {
      return 'warning';
    }
    return 'default';
  };

  const formatValue = (value) => {
    if (!value) return 'N/A';
    value = value.toLowerCase();
    // Convert snake_case to readable text
    if (value === 'not_satisfied') return 'Not Satisfied';
    if (value === 'no_comments') return 'No Comments';
    // Capitalize first letter
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const FeedbackSection = ({ title, data }) => (
    <Paper elevation={2} sx={{ mb: 3, overflow: 'hidden' }}>
      <Box sx={{ bgcolor: 'primary.main', color: 'primary.contrastText', p: 2 }}>
        <Typography variant="h6" component="h2">
          {title}
        </Typography>
      </Box>
      <Box>
        {Object.entries(data || {}).map(([question, answer]) => (
          <FeedbackItem key={question}>
            <Typography variant="body1" sx={{ flex: 1, mr: 2 }}>{question}</Typography>
            <Chip
              label={formatValue(answer)}
              color={getChipColor(answer)}
              size="medium"
            />
          </FeedbackItem>
        ))}
      </Box>
    </Paper>
  );

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography variant="h5" component="h1">
          Feedback Results
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {(feedback?.serviceFeedback) && (Object.keys(feedback?.serviceFeedback).length > 0) &&
          <FeedbackSection
            title="Service Feedback"
            data={feedback?.serviceFeedback}
          />
        }
        {(feedback?.portfolioPerformance) && (Object.keys(feedback?.portfolioPerformance).length > 0) &&
          <FeedbackSection
            title="Portfolio Performance"
            data={feedback?.portfolioPerformance}
          />
        }
      </DialogContent>
    </StyledDialog>
  );
};

export default FeedbackResults;