import * as React from "react";
 
import { useState } from 'react';
import CampaignManagementTable from './CampaignManagementTable';
import BroadcastCampaigns from './BroadcastCampaigns';
import ScheduleBroadcastCampaign from './ScheduleBroadcastCampaign';
import {
  Grid,
  TextField,
  Typography,
  Container,
  Box,
  Tabs,
  Tab,
  Divider
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';

const CampaignManagement = (props) => { 
  
  const [activeTab, setActiveTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);

  const handleSearchQueryChange = (event) => {
    if (event.target.value.length > 50) {
      return;
    }

    const value = event.target.value;
    setSearchQuery(value);   
    setCharCount(value.length);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    // Reset search when changing tabs
    setSearchQuery('');
    setCharCount(0);
    // Refresh data when switching tabs
    setRefreshKey(prev => prev + 1);
  };

  return (
    <div className="App">
      <Container maxWidth={false}>
        <h2 className="heading-title">Campaign Management</h2>
        {/* Tab Navigation */}
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
          <Tabs 
            value={activeTab} 
            onChange={handleTabChange}
            aria-label="campaign management tabs"
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: '#4CAF50',
                height: 3
              }
            }}
          >
            <Tab label="Broadcast Campaigns" />
            <Tab label="Scheduled Broadcasts" />
          </Tabs>
        </Box>

        {/* Search Field */}
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              fullWidth
              size="small"
              placeholder="Search campaigns..."
              title='Search by Campaign Name'
              value={searchQuery}
              onChange={handleSearchQueryChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="caption">{charCount}/50</Typography>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>

        {/* Content based on active tab */}
        {activeTab === 0 ? (
          <BroadcastCampaigns 
            refreshKey={refreshKey}
            searchQuery={searchQuery} 
          />
        ) : (
          <ScheduleBroadcastCampaign 
            refreshKey={refreshKey} 
            searchQuery={searchQuery} 
          />
        )}
      </Container>
    </div>
  );
}

export default CampaignManagement;