import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { CopyAll } from '@mui/icons-material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs, oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';

const WAChatWidget = () => {
  const [phoneNumber, setPhoneNumber] = useState('9999999999');
  const [countryCode, setCountryCode] = useState('+1');
  const [message, setMessage] = useState('Sample Welcome Text');
  const [cardTitle, setCardTitle] = useState('Title');
  const [iconPlacement, setIconPlacement] = useState('bottom-right');
  const [buttonBgColor, setButtonBgColor] = useState('#25d366');
  const [generatedScript, setGeneratedScript] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const countryPhoneLengths = {
    "+1": 10, // USA, Canada
    "+44": 10, // UK
    "+91": 10, // India
    "+971": 9, // UAE
    "+61": 9, // Australia
  };

  const handleGenerateScript = () => {
    if (phoneNumber && message && cardTitle && iconPlacement && buttonBgColor) {
      const script = `
        <script>
          (function createWhatsAppWidget() {
            const phoneNumber = '${countryCode}${phoneNumber}';
            const defaultMessage = '${message.trim()}';
            const cardTitle = '${cardTitle.trim()}';
            const iconPlacement = '${iconPlacement}';
            const buttonBgColor = '${buttonBgColor}';

            // Your Widget Code Here...
            const styles = \`
               /* Add your CSS styles here */
               body {
                  font-family: 'Roboto', sans-serif;
                  margin: 0;
                  padding: 0;
                  box-sizing: border-box;
                }
                #whatsapp-widget-container {
                  position: fixed;
                  bottom: 20px;
                  ${iconPlacement === 'bottom-left' ? 'left: 20px;' : 'right: 20px;'}
                  z-index: 9999;
                }
                #whatsapp-widget {
                  width: 60px;
                  height: 60px;
                  background-color: \${buttonBgColor};
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
                  cursor: pointer;
                  transition: transform 0.3s ease;
                }
                #whatsapp-widget:hover {
                  transform: scale(1.1);
                }
                #whatsapp-widget img {
                  width: 35px;
                  height: 35px;
                }
                #whatsapp-popup {
                  position: absolute;
                  bottom: 80px;
                  ${iconPlacement === 'bottom-left' ? 'left: 0;' : 'right: 0;'}
                  background-color: #ffffff;
                  width: 320px;
                  border-radius: 8px;
                  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
                  display: none;
                  flex-direction: column;
                  overflow: hidden;
                }
                #whatsapp-header {
                  background-color: \${buttonBgColor};
                  color: #fff;
                  padding: 15px;
                  text-align: center;
                  font-size: 18px;
                  font-weight: 500;
                  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                }
                #whatsapp-body {
                  padding: 20px;
                }
                #whatsapp-body label {
                  font-size: 14px;
                  color: #333;
                  display: block;
                  margin-bottom: 8px;
                }
                #whatsapp-body input {
                  width: calc(100% - 30px);
                  padding: 10px;
                  margin-bottom: 15px;
                  border: 1px solid #ddd;
                  border-radius: 5px;
                  font-size: 16px;
                }
                #whatsapp-body button {
                  width: calc(100% - 10px);
                  background-color: \${buttonBgColor};
                  color: white;
                  padding: 12px;
                  border: none;
                  border-radius: 5px;
                  font-size: 16px;
                  font-weight: 500;
                  cursor: pointer;
                  transition: background-color 0.3s ease;
                }
               #whatsapp-body button:hover {
                 background-color: #1da851;
               }
             \`;

             const styleSheet = document.createElement('style');
             styleSheet.type = 'text/css';
             styleSheet.innerText = styles;
             document.head.appendChild(styleSheet);

             const container = document.createElement('div');
             container.id = 'whatsapp-widget-container';

             const widget = document.createElement('div');
             widget.id = 'whatsapp-widget';
             widget.innerHTML = \`
               <img src="https:upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="WhatsApp">
             \`;

             const popup = document.createElement('div');
             popup.id = 'whatsapp-popup';
             popup.innerHTML = \`
               <div id="whatsapp-header">\${cardTitle}</div>
               <div id="whatsapp-body">
                 <label for="message">Message</label>
                 <input type="text" id="message" placeholder="Please Type a message" value="\${defaultMessage}" />
                 <button id="send-btn">Send</button>
               </div>
             \`;

             container.appendChild(widget);
             container.appendChild(popup);

             document.body.appendChild(container);

             widget.addEventListener('click', () => {
               popup.style.display = popup.style.display === 'flex' ? 'none' : 'flex';
             });

             document.getElementById('send-btn').addEventListener('click', () => {
               const userMessage = document.getElementById('message').value.trim();
               if (userMessage) {
                 const url = \`https:wa.me/\${phoneNumber}?text=\${encodeURIComponent(userMessage)}\`;
                 window.open(url, '_blank');
               } else {
                 alert('Please type a message before sending!');
               }
             });
          })();
        </script>
      `;

      setGeneratedScript(script.trim());
      setShowAlert(true);
    } else {
      window.alert('Please fill in all fields.');
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(generatedScript).then(() => {
      window.alert('Script copied to clipboard!');
    });
  };

  const validatePhoneNumber = (phone, code = countryCode) => {
    const cleanedPhone = phone.replace(/\D/g, ""); // Remove non-digit characters
    const expectedLength = countryPhoneLengths[code];
      
    if (cleanedPhone.length !== expectedLength) {
      return `Phone number must be ${expectedLength} digits long for ${code}.`;
    }
    return "";
  };

  const handleCountryCodeChange = (e) => {

    const newCountryCode = e.target.value;
    setCountryCode(newCountryCode);
    // Validate phone number length for new country code
    const errorMessage = validatePhoneNumber(phoneNumber, newCountryCode);
    setError(errorMessage);
  };
  
  const handlePhoneNumberChange = (e) => {
    const input = e.target.value;
    setPhoneNumber(input);
    setError(validatePhoneNumber(input));
  };

  const handleAlertClose = () => {
    setShowAlert(false);
  };

  useEffect(() => {
    let timeoutId;
    
    if (showAlert) {
      timeoutId = setTimeout(() => {
          handleAlertClose();
      }, 5000);
    }
    
    // Cleanup function to clear the timeout when component unmounts or when alert changes
    return () => {
      if (timeoutId) {
          clearTimeout(timeoutId);
      }
    };
  }, [showAlert]);

  return (
    <Card sx={{ maxWidth: 1100, margin: 'auto', mt: 2, p: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Generate WhatsApp Widget Script
        </Typography>
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Country Code</InputLabel>
            <Select
              value={countryCode}
              onChange={handleCountryCodeChange}
              label="Country Code"
            >
              {Object.keys(countryPhoneLengths).map((code) => (
                <MenuItem key={code} value={code}>
                  {code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Country Code</InputLabel>
            <Select
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              label="Country Code"
            >
              <MenuItem value="+1">+1 (USA)</MenuItem>
              <MenuItem value="+44">+44 (UK)</MenuItem>
              <MenuItem value="+91">+91 (India)</MenuItem>
              <MenuItem value="+971">+971 (UAE)</MenuItem>
              <MenuItem value="+61">+61 (Australia)</MenuItem>
            </Select>
          </FormControl> */}
          <TextField
            fullWidth
            label="Phone Number"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            onKeyDown={(e) => {
              if (!/^\d$/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete") {
                e.preventDefault();
              }
            }}
            placeholder="Enter phone number"
            error={!!error}
            helperText={error}
            sx={{ mb: 2 }}
            inputProps={{ maxLength: 10 }}
            required={true}
          />
          {/* <TextField
            fullWidth
            label="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter phone number"
            sx={{ mb: 2 }}
          /> */}
          <TextField
              fullWidth
              label="Card Title"
              value={cardTitle}
              onChange={(e) => setCardTitle(e.target.value)}
              placeholder="Enter card title"
              sx={{ mb: 2 }}
              inputProps={{ maxLength: 25 }}
              helperText={cardTitle.trim() === "" ? "Card title is required." : "Maximum length: 25 characters."}
              error={cardTitle.trim() === ""}
              required={true}
            />
          <TextField
            fullWidth
            label="Welcome Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Enter default message"
            sx={{ mb: 2 }}
            multiline="true"
            rows={2}
            inputProps={{ maxLength: 100 }}
            helperText={message.trim() === "" ? "Message is required." : "Maximum length: 100 characters."}
            error={message.trim() === ""}
            required={true}
          />

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Icon Placement</InputLabel>
            <Select
              value={iconPlacement}
              onChange={(e) => setIconPlacement(e.target.value)}
              label="Icon Placement"
            >
              <MenuItem value="bottom-right">Bottom Right</MenuItem>
              <MenuItem value="bottom-left">Bottom Left</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ mb: 2 }}>
            <InputLabel sx={{ mb: 1 }}>Button Background Color</InputLabel>
            <TextField
              fullWidth
              type="color"
              value={buttonBgColor}
              onChange={(e) => setButtonBgColor(e.target.value)}
              sx={{ height: 56 }}
            />
          </Box>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGenerateScript}
          disabled={error || (!message.trim()) || (!cardTitle.trim())}
        >
          Generate Script
        </Button>
        {showAlert && (
          <Alert severity="success" onClose={() => setShowAlert(false)} sx={{ mt: 2 }}>
            Script generated successfully! Copy and paste it into your project.
          </Alert>
        )}
        {generatedScript && (
          <Box sx={{ mt: 2, position: 'relative' }}>
            <SyntaxHighlighter
              language="javascript"
              style={oneLight}
              customStyle={{
                padding: '10px',
                borderRadius: '5px',
                backgroundColor: '#f9f9f9',
              }}
            >
              {generatedScript}
            </SyntaxHighlighter>
            <IconButton
              onClick={handleCopyToClipboard}
              sx={{
                position: 'absolute',
                top: 5,
                right: 5,
                color: 'white',
                backgroundColor: '#25d366',
                '&:hover': { backgroundColor: '#1da851' },
              }}
            >
              <CopyAll />
            </IconButton>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default WAChatWidget;

