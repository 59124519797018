import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/AuthProvider";
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';

const PrivateRoute = () => {
  const { token, logOut } = useAuth();

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      const tokenExpiry = dayjs.unix(decodedToken.exp);

      // If the token is expired, log the user out
      if (tokenExpiry.diff(dayjs()) < 0) {
        logOut();
      }
    }
  }, [token, logOut]);

  if (!token) return <Navigate to="/login" />;
  return <Outlet />;
};

export default PrivateRoute;

