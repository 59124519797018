import { useState, useEffect } from 'react';
import { Container, Grid, MenuItem, FormControl, Select, InputLabel, Card, CardContent, Typography, CircularProgress, Box, Paper } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { MONTHLY_PERFORMANCE_REPORTS_URL, ZONES_ENDPOINT } from '../../../config/urls';
import { useAuth } from "../../../hooks/AuthProvider";
import { useNavigate } from 'react-router-dom';

export default function OrderRequestMonthlyReport() {
    const { getValidToken } = useAuth();
    const navigate = useNavigate();

    const [zones, setZones] = useState(['Default']); // Initialize with 'Default'
    const [selectedZone, setSelectedZone] = useState('Default');
    const [reportData, setReportData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [zonesLoading, setZonesLoading] = useState(true);

    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    useEffect(() => {
        fetchZones();
    }, []);

    useEffect(() => {
        if (selectedZone) {
            fetchReportData(selectedZone);
        }
    }, [selectedZone]);

    const fetchZones = async () => {
        const token = await getValidToken();
        const tenant = localStorage.getItem('tenant');
        const headers = {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "X-Tenant-Id": tenant
          };

        setZonesLoading(true);
        try {
            const response = await fetch(ZONES_ENDPOINT, { headers });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
    
            if (Array.isArray(data.results)) {
                const zoneNames = data.results.map(zone => zone.name);
                setZones(prevZones => ['Default', ...zoneNames]);
            } else {
                console.error('Fetched zones data is not an array:', data);
            }
        } catch (error) {
            console.error('Error fetching zones:', error);
        } finally {
            setZonesLoading(false);
        }
    };

    const fetchReportData = async (zone) => {
        const token = await getValidToken();
        const tenant = localStorage.getItem('tenant');
        const headers = {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "X-Tenant-Id": tenant
          };

        setLoading(true);
        try {
            const response = await fetch(MONTHLY_PERFORMANCE_REPORTS_URL + `?zone=${zone}`, { headers });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (Array.isArray(data)) {
                setReportData(data);
            } else {
                console.error('Fetched report data is not an array:', data);
            }
        } catch (error) {
            console.error('Error fetching report data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleZoneChange = (event) => {
        setSelectedZone(event.target.value);
    };

    const generateMonthlyChartData = () => {
        const labels = [];
        const completedOrders = [];

        reportData.forEach((data) => {
            const monthLabel = `${monthNames[data.month - 1]} ${data.year}`;
            labels.push(monthLabel);
            completedOrders.push(data.completed_orders_count);
        });

        return { labels, completedOrders };
    };

    const { labels, completedOrders } = generateMonthlyChartData();

    return (
        <Container>
            <Paper sx={{ width: '100%', overflow: 'hidden', marginBottom: '1rem', padding: '16px' }}>
                <Typography variant="body2" color="textSecondary">
                    Summarized report for completed order requests based on (past 180 days) per zone
                </Typography>
                
                <Grid container spacing={3} alignItems="flex-start" sx={{ marginTop: '1rem' }}>
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Zone</InputLabel>
                            <Select
                                value={selectedZone}
                                onChange={handleZoneChange}
                                label="Zone"
                            >
                                {zones.map((zone) => (
                                    <MenuItem key={zone} value={zone}>
                                        {zone}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Paper>

            <Grid container spacing={3} alignItems="flex-start">
                {loading || zonesLoading ? (
                    <Grid item xs={12} style={{ marginTop: '1rem' }}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <Grid item xs={12} style={{ marginTop: '1rem' }}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Monthly Order Requests completed Per Zone
                                </Typography>
                                <BarChart
                                    xAxis={[{ scaleType: 'band', data: labels }]}
                                    yAxis={[{ tickMinStep: 1 }]}
                                    series={[{ data: completedOrders }]}
                                    width={700}
                                    height={400}
                                    colors={['#1976d2']}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
