import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Collapse,
  Alert,
  Box,
  Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { TEMPLATE_DELETE_ENDPOINT, TEMPLATE_CREATE_ENDPOINT } from '../../config/urls';
import MessageNode from "./MessageNode";
import { useAuth } from '../../hooks/AuthProvider';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const WhatsappFormDialog = ({ modalState, handleModalState, selectedClient }) => {

  const { getValidToken } = useAuth();

  const [alertObj, setAlertObj] = useState({
    active: false,
    msg: "",
    type: ""
  });

  const handleAlertClose = () => {
    setAlertObj({
      active: false,
      type: "",
      msg: ""
    });
  };

  const handleClose = () => {
    handleModalState({ isOpen: false });
    handleAlertClose();
  };

  const handleDisable = async() => {

    const apiUrl =  TEMPLATE_DELETE_ENDPOINT + `${modalState.selectedClient.template_name}/`;

    console.log("API URL: ", apiUrl);

    const token = await getValidToken();
    const tenant = localStorage.getItem('tenant');

    axios.delete(apiUrl , {
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "application/json",
        "X-Tenant-Id": tenant
      },
    })
      .then(response => {
        console.log('API Response:', response.data);
        setAlertObj({
          active: true,
          type: "success",
          msg: "Template Deleted Successfully"
        });
        // handleClose();
      })
      .catch(error => {
        console.error('API Error:', error.response.data);
        setAlertObj({
          active: true,
          type: "error",
          msg: "Failed to Delete Template"
        });
      });
  };

  useEffect(() => {
    let timeoutId;
    
    if (alertObj.active) {
      timeoutId = setTimeout(() => {
        handleAlertClose();
      }, 5000);
    }
    
    // Cleanup function to clear the timeout when component unmounts
    // or when alertObj changes
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [alertObj]);


  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={modalState.isOpen}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2, position: 'relative' }} id="customized-dialog-title">
        {/* Conditionally render title based on modalState.mode */}
        {modalState.mode === 'view' ? modalState.selectedClient.template_name
          : modalState.mode === 'disable' ? "Disable Template: " + modalState.selectedClient.template_name
          : ''}

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 0,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>


      <DialogContent dividers sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        {modalState.mode === 'view' && modalState.jsonData && (
          // <Box sx={{ textAlign: 'left', mb: 2 }}>
            // <Grid item >
                <MessageNode 
                    templateName={modalState.jsonData.templateName}
                    language={modalState.jsonData.language}
                    headerType={modalState.jsonData.headerType}
                    headerText={modalState.jsonData.headerText}
                    headerFile={modalState.jsonData.headerFile}
                    body={modalState.jsonData.body}
                    sampleBodyFields={modalState.jsonData.sampleBodyFields}
                    footer={modalState.jsonData.footer}
                    mode={modalState.mode}
                    allButtons={modalState.jsonData.allButtons}
                />
            // </Grid>
          // </Box>
        )}
        {(modalState.mode === 'disable') && (
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            <p>Are you sure you want to disable the template: <strong>{modalState.selectedClient.template_name}</strong><br></br>(This change is not reversible)</p>
          </Box>
        )}
      </DialogContent>
      
      <DialogActions>
        {modalState.mode === 'disable' ? (
          <Button variant="contained" onClick={handleDisable}>Disable</Button>
        ) : (
          <Button autoFocus onClick={handleClose}>Close</Button>
        )}
      </DialogActions>
      <Collapse in={alertObj.active}>
        <Alert sx={{ mb: 2 }} severity={alertObj.type} onClose={handleAlertClose}>
          {alertObj.msg}
        </Alert>
      </Collapse>
    </BootstrapDialog>
  );
}

export default WhatsappFormDialog;
