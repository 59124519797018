// ChatHistoryContext.js
import React, { createContext, useState, useContext } from 'react';

const ChatHistoryContext = createContext();

export const useChatHistoryContext = () => useContext(ChatHistoryContext);

export const ChatHistoryProvider = ({ children }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);

  return (
    <ChatHistoryContext.Provider value={{ selectedUser, setSelectedUser, chatHistory, setChatHistory }}>
      {children}
    </ChatHistoryContext.Provider>
  );
};