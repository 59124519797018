import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    TextField,
    Button,
    Box,
    FormControlLabel,
    Checkbox,
    Alert,
    Radio,
    RadioGroup,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    FormHelperText
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

// Recurring broadcast scheduling component
const RecurringBroadcast = ({
    scheduleDate,
    setScheduleDate,
    broadcastTime,
    setBroadcastTime,
    notifyBefore,
    setNotifyBefore,
    notifyStatus,
    setNotifyStatus,
    onCancel,
    onSubmit,
}) => {
    // Recurring schedule specific state
    const [repeatInterval, setRepeatInterval] = useState(1);
    const [monthlyPattern, setMonthlyPattern] = useState('dayOfMonth');
    const [dayOfMonth, setDayOfMonth] = useState(15);
    const [weekOfMonth, setWeekOfMonth] = useState('First');
    const [dayOfWeek, setDayOfWeek] = useState('Sunday');
    const [weekendHandling, setWeekendHandling] = useState('sendAnyway');
    const [startRecurrence, setStartRecurrence] = useState(new Date());
    const [endRecurrence, setEndRecurrence] = useState(null);
    const [noEndDate, setNoEndDate] = useState(true);
    
    // Error states
    const [dateError, setDateError] = useState('');
    const [timeError, setTimeError] = useState('');
    const [startDateError, setStartDateError] = useState('');
    const [endDateError, setEndDateError] = useState('');
    const [formErrors, setFormErrors] = useState({});
    
    // Summary text for display
    const [summaryText, setSummaryText] = useState('');

    // Set default values when component mounts
    useEffect(() => {
        // Default start recurrence to today
        if (!startRecurrence) {
            setStartRecurrence(new Date());
        }
        
        // Default broadcast time to current time + 1 hour
        if (!broadcastTime) {
            const now = new Date();
            now.setHours(now.getHours() + 1, 0, 0, 0);
            setBroadcastTime(now);
        }
    }, []);

    // Update summary text when form values change
    useEffect(() => {
        if (broadcastTime && startRecurrence) {
            generateSummaryText();
        }
    }, [
        repeatInterval, 
        monthlyPattern, 
        dayOfMonth, 
        weekOfMonth, 
        dayOfWeek, 
        broadcastTime, 
        startRecurrence,
        endRecurrence,
        noEndDate
    ]);

    // Generate the human-readable summary text
    const generateSummaryText = () => {
        try {
            let summaryStr = "This broadcast will be sent ";
            
            // Day pattern
            if (monthlyPattern === 'dayOfMonth') {
                summaryStr += `on the ${getDayWithOrdinal(dayOfMonth)} day`;
            } else {
                summaryStr += `on the ${weekOfMonth.toLowerCase()} ${dayOfWeek}`;
            }
            
            // Frequency
            if (repeatInterval === 1) {
                summaryStr += " of every month";
            } else {
                summaryStr += ` of every ${repeatInterval} months`;
            }
            
            // Time
            if (broadcastTime instanceof Date) {
                const timeStr = broadcastTime.toLocaleTimeString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                });
                summaryStr += ` at ${timeStr}`;
            }
            
            // Start date
            if (startRecurrence) {
                const startDateStr = startRecurrence.toLocaleDateString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                });
                summaryStr += `, starting from ${startDateStr}`;
            }
            
            // End date
            if (!noEndDate && endRecurrence) {
                const endDateStr = endRecurrence.toLocaleDateString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                });
                summaryStr += ` until ${endDateStr}`;
            } else {
                summaryStr += " with no end date";
            }
            
            summaryStr += ".";
            setSummaryText(summaryStr);
        } catch (error) {
            console.error("Error generating summary:", error);
            setSummaryText("");
        }
    };

    // Helper function to add ordinal suffix to numbers (1st, 2nd, 3rd, etc.)
    const getDayWithOrdinal = (day) => {
        if (day > 3 && day < 21) return `${day}th`;
        switch (day % 10) {
            case 1: return `${day}st`;
            case 2: return `${day}nd`;
            case 3: return `${day}rd`;
            default: return `${day}th`;
        }
    };

    // Validate start recurrence date
    const validateStartDate = (date) => {
        if (!date) {
            setStartDateError('Start date is required');
            return false;
        }
        
        const selectedDate = new Date(date);
        selectedDate.setHours(0, 0, 0, 0);
        
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        if (selectedDate < today) {
            setStartDateError('Start date cannot be in the past');
            return false;
        }
        
        setStartDateError('');
        return true;
    };

    // Validate end recurrence date
    const validateEndDate = (date) => {
        if (noEndDate) {
            setEndDateError('');
            return true;
        }
        
        if (!date) {
            setEndDateError('End date is required');
            return false;
        }
        
        const endDate = new Date(date);
        endDate.setHours(0, 0, 0, 0);
        
        const start = new Date(startRecurrence);
        start.setHours(0, 0, 0, 0);
        
        if (endDate <= start) {
            setEndDateError('End date must be after start date');
            return false;
        }
        
        setEndDateError('');
        return true;
    };

    // Validate broadcast time
    const validateTime = (time) => {
        if (!time) {
            setTimeError('Broadcast time is required');
            return false;
        }
        
        // If start date is today, time must be at least 1 hour in the future
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        const startDate = new Date(startRecurrence);
        startDate.setHours(0, 0, 0, 0);
        
        if (startDate.getTime() === today.getTime()) {
            const selectedTime = new Date();
            
            if (time instanceof Date) {
                selectedTime.setHours(time.getHours(), time.getMinutes(), 0, 0);
            }
            
            const minimumTime = new Date();
            minimumTime.setHours(minimumTime.getHours() + 1, 0, 0, 0);
            
            if (selectedTime < minimumTime) {
                setTimeError('Time must be at least 1 hour from now');
                return false;
            }
        }
        
        setTimeError('');
        return true;
    };

    // Handle form changes
    const handleRepeatIntervalChange = (event) => {
        setRepeatInterval(event.target.value);
    };

    const handleMonthlyPatternChange = (event) => {
        setMonthlyPattern(event.target.value);
    };

    const handleDayOfMonthChange = (event) => {
        setDayOfMonth(event.target.value);
    };

    const handleWeekOfMonthChange = (event) => {
        setWeekOfMonth(event.target.value);
    };

    const handleDayOfWeekChange = (event) => {
        setDayOfWeek(event.target.value);
    };

    const handleWeekendHandlingChange = (event) => {
        setWeekendHandling(event.target.value);
    };

    const handleStartRecurrenceChange = (newDate) => {
        setStartRecurrence(newDate);
        validateStartDate(newDate);
        if (broadcastTime) {
            validateTime(broadcastTime);
        }
    };

    const handleEndRecurrenceChange = (newDate) => {
        setEndRecurrence(newDate);
        validateEndDate(newDate);
    };

    const handleTimeChange = (newTime) => {
        setBroadcastTime(newTime);
        validateTime(newTime);
    };

    const handleNoEndDateChange = (event) => {
        setNoEndDate(event.target.checked);
        if (event.target.checked) {
            setEndDateError('');
        } else if (endRecurrence) {
            validateEndDate(endRecurrence);
        }
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        
        // Validate all fields
        const isStartDateValid = validateStartDate(startRecurrence);
        const isEndDateValid = validateEndDate(endRecurrence);
        const isTimeValid = validateTime(broadcastTime);
        
        // Check if all validations pass
        if (!isStartDateValid || (!noEndDate && !isEndDateValid) || !isTimeValid) {
            return;
        }
        
        // Call the parent onSubmit handler
        onSubmit();
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <Grid container spacing={3}>
                {/* Repeat Every */}
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="repeat-every-label">Repeat Every</InputLabel>
                        <Select
                            labelId="repeat-every-label"
                            id="repeat-every"
                            value={repeatInterval}
                            label="Repeat Every"
                            onChange={handleRepeatIntervalChange}
                        >
                            <MenuItem value={1}>1 month</MenuItem>
                            <MenuItem value={2}>2 months</MenuItem>
                            <MenuItem value={3}>3 months</MenuItem>
                            <MenuItem value={6}>6 months</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                {/* Monthly Pattern */}
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Monthly Pattern</Typography>
                    <RadioGroup
                        name="monthly-pattern-radio-group"
                        value={monthlyPattern}
                        onChange={handleMonthlyPatternChange}
                    >
                        {/* Day of month option */}
                        <Grid container alignItems="center" spacing={2} sx={{ mb: 2 }}>
                            <Grid item>
                                <FormControlLabel 
                                    value="dayOfMonth" 
                                    control={<Radio />} 
                                    label="Day of month" 
                                />
                            </Grid>
                            {monthlyPattern === 'dayOfMonth' && (
                                <Grid item xs>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography sx={{ mr: 2 }}>Day</Typography>
                                        <FormControl sx={{ minWidth: 120 }}>
                                            <Select
                                                id="day-of-month"
                                                value={dayOfMonth}
                                                onChange={handleDayOfMonthChange}
                                                disabled={monthlyPattern !== 'dayOfMonth'}
                                            >
                                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 28].map((day) => (
                                                    <MenuItem key={day} value={day}>
                                                        {day}
                                                    </MenuItem>
                                                ))}
                                                <MenuItem value={31}>Last</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>

                        {/* Specific day of week option */}
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                                <FormControlLabel 
                                    value="dayOfWeek" 
                                    control={<Radio />} 
                                    label="Specific day of week" 
                                />
                            </Grid>
                            {monthlyPattern === 'dayOfWeek' && (
                                <Grid item xs>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <FormControl sx={{ minWidth: 120, mr: 2 }}>
                                            <Select
                                                id="week-of-month"
                                                value={weekOfMonth}
                                                onChange={handleWeekOfMonthChange}
                                                disabled={monthlyPattern !== 'dayOfWeek'}
                                            >
                                                <MenuItem value="First">First</MenuItem>
                                                <MenuItem value="Second">Second</MenuItem>
                                                <MenuItem value="Third">Third</MenuItem>
                                                <MenuItem value="Fourth">Fourth</MenuItem>
                                                <MenuItem value="Last">Last</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl sx={{ minWidth: 120 }}>
                                            <Select
                                                id="day-of-week"
                                                value={dayOfWeek}
                                                onChange={handleDayOfWeekChange}
                                                disabled={monthlyPattern !== 'dayOfWeek'}
                                            >
                                                <MenuItem value="Sunday">Sunday</MenuItem>
                                                <MenuItem value="Monday">Monday</MenuItem>
                                                <MenuItem value="Tuesday">Tuesday</MenuItem>
                                                <MenuItem value="Wednesday">Wednesday</MenuItem>
                                                <MenuItem value="Thursday">Thursday</MenuItem>
                                                <MenuItem value="Friday">Friday</MenuItem>
                                                <MenuItem value="Saturday">Saturday</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <Typography sx={{ ml: 2 }}>of the month</Typography>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </RadioGroup>
                </Grid>

                {/* Broadcast Time */}
                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" gutterBottom>Broadcast Time</Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                            value={broadcastTime}
                            onChange={handleTimeChange}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    required: true,
                                    error: !!timeError,
                                    helperText: timeError
                                }
                            }}
                            ampm={false} // Use 24-hour format
                            minutesStep={5} // 5-minute increments
                        />
                    </LocalizationProvider>
                </Grid>

                {/* Weekend Handling */}
                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" gutterBottom>If the selected day falls on a weekend</Typography>
                    <FormControl fullWidth>
                        <Select
                            id="weekend-handling"
                            value={weekendHandling}
                            onChange={handleWeekendHandlingChange}
                        >
                            <MenuItem value="sendAnyway">Send anyway</MenuItem>
                            <MenuItem value="sendPrevious">Send on the previous business day</MenuItem>
                            <MenuItem value="sendNext">Send on the next business day</MenuItem>
                            <MenuItem value="skip">Skip this occurrence</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                {/* Start and End Recurrence */}
                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" gutterBottom>Start Recurrence</Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            value={startRecurrence}
                            onChange={handleStartRecurrenceChange}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    required: true,
                                    error: !!startDateError,
                                    helperText: startDateError
                                }
                            }}
                            minDate={new Date()} // Disables past dates
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={noEndDate}
                                    onChange={handleNoEndDateChange}
                                />
                            }
                            label="No end date"
                        />
                        {!noEndDate && (
                            <Box sx={{ mt: 1 }}>
                                <Typography variant="subtitle1" gutterBottom>End Recurrence</Typography>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        value={endRecurrence}
                                        onChange={handleEndRecurrenceChange}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                required: !noEndDate,
                                                error: !!endDateError,
                                                helperText: endDateError
                                            }
                                        }}
                                        minDate={startRecurrence || new Date()} // Must be after start date
                                        disabled={noEndDate}
                                    />
                                </LocalizationProvider>
                            </Box>
                        )}
                    </Box>
                </Grid>

                {/* Summary */}
                {summaryText && (
                    <Grid item xs={12}>
                        <Alert severity="success" sx={{ backgroundColor: '#e8f5e9' }}>
                            <Typography variant="body1" sx={{ color: '#1b5e20' }}>
                                <strong>Summary</strong>
                            </Typography>
                            <Typography variant="body2" sx={{ color: '#1b5e20' }}>
                                {summaryText}
                            </Typography>
                        </Alert>
                    </Grid>
                )}

                {/* Notification Settings */}
                <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        Notification Settings
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={notifyBefore}
                                onChange={(e) => setNotifyBefore(e.target.checked)}
                            />
                        }
                        label="Notify me before broadcast is sent"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={notifyStatus}
                                onChange={(e) => setNotifyStatus(e.target.checked)}
                            />
                        }
                        label="Notify me if broadcast fails"
                    />
                </Grid>

                {/* Buttons */}
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                        <Button variant="outlined" onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                        >
                            Schedule Broadcast
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default RecurringBroadcast;