import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Chip from '@mui/material/Chip';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import { MenuItem } from '@mui/material';
import { Select } from '@mui/material';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import RefreshIcon from '@mui/icons-material/Refresh';
import { WHATSAPP_MESSAGE_LOG_ENDPOINT } from '../config/urls';

const columns = [
  { id: 'From', label: 'From' },
  { id: 'Agent', label: 'Agent' },
  { id: 'To', label: 'To' },
  { id: 'Date', label: 'Date' },
  { id: 'Status', label: 'Status' },
  { id: 'Message Type', label: 'Message Type' },
  { id: 'Template', label: 'Template' },
  { id: 'Message', label: 'Message' },
];

export default function UsersTable({ refreshKey, dateInput }) {
  const [rowCount, setRowCount] = useState(0);
  const [messageData, setmessageData] = useState([]);
  const [userModalState, setUserModalState] = useState({ isOpen: false, selectedClient: null, mode: 'add' });
  const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:8000/api`;
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 20
  });

  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [messageTypeFilter, setMessageTypeFilter] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [dateError, setDateError] = useState(null);
  const [senderFilter, setSenderFilter] = useState('');
  const [receiverFilter, setReceiverFilter] = useState('');
  const [lastSynced, setLastSynced] = useState(null);

  // const options = [...new Set(messageData.map(data => data.option))].map(option => (
  //       <MenuItem key={option} value={option}>{captilazeFirstLetter(option)}</MenuItem>
  //   ));

  // const statuses = [...new Set(messageData.map(data => data.status))].map(status => (
  //       <MenuItem key={status} value={status}>{captilazeFirstLetter(status)}</MenuItem>
  //   ));

  const fetchData = async () => {
    let searchDateURL = '';
    if (dateInput) {
      let dateString = dayjs(dateInput).format("YYYY-MM-DD");
      searchDateURL = `&search_date=${dateString}`;
    }

    const queryParams = new URLSearchParams({
        limit: controller.rowsPerPage.toString(),
        offset: (controller.serverSidePage * controller.rowsPerPage).toString(),
        search_query: searchQuery,
        status_filter: statusFilter,
        message_type_filter: messageTypeFilter,
        from_date: fromDate ? dayjs(fromDate).format("YYYY-MM-DD") : '',
        to_date: toDate ? dayjs(toDate).format("YYYY-MM-DD") : '',
        sender_filter: senderFilter,
        receiver_filter: receiverFilter
    });

    // const response = await fetch(`${WHATSAPP_MESSAGE_LOG_ENDPOINT}?${queryParams}${searchDateURL}`);
    const tenant = localStorage.getItem('tenant');
    const headers = {
      // "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "X-Tenant-Id": tenant
    };

    try {
      const response = await fetch(`${WHATSAPP_MESSAGE_LOG_ENDPOINT}?${queryParams}${searchDateURL}`, { headers });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const jsonData = await response.json();
      setmessageData(jsonData.results);
      setRowCount(jsonData.count);
      setLastSynced(
          jsonData.results.length > 0 ?
              dayjs(jsonData.results[0].last_synced).format("MMMM D, YYYY, h:mm:ss A")
              : null
      );
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    controller,
    controller.serverSidePage, 
    controller.rowsPerPage, 
    refreshKey, 
    dateInput,
    searchQuery, 
    statusFilter, 
    messageTypeFilter, 
    fromDate, 
    toDate,
    senderFilter,
    receiverFilter,
    lastSynced
  ]);

  const handlePageChange = (event, newPage) => {
    setController(prev => ({
      ...prev,
      page: newPage,
      serverSidePage: newPage  // Sync server-side page with UI page
    }));
  };

  const handleMessageTypeFilterChange = (event) => {
    setMessageTypeFilter(event.target.value);
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleSenderFilterChange = (event) => {
    setSenderFilter(event.target.value);
  };

  const handleReceiverFilterChange = (event) => {
    setReceiverFilter(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...prev => ({
        ...prev,
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
        serverSidePage: 0  // Reset to first page
      })
    });
  };

  const handleFromDateChange = (newValue) => {
    if (toDate && newValue && dayjs(newValue).isAfter(toDate)) {
      setDateError('From Date must be less than or equal to To Date');
    } else {
      setDateError(null);
      setFromDate(newValue);
    }
  };

  const handleToDateChange = (newValue) => {
    if (fromDate && newValue && dayjs(newValue).isBefore(fromDate)) {
      setDateError('To Date must be greater than or equal to From Date');
    } else {
      setDateError(null);
      setToDate(newValue);
    }
  };

  const handleRefresh = () => {
    setController({
      page: 0,
      rowsPerPage: 20
    });
  };

  const handleResetButtonClick = () => {
    setSearchQuery('');
    setStatusFilter('');
    setMessageTypeFilter('');
    setFromDate(null);
    setToDate(null);
    setSenderFilter('');
    setReceiverFilter('');
    setController({
      page: 0,
      rowsPerPage: 20
    });
  }

  const [activeButton, setActiveButton] = useState("Message"); // Default active button

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    console.log(`${buttonName} clicked`);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: 1,
          m: 1,
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        {/* Filters Row (Including Reset Button) */}
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <DatePicker
                label="From Date"
                value={fromDate}
                onChange={handleFromDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <DatePicker
                label="To Date"
                value={toDate}
                onChange={handleToDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel>Message Type</InputLabel> 
              <Select
                value={messageTypeFilter}
                onChange={handleMessageTypeFilterChange}
                displayEmpty
                fullWidth
              >
                <MenuItem value="text">text</MenuItem>
                <MenuItem value="button_reply">button_reply</MenuItem>
                <MenuItem value="list_reply">list_reply</MenuItem>
                <MenuItem value="quick_reply">quick_reply</MenuItem>
                <MenuItem value="location">location</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel> 
              <Select
                value={statusFilter}
                onChange={handleStatusFilterChange}
                displayEmpty
                fullWidth
              >
                <MenuItem value="read">read</MenuItem>
                <MenuItem value="sent">sent</MenuItem>
                <MenuItem value="delivered">delivered</MenuItem>
                <MenuItem value="enqueued">enqueued</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <TextField
                label="From"
                value={senderFilter}
                onChange={handleSenderFilterChange}
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <FormControl fullWidth>
              <TextField
                label="To"
                value={receiverFilter}
                onChange={handleReceiverFilterChange}
                fullWidth
              />
            </FormControl>
            {/* Reset Button (In Line with Filters) */}
            <Button variant="outlined" onClick={handleResetButtonClick} sx={{ ml: 1, height: '56px' }}>Reset</Button>
          </Grid>
        </Grid>

        {/* Sync Button Below Filters */}
        <Grid item xs={12} sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" onClick={handleRefresh}><RefreshIcon sx={{ mr: 1 }} /> Sync</Button>
        </Grid>

        <Grid item xs={12} sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
          {/* Last Synced Information */}
          <Typography 
            variant="body2" 
            gutterBottom
            sx={{ mt: 1 }}
            color="text.secondary"
          >
            Last Synced: {lastSynced}
          </Typography>
        </Grid>
      </Box>

      {/* Table Section */}
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="left"
                  style={{ minWidth: column.minWidth, border: '1px solid rgba(224, 224, 224, 1)' }}
                >
                  <h3>{column.label}</h3>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {messageData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No match found
                </TableCell>
              </TableRow>
            ) : (
              messageData.map((message, index) => (
                <TableRow key={index}>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{message.sender}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{message.agent}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{message.receiver}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{dayjs(message.logged_at).format("MMMM D, YYYY, h:mm A")}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{message.status}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{message.message_type}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{message?.template?.template_name}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{message.message}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={rowCount}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
