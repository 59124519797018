import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Chip from '@mui/material/Chip';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { ZONE_ORDER_REPORTS_URL, REPORT_EXPORT_URL } from '../../../config/urls';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useAuth } from "../../../hooks/AuthProvider";
import { useNavigate } from 'react-router-dom';

const columns = [
  { id: 'zone', label: 'Zone' },
  { id: 'count', label: 'Count' },
];

function OrdersTable({ refreshKey, searchInput }) {
  const { getValidToken } = useAuth();
  const auth = useAuth();
  const navigate = useNavigate();

  const [rowCount, setRowCount] = useState(0);
  const [ordersData, setOrdersData] = useState([]);
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 20
  });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchTriggered, setSearchTriggered] = useState(false); // Search-triggered state

  // Function to fetch orders based on filter or without filter
  const fetchOrders = async (searchURL = '', isTriggered = false) => {
    const token = await getValidToken();
    let offset = controller.page * controller.rowsPerPage;
    const tenant = localStorage.getItem('tenant');

    try {
      const headers = {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
        "X-Tenant-Id": tenant
      };

      if (isTriggered){
        offset = 0;
      }

      const response = await fetch(
        `${ZONE_ORDER_REPORTS_URL}?limit=${controller.rowsPerPage}&offset=${offset}${searchURL}`,
        { headers }
      );

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.code === "user_inactive") {
          auth.logOut();
          navigate('/login');
        }
        throw new Error('Failed to fetch data');
      }

      const jsonData = await response.json();
      setOrdersData(jsonData.results);
      setRowCount(jsonData.count);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };

  // Call API initially without filters (default load) and when pagination or refreshKey changes
  useEffect(() => {
    // Only fetch without filters if not in search mode
    if (!searchTriggered && !searchInput) {
      fetchOrders(); // No filters on initial load
    }
  }, [controller, refreshKey]);

  // Call API with filters when search is triggered
  useEffect(() => {
    if (searchTriggered || searchInput) {
      let constructedSearchURL = '';

      if (searchInput) {
        constructedSearchURL += `&search_id=${encodeURIComponent(searchInput)}`;
      }
      if (startDate) {
        constructedSearchURL += `&start_date=${dayjs(startDate).format('YYYY-MM-DD')}`;
      }
      if (endDate) {
        constructedSearchURL += `&end_date=${dayjs(endDate).format('YYYY-MM-DD')}`;
      }
      
      setController((prev) => ({
        ...prev,
        page: 0
      }));

      console.log('Fetching with filters:', constructedSearchURL); // Debug log
      fetchOrders(constructedSearchURL, true); // Fetch with filters
      setSearchTriggered(false); // Reset search trigger
    }
  }, [searchTriggered, searchInput, startDate, endDate]);

  // Handle pagination
  const handlePageChange = (event, newPage) => {
    setController((prev) => ({
      ...prev,
      page: newPage
    }));
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setController((prev) => ({
      ...prev,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    }));
  };

  const handleExport = async () => {
    const token = await getValidToken();
    const tenant = localStorage.getItem('tenant');
    
    let exportURL = `${REPORT_EXPORT_URL}?model=zone`;
    
    // Append filters if they exist
    // if (startDate) {
    //   exportURL += `start_date=${dayjs(startDate).format('YYYY-MM-DD')}&`;
    // }
    // if (endDate) {
    //   exportURL += `end_date=${dayjs(endDate).format('YYYY-MM-DD')}&`;
    // }
    // if (searchInput) {
    //   exportURL += `search_id=${encodeURIComponent(searchInput)}&`;
    // }
    
    try {
      const response = await fetch(exportURL, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          "X-Tenant-Id": tenant
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to export data');
      }
  
      // Create a blob for the file
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
  
      // Create a temporary anchor element to download the file
      const a = document.createElement('a');
      a.href = url;
  
      // Extract file name from Content-Disposition header or use default
      const contentDisposition = response.headers.get('Content-Disposition');
      const filename = contentDisposition
        ? contentDisposition.split('filename=')[1]?.replace(/"/g, '') || 'report.csv'
        : 'report.csv';
  
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
  
      // Revoke the object URL to free memory
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting data:', error.message);
    }
  };
  

  // Trigger search when button is clicked
  const handleSearch = () => {
    setSearchTriggered(true);
    setController((prev) => ({
      ...prev,
      page: 0 // Reset to first page after search
    }));
  };

  // Reset date filters and search input
  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setController((prev) => ({
      ...prev,
      page: 0
    }));
    setSearchTriggered(true); // Trigger fetch without filters
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
    <Typography variant="body2" color="textSecondary" sx={{m: 1, ml:2 }}>
      Summerized report for completed order requests per zone
    </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          p: 1,
          m: 1,
          bgcolor: 'background.paper',
          borderRadius: 1,
          alignItems: 'center',
          gap: 2,
        }}
      >
        {/* Date Filters */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <DatePicker
              label="Start Date"
              value={startDate}
              format="DD/MM/YYYY" // Correct prop
              onChange={(date) => setStartDate(date)}
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              format="DD/MM/YYYY" // Correct prop
              onChange={(date) => setEndDate(date)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
          <Button variant="contained" size="large" onClick={handleSearch}>
            Search
          </Button>
          <Button variant="outlined" size="large" onClick={handleReset}>
            Reset
          </Button>
          <Box sx={{ flexGrow: 1 }} /> {/* Adds space to push the export button to the right */}
          <Button variant="contained" size="large" onClick={handleExport}>
            Export
          </Button>
        </LocalizationProvider>
      </Box>

      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="left"
                  style={{ minWidth: column.minWidth || 100, border: '1px solid rgba(224, 224, 224, 1)' }}
                >
                  <h3>{column.label}</h3>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {ordersData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No data found
                </TableCell>
              </TableRow>
            ) : (
              ordersData.map((order, index) => (
                <TableRow key={index}>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{order.zone_name}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{order.completed_orders_count}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={rowCount}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default OrdersTable;
