import * as React from "react";
 
// importing material UI components
import { useState } from 'react';
import AudienceTable from './AudienceTable';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import {
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';

import AudienceFormDialog from './AudienceFormDialog';

const Audience = (props) => {

  const [searchQuery, setSearchQuery] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);

  const updateSearchValue = (value) => {
    if (value.length > 50) {
      return;
    }
    setSearchQuery(value);
    setCharCount(value.length);
  };

  return (
    <div className="App">
      <Container maxWidth={false}>
        <>
          <h2 className="heading-title">Audience</h2>
          
          <Grid item xs={12} md={2} mb={2}>
            <Tooltip title="Search by First Name, Last Name, Phone Number, or Segment" placement="right-start" arrow>
              <TextField
                sx={{ width: 250, mt: 1 }}
                size="small"
                placeholder="Search"
                autoComplete='off'
                value={searchQuery}
                onChange={(e) => updateSearchValue(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="caption">{charCount}/50</Typography>
                    </InputAdornment>
                  ),
                  
                }}
              />
            </Tooltip>
          </Grid>

          {/* <Button variant="contained" sx={{ float: 'right', marginRight: 2 }} ><CreateIcon /></Button>
          <Button variant="contained" sx={{ float: 'right', marginRight: 2 }} ><AddIcon /></Button> */}
          <AudienceTable 
            refreshkey={refreshKey}
            searchQuery={searchQuery}
            updateRefreshKey={setRefreshKey}
          />
        </>
      </Container>

    </div>
  );
}
export default Audience;
