import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    TextField,
    Button,
    Box,
    FormControlLabel,
    Checkbox,
    Alert,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

// One-time broadcast scheduling component
const OneTimeBroadcast = ({
    campaignName,
    setCampaignName,
    campaignDescription,
    setCampaignDescription,
    scheduleDate,
    setScheduleDate,
    broadcastTime,
    setBroadcastTime,
    notifyBefore,
    setNotifyBefore,
    notifyStatus,
    setNotifyStatus,
    campaignsData,
    onCancel,
    onSubmit,
}) => {
    const [dateError, setDateError] = useState('');
    const [timeError, setTimeError] = useState('');
    const [summaryText, setSummaryText] = useState('');
    const [error, setError] = useState(null);

    // Generate human-readable date format for summary
    useEffect(() => {
        if (scheduleDate && broadcastTime) {
            // Create a new date from scheduleDate
            const date = new Date(scheduleDate);
            
            // If broadcastTime is a Date object, use its hours and minutes
            if (broadcastTime instanceof Date) {
                date.setHours(
                    broadcastTime.getHours(),
                    broadcastTime.getMinutes(),
                    0,
                    0
                );
            }
            
            const formattedDate = date.toLocaleDateString('en-US', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
            
            const formattedTime = date.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            });
            
            setSummaryText(`This broadcast will be sent on ${formattedDate} at ${formattedTime}.`);
        } else {
            setSummaryText('');
        }
    }, [scheduleDate, broadcastTime]);

    // Set default date and time when component mounts
    useEffect(() => {
        if (!scheduleDate) {
            const today = new Date();
            setScheduleDate(today);
        }
        
        if (!broadcastTime) {
            const now = new Date();
            now.setHours(now.getHours() + 1, 0, 0, 0); // Current time + 1 hour
            setBroadcastTime(now);
        }
    }, []);

    useEffect(() => {
        const isDuplicate = campaignsData.some(campaign => campaign.toLowerCase() === campaignName.toLowerCase());
        setError(isDuplicate);
    }, [campaignName]);

    // const handleNameChange = (event) => {
    //     const newName = event.target.value;
        
    //     if (newName === '' || newName.trim().length > 0) {
    //         if (newName.length <= 50) {
    //             setCampaignName(newName);
    //         }
    //     }
    // };

    // const handleDescriptionChange = (event) => {
    //     const newDescription = event.target.value;
        
    //     if (newDescription === '' || newDescription.trim().length > 0) {
    //         if (newDescription.length <= 250) {
    //             setCampaignDescription(newDescription);
    //         }
    //     }
    // };

    // Validate date is not in the past
    const validateDate = (date) => {
        if (!date) return false;
        
        const selectedDate = new Date(date);
        selectedDate.setHours(0, 0, 0, 0);
        
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        if (selectedDate < today) {
            setDateError('Date cannot be in the past');
            return false;
        }
        
        setDateError('');
        return true;
    };

    // Validate time is at least one hour in the future if date is today
    const validateTime = (time) => {
        if (!time || !scheduleDate) return false;
        
        const selectedDate = new Date(scheduleDate);
        selectedDate.setHours(0, 0, 0, 0);
        
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        if (selectedDate.getTime() === today.getTime()) {
            const selectedTime = new Date();
            
            // If time is a Date object, use its hours and minutes
            if (time instanceof Date) {
                selectedTime.setHours(time.getHours(), time.getMinutes(), 0, 0);
            }
            
            const minimumTime = new Date();
            minimumTime.setHours(minimumTime.getHours() + 1, 0, 0, 0);
            
                // if (selectedTime < minimumTime) {
                //     setTimeError('Time must be at least 1 hour from now');
                //     return false;
                // }
        }
        
        setTimeError('');
        return true;
    };

    // Handle date change with validation
    const handleDateChange = (newDate) => {
        setScheduleDate(newDate);
        validateDate(newDate);
        
        if (broadcastTime) {
            validateTime(broadcastTime);
        }
    };

    // Handle time change with validation
    const handleTimeChange = (newTime) => {
        setBroadcastTime(newTime);
        validateTime(newTime);
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (!validateDate(scheduleDate) || !validateTime(broadcastTime)) {
            return;
        }
        
        onSubmit();
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <Grid container spacing={3}>

                {/* Campaign Name */}
                    {/* <Grid item xs={12}>
                        <div style={{ position: 'relative', width: '100%', marginTop: 2, marginBottom: 2 }}>
                            <TextField
                                label="Campaign Name"
                                value={campaignName}
                                onChange={handleNameChange}
                                fullWidth
                                error={error}
                                helperText={error ? "Campaign name must be unique." : ""}
                                required
                            />
                            <Typography 
                                variant="body2" 
                                sx={{ 
                                    color: 'gray', 
                                    position: 'absolute', 
                                    right: '10px', 
                                    top: '10px',
                                    pointerEvents: 'none'
                                }}>
                                {`${campaignName.length}/50`}
                            </Typography>
                        </div>
                    </Grid>
         */}
                    {/* Campaign Description */}
                    {/* <Grid item xs={12}>
                        <div style={{ position: 'relative', width: '100%', marginTop: 2, marginBottom: 2 }}>
                            <TextField
                                label="Campaign Description"
                                multiline
                                rows={4}
                                value={campaignDescription}
                                onChange={handleDescriptionChange}
                                fullWidth
                            />
                            <Typography 
                                variant="body2" 
                                sx={{ 
                                    color: 'gray', 
                                    position: 'absolute', 
                                    right: '10px', 
                                    top: '10px',
                                    pointerEvents: 'none'
                                }}>
                                {`${campaignDescription.length}/250`}
                            </Typography>
                        </div>
                    </Grid> */}

                {/* Schedule Date and Time */}
                <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Scheduled Date"
                            value={scheduleDate}
                            onChange={handleDateChange}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    required: true,
                                    error: !!dateError,
                                    helperText: dateError
                                }
                            }}
                            minDate={new Date()} // Disables past dates
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                            label="Broadcast Time"
                            value={broadcastTime}
                            onChange={handleTimeChange}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    required: true,
                                    error: !!timeError,
                                    helperText: timeError
                                }
                            }}
                            ampm={false} // Use 24-hour format
                            minutesStep={5} // 5-minute increments
                        />
                    </LocalizationProvider>
                </Grid>

                {/* Summary */}
                {summaryText && (
                    <Grid item xs={12}>
                        <Alert severity="success" sx={{ backgroundColor: '#e8f5e9' }}>
                            <Typography variant="body1" sx={{ color: '#1b5e20' }}>
                                <strong>Summary</strong>
                            </Typography>
                            <Typography variant="body2" sx={{ color: '#1b5e20' }}>
                                {summaryText}
                            </Typography>
                        </Alert>
                    </Grid>
                )}

                {/* Notification Settings */}
                <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        Notification Settings
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={notifyBefore}
                                onChange={(e) => setNotifyBefore(e.target.checked)}
                            />
                        }
                        label="Notify me before broadcast is sent"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={notifyStatus}
                                onChange={(e) => setNotifyStatus(e.target.checked)}
                            />
                        }
                        label="Notify me if broadcast fails"
                    />
                </Grid>

                {/* Buttons */}
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                        <Button variant="outlined" onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                        >
                            Schedule Broadcast
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default OneTimeBroadcast;