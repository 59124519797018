import React, { useState } from "react";
import Header from '../Header.js';
import Footer from "../Footer.js";
import ButtonRow from "../ButtonRow.js";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Typography,
  CardHeader,
  TextField,
  InputAdornment
} from '@mui/material';
import {
  Search,
  FilterList,
} from '@mui/icons-material';
import CustomersTable from './CustomersTable.js';
import CustomersFormDialog from './CustomersFormDialog.js';
import ClientDetails from "../ClientDetails/ClientDetails.js";

const CustomersHome = (props) => {
  const [searchValue, setSearchValue] = useState(""); 
  const [charCount, setCharCount] = useState(0);
  const [clientModal, setClientModal] = useState({
    "isOpen": false
  });
  const [selectedClient, setSelectedClient] = useState(null);
  const [currentView, setCurrentView] = useState('table');
  const [selectedClientCode, setSelectedClientCode] = useState(null);

  const userRole = localStorage.getItem('role');

  const permissions = localStorage.getItem("permissions") || [];
  const clientMasterReadPermission = "client_master.view";
  const clientMasterAddPermission = "client_master.add";
  const clientMasterEditPermission = "client_master.edit";

  const hasReadPermission = permissions.includes(clientMasterReadPermission);
  const hasAddPermission = permissions.includes(clientMasterAddPermission);
  const hasEditPermission = permissions.includes(clientMasterEditPermission);

  const updateSearchValue = (value) => {
    if (value.length > 50) {
      return;
    }
    setSearchValue(value);
    setCharCount(value.length);
  }

  const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:8000/api`;
  
  const handlePopupOpen = (param, data) => {
    let popupType = param;
    setClientModal({"isOpen": true, "popupType": popupType, "data": data});
    setSelectedClient(data);
  }

  const handleModalChanges = (changes) => {
    setClientModal(changes);
  }

  const handleViewClient = (clientCode) => {
    setSelectedClientCode(clientCode);
    setCurrentView('details');
  }

  const handleBackToTable = () => {
    setCurrentView('table');
    setSelectedClientCode(null);
  }

  return (
    <div>
      <Container maxWidth={false}>
        {currentView === 'table' ? (
          <>
            <div className="customers-home-add">
              <div className="customers-search-icon" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant="h4" component="div" padding={2}>
                  Clients
                </Typography>
                
                <Box sx={{ paddingLeft: 2, paddingBottom: 2 }}>
                  <TextField
                    sx={{ width: 250 }}
                    size="small"
                    placeholder="Search"
                    title="Search Client Code, Client Phone No, Client Name, RM Name or RM Phone No"
                    value={searchValue}
                    onChange={(e) => updateSearchValue(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography variant="caption">{charCount}/50</Typography>
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
              </div>
              <ButtonGroup variant="outlined" aria-label="Basic button group">
              { 
                hasAddPermission && (
                <Button className={``} onClick={() => handlePopupOpen('Add', null)} variant="contained">
                  <Typography variant="body1">Add</Typography>
                </Button> )
               }
              </ButtonGroup>
            </div>
            <CustomersTable 
              searchInput={searchValue} 
              handleEditClick={(e, data) => handlePopupOpen('Edit', data)}
              onViewClient={handleViewClient}
            />
            <CustomersFormDialog 
              modalState={clientModal} 
              handleModalState={handleModalChanges} 
              selectedClient={selectedClient} 
            />
          </>
        ) : (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Button onClick={handleBackToTable} variant="contained" sx={{ mr: 2 }}>
                Back to Clients
              </Button>
            </Box>
            <ClientDetails clientCode={selectedClientCode} />
          </>
        )}
      </Container>
    </div>
  );
}

export default CustomersHome;