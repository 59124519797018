import * as React from "react";
 
// importing material UI components
import { useState } from 'react';
import TemplateAnalyticsTable from './TemplateAnalyticsTable';
import TemplateAnalyticsTab from "./TemplateAnalyticsTab";
import Container from '@mui/material/Container';
import { 
    Grid,
    TextField,
    Typography,
    Button,
    Box,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const TemplateAnalytics = (props) => { 
  
    const [templatesNamesData, setTemplatesNamesData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [charCount, setCharCount] = useState(0);
    
    // Add state for selected template and view flag
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [showAnalytics, setShowAnalytics] = useState(false);

    const handleSearchQueryChange = (event) => {
        if (event.target.value.length > 50) {
            return;
        }
        const value = event.target.value;
        setSearchQuery(event.target.value);
        setCharCount(value.length);
    };
    
    // Handler for template click
    const handleTemplateClick = (template) => {
        setSelectedTemplate(template);
        setShowAnalytics(true);
    };
    
    // Handler to go back to the table view
    const handleBackToTable = () => {
        setShowAnalytics(false);
        setSelectedTemplate(null);
    };

    return (
        <div className="App">
            <Container maxWidth={false}>
                {!showAnalytics ? (
                    // Table view
                    <>
                        <h2 className="heading-title">Template Analytics</h2>
                        
                        {/* Search Field */}
                        <Grid item xs={2} md={2} mb={2}>
                            <TextField
                                sx={{ width: 250 }}
                                size="small"
                                placeholder="Search"
                                title="Search by Template Name"
                                value={searchQuery}
                                onChange={handleSearchQueryChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Typography variant="caption">{charCount}/50</Typography>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>

                        {/* WhatsApp Templates Table */}
                        <TemplateAnalyticsTable 
                            refreshkey={props.refreshKey} 
                            setTemplatesNamesData={setTemplatesNamesData} 
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                            onTemplateClick={handleTemplateClick}  // Pass the handler
                        />
                    </>
                ) : (
                    // Analytics view
                    <>
                        <Box sx={{ mb: 3 }}>
                            <Button 
                                startIcon={<ArrowBackIcon />} 
                                onClick={handleBackToTable}
                                variant="outlined"
                            >
                                Back to templates
                            </Button>
                        </Box>
                        
                        {/* Show the template analytics tab with the selected template */}
                        <TemplateAnalyticsTab template={selectedTemplate} />
                    </>
                )}
            </Container>
        </div>
    );
}

export default TemplateAnalytics;