import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemAvatar, ListItemText, ListItemButton, Avatar, Divider, Typography, Box, Chip, TextField, InputAdornment } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { useChatHistoryContext } from '../hooks/ChatHistoryProvider';
import { CHAT_CLIENT_HISTORY_ENDPOINT } from '../config/urls';
import axios from 'axios';

const ChatHistoryList = () => {
  const [chats, setChats] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { selectedUser, setSelectedUser } = useChatHistoryContext();

  const handleClick = (user) => {
    setSelectedUser(user);
    localStorage.setItem("lastTopicSelected", JSON.stringify(user.topic));
  };

  const parseMessage = (message) => {
    return message ?? ""
      .replace(/_([^_]+)_/g, '<i>$1</i>')
      .replace(/\*([^\*]+)\*/g, '<b>$1</b>')
      .replace(/~([^~]+)~/g, '<s>$1</s>');
  };

  useEffect(() => {
    const fetchChatHistory = async () => {
      try {
        let tenant = localStorage.getItem("tenant");
        const response = await axios.get(CHAT_CLIENT_HISTORY_ENDPOINT, {
          headers: {
            "X-tenant-ID": tenant
          }
        });
        const chatData = response.data.data || [];
        setChats(chatData);
  
        // Set the first chat as the selected user if at least one chat exists
        if (chatData.length > 0 && !selectedUser) {
          setSelectedUser(chatData[0]);
          localStorage.setItem("lastTopicSelected", JSON.stringify(chatData[0].topic));
        }
      } catch (error) {
        console.error("Error fetching chat history:", error);
      }
    };
  
    fetchChatHistory();
  }, []);
  

  const filteredChats = chats.filter(chat => {
    const query = searchQuery.toLowerCase();
    return (
      chat.name.toLowerCase().includes(query) ||
      chat.sender.toLowerCase().includes(query) ||
      (chat.message && chat.message.toLowerCase().includes(query))
    );
  });
  

  return (
    <>
      <Box px={2} py={1}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ color: 'white' }} />
              </InputAdornment>
            ),
            style: { color: 'white' }
          }}
          sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'white', // Default border color
            },
            '&:hover fieldset': {
              borderColor: 'white', // Border color on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white !important', // Border color when focused
            },
          },
          input: { color: 'white' } // Ensure text is visible
        }}
        />
      </Box>
      {/* <Box sx={{ maxHeight: '550px', overflowY: 'auto' }}> */}
      <Box
        sx={{
          maxHeight: '550px',
          overflowY: 'auto',
          scrollbarWidth: 'thin', // For Firefox
          scrollbarColor: '#555 transparent', // For Firefox
          '&::-webkit-scrollbar': {
            width: '8px', // Wider scrollbar
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent', // Invisible track
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(255, 255, 255, 0.3)', // Semi-transparent white
            borderRadius: '10px',
            transition: 'background 0.3s ease-in-out',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: 'rgba(255, 255, 255, 0.6)', // Darker on hover
          },
        }}
      >

      <List>
        {filteredChats.length > 0 ? (
          filteredChats.map((chat, index) => (
            <React.Fragment key={chat.id}>
              <ListItemButton button onClick={() => handleClick(chat)}>
                <ListItemAvatar>
                  <Avatar><PersonIcon /></Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${chat.name}`}
                  secondary={
                    <Typography component="span" variant="body2" style={{ color: '#d9d9d9' }}>
                       +{chat.sender}
                     </Typography>
                  }
                  // secondary={
                  //   <Typography component="span" variant="body2" style={{ color: '#d9d9d9' }}>
                  //     {parseMessage(chat.message?.length > 50 ? `${chat.message.substring(0, 50)}...` : chat.message)}
                  //   </Typography>
                  // }
                />
                {chat.supportUser === null && (
                  <Chip label="new" color="error" size="small" />
                )}
              </ListItemButton>
              {index < filteredChats.length - 1 && <Divider component="li" variant="middle" />}
            </React.Fragment>
          ))
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center" py={5} color="textSecondary">
            <ChatBubbleOutlineIcon fontSize="large" />
            <Typography variant="h6" mt={2}>
              No Chats Available
            </Typography>
            <Typography variant="body2">
              Start a new conversation to see your chats here.
            </Typography>
          </Box>
        )}
      </List>
      </Box>
    </>
  );
};

export default ChatHistoryList;