import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Chip,
  IconButton,
  Collapse,
  InputAdornment,
  Stack,
  Alert,
  AlertTitle
} from '@mui/material';
import {
  Search,
  FilterList,
  ExpandMore,
  ExpandLess,
  MonetizationOn,
  People,
  Person,
  TrendingUp,
  Close
} from '@mui/icons-material';

const initialData = {
  totalAUM: "₹0",
  aumTrend: "+0%",
  totalClients: 0,
  totalRM: 0,
  activeRM: 0,
};

const initialRMList = {
  count: 0,
  results: []
};

const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:8000/api`;
// const srm_id = 2;

const RMMetricsDashboardTab = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [page, setPage] = useState(0);
  const [dashboardData, setDashboardData] = useState(initialData);
  const [rmListData, setRmListData] = useState(initialRMList);
  const [loading, setLoading] = useState(false);
  const [alertObj, setAlertObj] = useState({
    active: false,
    msg: "",
    type: "",
    statusCode: ""
  });
  const [expandedCards, setExpandedCards] = useState({
    metrics: true,
    rmList: true
  });

  const formatAUM = (aumValue) => {
    const num = parseFloat(aumValue);
    if (isNaN(num)) return "₹0.00";
    const [integerPart, decimalPart] = num.toFixed(2).split('.');
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `₹${formattedInteger}.${decimalPart}`;
  };

  // Function to fetch dashboard metrics
  const fetchRMData = async () => {
    setLoading(true);
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const tenant = localStorage.getItem('tenant');
      const headers = {
        "Content-Type": "application/json",
        "X-Tenant-Id": tenant
      };
      const response = await axios.get(`${URL}/rm-metrics/metrics/?srm_id=${user.id}`, {
          headers: {
            "Content-Type": "application/json", // Optional, depending on API requirements
            "X-Tenant-Id": tenant
          }
        });

      if (response.data) {
        setDashboardData(prevData => ({
          ...prevData,
          totalAUM: formatAUM(response.data.totalAUM || '0'),
          totalClients: response.data.totalClients || 0,
          totalRM: response.data.totalRM || 0,
          activeRM: response.data.totalRM || 0,
          aumTrend: response.data.aumTrend || prevData.aumTrend,
        }));
      }
    } catch (err) {
      console.error('Error fetching metrics data:', err);
    }
  };

  // Function to fetch RM list with pagination
  const fetchRMListData = async (search = '', pageNum = 0) => {
    setLoading(true);
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const tenant = localStorage.getItem('tenant');
      const headers = {
        "Content-Type": "application/json",
        "X-Tenant-Id": tenant
      };
      const response = await axios.get(`${URL}/rm-metrics/srm-rm-list/?srm_id=${user.id}&search_value=${search}&limit=10&offset=${pageNum * 10}`, {headers: headers});

      if (response.data) {
        setRmListData({
          count: response.data.count,
          results: response.data.results.map(rm => ({
            id: rm.id,
            name: rm.rm_name,
            aum: formatAUM(rm.aum),
            clientCount: rm.client_count,
            region: rm.region,
            lastActivity: rm.last_activity,
            status: rm.status.toLowerCase()
          }))
        });
      }
    } catch (err) {
      console.error('Error fetching RM list:', err);
      setRmListData(initialRMList);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRMData();
    fetchRMListData();
  }, []);

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    setCharCount(newSearchTerm.length);
    setPage(0);
    fetchRMListData(newSearchTerm, 0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchRMListData(searchTerm, newPage);
  };

  const handleAlertClose = () => {
    setAlertObj(prev => ({ ...prev, active: false }));
  };

  return (
    <Box sx={{ p: 3, maxWidth: 'xl', margin: 'auto' }}>
      {/* Dashboard Header */}
      <Stack spacing={1} sx={{ mb: 4 }}>
        <Typography variant="h4" component="h1">
          RM Metrics Dashboard
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Relationship Manager Overview
        </Typography>
      </Stack>

      {/* Metrics Cards Grid */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        {/* Total AUM Card */}
        <Grid item xs={6}>
          <Card>
            <CardHeader
              title="Total AUM"
              action={
                <IconButton>
                  <MonetizationOn color="primary" />
                </IconButton>
              }
            />
            <CardContent>
              <Typography variant="h4">{dashboardData.totalAUM}</Typography>
              <Typography variant="subtitle2" color="success.main" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <TrendingUp fontSize="small" />
                {dashboardData.aumTrend}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Total Clients Card */}
        <Grid item xs={6}>
          <Card>
            <CardHeader
              title="Total Clients"
              action={
                <IconButton>
                  <People color="primary" />
                </IconButton>
              }
            />
            <CardContent>
              <Typography variant="h4">{dashboardData.totalClients}</Typography>
              <Typography variant="subtitle2" color="text.secondary">
                Across all relationship managers
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Total RMs Card */}
        <Grid item xs={6}>
          <Card>
            <CardHeader
              title="Total RMs"
              action={
                <IconButton>
                  <Person color="primary" />
                </IconButton>
              }
            />
            <CardContent>
              <Typography variant="h4">{dashboardData.totalRM}</Typography>
              <Typography variant="subtitle2" color="text.secondary">
                {dashboardData.activeRM} Active RMs
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Average Performance Card */}
        <Grid item xs={6}>
          <Card>
            <CardHeader
              title="Average Performance"
              action={
                <IconButton>
                  <TrendingUp color="primary" />
                </IconButton>
              }
            />
            <CardContent>
              <Typography variant="h4">N/A%</Typography>
              <Typography variant="subtitle2" color="text.secondary">
                Target Achievement Rate
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* RM List Section */}
      <Card>
        <CardHeader
          title="Relationship Manager List"
          action={
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <TextField
                size="small"
                placeholder="Search RMs..."
                value={searchTerm}
                onChange={handleSearchChange}
                sx={{ width: 250 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="caption" color="text.secondary">
                        {charCount}/50
                      </Typography>
                    </InputAdornment>
                  )
                }}
              />
              <Button
                variant="outlined"
                startIcon={<FilterList />}
              >
                Filter
              </Button>
            </Box>
          }
        />
        <Collapse in={expandedCards.rmList}>
          <CardContent>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>RM Name</TableCell>
                    <TableCell>AUM</TableCell>
                    <TableCell>Client Count</TableCell>
                    <TableCell>Region</TableCell>
                    <TableCell>Last Activity</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rmListData.results.map((rm) => (
                    <TableRow key={rm.id}>
                      <TableCell>{rm.name}</TableCell>
                      <TableCell>{rm.aum}</TableCell>
                      <TableCell>{rm.clientCount}</TableCell>
                      <TableCell>{rm.region}</TableCell>
                      <TableCell>
                      {rm.lastActivity 
                        ? new Date(rm.lastActivity).toLocaleDateString()
                        : "No activity"}
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={rm.status.charAt(0).toUpperCase() + rm.status.slice(1)}
                          color={rm.status === 'active' ? 'success' : 'error'}
                          size="small"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={rmListData.count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={10}
              rowsPerPageOptions={[10]}
            />
          </CardContent>
        </Collapse>

        {/* Alert at the bottom of the card */}
        {alertObj.active && (
          <Box sx={{ p: 2 }}>
            <Alert
              severity={alertObj.type}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
            >
              <AlertTitle>{alertObj.statusCode}</AlertTitle>
              {alertObj.msg}
            </Alert>
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default RMMetricsDashboardTab;