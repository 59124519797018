import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Collapse,
  Alert,
  Box,
  Grid,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import axios from 'axios';

import { 
  AUDIENCE_UPDATE_ENDPOINT,
  SEGMENT_CREATE_ENDPOINT,
  SEGMENT_EDIT_ENDPOINT
} from '../../config/urls';

import { useAuth } from '../../hooks/AuthProvider';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const AudienceFormDialog = ({ 
    modalState, 
    handleModalState, 
    selectedClient, 
    allSegments, 
    activeSegments, 
    allSegmentsDetails, 
    allActiveSegmentsDetails,
    allZonesDetails,
    fetchData }) => {
  
  const { getValidToken } = useAuth();
  
      // create segment variables
  const [createSegmentName, setCreateSegmentName] = useState('');
  const [createSegmentDescription, setCreateSegmentDescription] = useState('');

  // edit segment variables
  const [segmentCode, setSegmentCode] = useState('');
  const [selectedSegment, setSelectedSegment] = useState('Select a segment');
  
  // update segment variables
  const [selectedSegmentName, setSelectedSegmentName] = useState('');
  const [selectedSegmentDescription, setSelectedSegmentDescription] = useState('');
  const [tickedSegment, setTickedSegment] = useState(selectedClient?.segments ?? []);
  const [newtickedSegment, setNewtickedSegment] = useState([]);

  const handleNameChange = (event) => {
      const newName = event.target.value;
      
      if (newName === '' || newName.trim().length > 0) {
          if (newName.length <= 50) {
            setCreateSegmentName(newName);
          }
      }
  };

  const handleDescriptionChange = (event) => {
    const newDescription = event.target.value;
    
    if (newDescription === '' || newDescription.trim().length > 0) {
      if (newDescription.length <= 250) {
        setCreateSegmentDescription(newDescription);
      }
    }
  };

  const handleUpdatedNameChange = (event) => {
    const newName = event.target.value;
    
    if (newName === '' || newName.trim().length > 0) {
        if (newName.length <= 50) {
          setSelectedSegmentName(newName);
        }
    }
};

const handleUpdatedDescriptionChange = (event) => {
  const newDescription = event.target.value;
  
  if (newDescription === '' || newDescription.trim().length > 0) {
    if (newDescription.length <= 250) {
      setSelectedSegmentDescription(newDescription);
    }
  }
};

  const handleSegmentChange = (event) => {
    const selectedSegmentName = event.target.value;
    setSelectedSegment(selectedSegmentName);
    
    const selectedSegment = allSegmentsDetails.find(
      segment => segment.segment_name === selectedSegmentName
    );
    
    if (selectedSegment) {
      console.log("Selected Segment ", selectedSegment);
      setSelectedSegmentName(selectedSegment.segment_name);
      setSelectedSegmentDescription(selectedSegment.segment_description);
      setSegmentCode(selectedSegment.id);
    }
  };


  const [alertObj, setAlertObj] = useState({
    active: false,
    msg: "",
    type: ""
  });

  const handleAlertClose = () => {
    setAlertObj({
      active: false,
      type: "",
      msg: ""
    });
  };

  const handleClose = () => {
    handleModalState({ isOpen: false });
    handleAlertClose();
    
    setSegmentCode('');
    setSelectedSegment('Select a segment');
    setSelectedSegmentName('');
    setSelectedSegmentDescription('');
    setTickedSegment([]);
    setCreateSegmentName('');
    setCreateSegmentDescription('');

    fetchData();
  };

  const handleActionUpdateSave = async() => {

    const apiUrl =  AUDIENCE_UPDATE_ENDPOINT;

    console.log("API URL: ", apiUrl);

    const data = {
      "phone_no": selectedClient.phone_no,
      "segment_list": tickedSegment
    };

    console.log("Payload: ", data);

    const token = await getValidToken();
    const tenant = localStorage.getItem('tenant');

    axios.post(apiUrl, data , {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
        "X-Tenant-Id": tenant
      },
    })
      .then(response => {
        console.log('API Response:', response.data);
        setAlertObj({
          active: true,
          type: "success",
          msg: "Customer Segment Updated Successfully"
        });
        // handleClose();
      })
      .catch(error => {
        console.error('API Error:', error.response.data);
        setAlertObj({
          active: true,
          type: "error",
          msg: "Failed to Update Customer Segment"
        });
      });
  };

  const handleEditSegmentButton = async() => {

    const apiUrl =  SEGMENT_EDIT_ENDPOINT;
    console.log("API URL: ", apiUrl);

    if (selectedSegment === 'Select a segment') {
      setAlertObj({
        active: true,
        type: "error",
        msg: "Please select a segment"
      });
      return;
    }

    const data = {
      "segment_code": segmentCode,
      "segment_name": selectedSegmentName,
      "segment_description": selectedSegmentDescription
    };
    console.log("Payload: ", data);

    const token = await getValidToken();
    const tenant = localStorage.getItem('tenant');

    axios.post(apiUrl, data, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
        "X-Tenant-Id": tenant
        
      },
    })
      .then(response => {
        console.log('Edit API Response:', response.data);
        setAlertObj({
          active: true,
          type: "success",
          msg: "Segment Updated Successfully"
        });
        // handleClose();
      })
      .catch(error => {
        console.error('API Error:', error.response.data);
        setAlertObj({
          active: true,
          type: "error",
          msg: "Failed to Update Segment"
        });
      });
  };

  const handleInactiveButton = async() => {

    const apiUrl =  SEGMENT_EDIT_ENDPOINT;
    console.log("API URL: ", apiUrl);

    if (selectedSegment === 'Select a segment') {
      setAlertObj({
        active: true,
        type: "error",
        msg: "Please select a segment"
      });
      return;
    }

    const data = {
      "segment_code": segmentCode,
      "segment_name": selectedSegment,
      "active": activeSegments.includes(selectedSegment) ? false : true
    };
    console.log("Payload: ", data);

    const token = await getValidToken();
    const tenant = localStorage.getItem('tenant');

    axios.post(apiUrl, data, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
        "X-Tenant-Id": tenant
      }
    })
      .then(response => {
        console.log('Segment Status API Response:', response.data);
        setAlertObj({
          active: true,
          type: "success",
          msg: `${response.data.message}`
        });
      })
      .catch(error => {
        console.error('API Error:', error.response.data);
        setAlertObj({
          active: true,
          type: "error",
          msg: "Failed to Update Segment"
        });
      });
  };

  const handleCreateSegmentSave = async() => {

    const apiUrl = SEGMENT_CREATE_ENDPOINT;

    console.log("API URL: ", apiUrl);

    if (createSegmentName === '') {
      setAlertObj({
        active: true,
        type: "error",
        msg: "Please enter a name for the segment"
      });
      return;
    }


    const data = {
      "segment_name": createSegmentName,
      "segment_description": createSegmentDescription,
    };

    console.log("Payload: ", data);

    const token = await getValidToken();
    const tenant = localStorage.getItem('tenant');

    axios.post(apiUrl, data, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        "X-Tenant-Id": tenant
      },
    })
      .then(response => {
        console.log('Create Segment API Response:', response.data);
        setAlertObj({
          active: true,
          type: "success",
          msg: "Segment Created Successfully"
        });
        // handleClose();
      })
      .catch(error => {
        console.error('API Error:', error.response.data);
        setAlertObj({
          active: true,
          type: "error",
          msg: error.response.data.message
        });
      });
  };

  const createSegmentBody = () => {
    return (
      <>
        <div style={{ position: 'relative' }}>
          <strong>Name: *</strong>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            placeholder="Enter name"
            type="text"
            fullWidth
            onChange={handleNameChange}
            value={createSegmentName}
            required
          />
          <Typography 
              variant="body2" 
              sx={{ 
                color: 'gray', 
                position: 'absolute', 
                right: '10px', 
                top: '10px',
                pointerEvents: 'none'
              }}>
              {`${createSegmentName.length}/50`}
          </Typography>
        </div>

        <div style={{ position: 'relative' }}>
          <strong>Description:</strong>
          <TextField
            autoFocus
            margin="dense"
            id="description"
            placeholder="Enter description"
            multiline
            rows={4}
            type="text"
            fullWidth
            // onChange={(e) => setCreateSegmentDescription(e.target.value)}
            onChange={handleDescriptionChange}
            value={createSegmentDescription}
          />
          <Typography 
            variant="body2"
            sx={{ 
              color: 'gray',
              position: 'absolute',
              right: '10px',
              top: '10px',
              pointerEvents: 'none'
            }}>
            {`${createSegmentDescription.length}/250`}
          </Typography>
        </div>
      </>
    );
  };

  const editSegmentBody = () => {
    return (
      <>
        <div style={{ position: 'relative' }}>
          <strong>Select a Segment:</strong>
          <Select 
            value={selectedSegment} 
            onChange={handleSegmentChange}
            fullWidth
            sx={{ mt: 1 }}
          >
            <MenuItem value="Select a segment">
              Select a segment
            </MenuItem>
            {allSegments.map((segment, index) => (
              <MenuItem 
                key={index} 
                value={segment}
              >
                {segment}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div style={{ position: 'relative', marginTop: '20px' }}>
          <strong>Name:</strong>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            placeholder="Enter name"
            type="text"
            fullWidth
            value={selectedSegmentName}
            onChange={handleUpdatedNameChange}
          />
          <Typography 
            variant="body2" 
            sx={{ 
              color: 'gray', 
              position: 'absolute', 
              right: '10px', 
              top: '10px',
              pointerEvents: 'none'
            }}>
            {`${selectedSegmentName.length}/50`}
          </Typography>

          {/* <strong>Description:</strong>
          <TextField
            autoFocus
            margin="dense"
            id="description"
            placeholder="Enter description"
            multiline
            rows={4}
            type="text"
            fullWidth
            value={selectedSegmentDescription}
            onChange={handleUpdatedDescriptionChange}
          />
          <Typography 
            variant="body2"
            sx={{ 
              color: 'gray',
              position: 'absolute',
              right: '10px',
              top: '100px',
              pointerEvents: 'none'
            }}>
            {`${selectedSegmentDescription?.length || 0}/250`}
          </Typography> */}
        </div>

      </>
    );
  };

  const updateCustomerSegmentBody = () => {
    return (
      <Box sx={{ textAlign: 'left', mb: 0, mx: 2}}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Select one or more segments to add customers to an existing segment
        </Typography>
        {activeSegments.length === 0 ? (
          <Typography variant="body2" color="error">
            No segments found!
          </Typography>
        ) : (
          activeSegments.map((segment, index) => (
            <Box key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tickedSegment.includes(segment)}
                    onChange={(e) => {
                      let newSelectedSegment = [...tickedSegment];
                      if (e.target.checked) {
                        // Add segment if not already in the list
                        if (!newSelectedSegment.includes(segment)) {
                          newSelectedSegment.push(segment);
                        }
                      } else {
                        // Remove segment from the list
                        newSelectedSegment = newSelectedSegment.filter((s) => s !== segment);
                      }
                      setTickedSegment(newSelectedSegment);
                      setNewtickedSegment(newSelectedSegment);
                    }}
                  />
                }
                label={segment}
              />
            </Box>
          ))
        )}
      </Box>
    );
  };

  useEffect(() => {
    // Pre-select segments from selectedClient when modal opens
    setTickedSegment(selectedClient?.segments ?? []);
  }, [modalState.isOpen, selectedClient]);

  useEffect(() => {
    if (selectedSegment === 'Select a segment') {
      setSelectedSegmentName('');
      setSelectedSegmentDescription('');
    }
  }, [segmentCode, selectedSegment, selectedSegmentName, selectedSegmentDescription]);


  useEffect(() => {
      let timeoutId;
      
      if (alertObj.active) {
      timeoutId = setTimeout(() => {
          handleAlertClose();
      }, 5000);
      }
      
      // Cleanup function to clear the timeout when component unmounts or when alertObj changes
      return () => {
          if (timeoutId) {
              clearTimeout(timeoutId);
          }
      };
  }, [alertObj]);


  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={modalState.isOpen}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2, position: 'relative' }} id="customized-dialog-title">
        {/* Conditionally render title based on modalState.mode */}
        {modalState.mode === 'edit_segment' ? "Edit Segment"
          : modalState.mode === 'create_segment' ? "Create Segment"
            : modalState.mode === 'update_customer_segments' ? "Add Customers To Existing Segment"
            : ''}

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 0,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>


      <DialogContent dividers>
        <Collapse in={alertObj.active}>
          <Alert sx={{ mb: 2 }} severity={alertObj.type} onClose={handleAlertClose}>
            {alertObj.msg}
          </Alert>
        </Collapse>

        {
        modalState.mode === 'create_segment' ? (
          createSegmentBody()
        ) :
          modalState.mode === 'edit_segment' ? (
            editSegmentBody()
          ) :
            (modalState.mode === 'update_customer_segments') && (
              updateCustomerSegmentBody()
            )
        }
      </DialogContent>
      
      <DialogActions>
        {
          modalState.mode === 'create_segment' ? (
            <>
              <Button variant="contained" onClick={handleCreateSegmentSave}>Save</Button>
              <Button variant="contained" onClick={handleClose}>Close</Button>
            </>
          ) :
            modalState.mode === 'edit_segment' ? (
              <>
                <Button variant="contained" onClick={handleEditSegmentButton}>Save</Button>
                <Button variant="contained" onClick={handleInactiveButton}>
                  {activeSegments.includes(selectedSegment) ? "Inactive" : "Active"}
                </Button>
                <Button variant="contained" onClick={handleClose}>Close</Button>
              </>
            ) :
              modalState.mode === 'update_customer_segments' ? (
                <>
                  <Button variant="contained" disabled={tickedSegment.length === 0} onClick={handleActionUpdateSave}>Save</Button>
                  <Button variant="contained" onClick={handleClose}>Close</Button>
                </>
              ) : (
                <Button autoFocus onClick={handleClose}>Close</Button>
              )
        }
      </DialogActions>
    </BootstrapDialog>
  );
}

export default AudienceFormDialog;
