import * as React from "react";
 
// importing material UI components
import { useState } from 'react';
import Container from '@mui/material/Container';

import RMMetricsDashboardTab from "./RMMetricsDashboardTab";

const RMMetricsDashboard = (props) => {

  return (
    <div className="App">
      <Container maxWidth={false}>
        <>
          <RMMetricsDashboardTab />
        </>
      </Container>

    </div>
  );
}
export default RMMetricsDashboard;