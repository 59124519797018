import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Collapse,
  Alert,
  Box,
  Grid,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import axios from 'axios';

import { 
  PRODUCTS_CREATE_ENDPOINT,
  PRODUCTS_UPDATE_ENDPOINT,
} from '../../config/urls';

import { useAuth } from '../../hooks/AuthProvider';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ProductsFormDialog = ({ 
    modalState, 
    handleModalState, 
    selectedClient, 
    allSegmentsDetails, 
    fetchData }) => {
  
  const { getValidToken } = useAuth();

  console.log("selectedClient: ", selectedClient);

  // New product
  const [newProduct, setNewProduct] = useState({
    content_id: '',
    title: '',
    description: '',
    item_price: 0,
    sale_price: 0,
    status: 'active', // default value
    availability: '' // default value
  });

  // Edit product
  const [editedProduct, setEditedProduct] = useState({
    title: '',
    description: '',
    item_price: 0,
    sale_price: 0,
    status: '',
    availability: ''
  });

  const handleNewProductChange = (field) => (event) => {
    setNewProduct(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };
  
  const handleProductChange = (field) => (event) => {
    setEditedProduct(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const [alertObj, setAlertObj] = useState({
    active: false,
    msg: "",
    type: ""
  });

  const handleAlertClose = () => {
    setAlertObj({
      active: false,
      type: "",
      msg: ""
    });
  };

  const handleClose = () => {
    handleModalState({ isOpen: false });
    handleAlertClose();

    setEditedProduct({
      title: '',
      description: '',
      item_price: 0,
      sale_price: 0,
      status: '',
      availability: ''
    });
    setNewProduct({
      content_id: '',
      title: '',
      description: '',
      item_price: 0,
      sale_price: 0,
      status: 'active', // default value
      availability: '' // default value
    });

    fetchData();
  };

  const handleActionUpdateSave = async() => {


    const apiUrl = `${PRODUCTS_UPDATE_ENDPOINT}${selectedClient.product_id}/`;

    console.log("API URL: ", apiUrl);

    const data = {
      "content_id": selectedClient.content_id,
      ...editedProduct
    };

    console.log("Payload: ", data);

    const token = await getValidToken();
    const tenant = localStorage.getItem('tenant');

    axios.put(apiUrl, data , {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        "X-Tenant-Id": tenant
      },
    })
      .then(response => {
        console.log('API Response:', response.data);
        setAlertObj({
          active: true,
          type: "success",
          msg: "Product Updated Successfully"
        });
        // handleClose();
      })
      .catch(error => {
        console.error('API Error:', error.response.data);
        setAlertObj({
          active: true,
          type: "error",
          msg: "Failed to Update Product"
        });
      });
  };

  const handleCreateProductSave = async() => {

    const apiUrl = PRODUCTS_CREATE_ENDPOINT;

    console.log("API URL: ", apiUrl);

    // Validation
    if (!newProduct.content_id.trim()) {
      setAlertObj({
        active: true,
        type: "error",
        msg: "Please enter a Content ID"
      });
      return;
    }

    if (!newProduct.title.trim()) {
      setAlertObj({
        active: true,
        type: "error",
        msg: "Please enter a title for the product"
      });
      return;
    }

    if (!newProduct.item_price || newProduct.item_price <= 0) {
      setAlertObj({
        active: true,
        type: "error",
        msg: "Please enter a valid item price"
      });
      return;
    }

    console.log("Payload: ", newProduct);

    const token = await getValidToken();
    const tenant = localStorage.getItem('tenant');

    axios.post(apiUrl, newProduct, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        "X-Tenant-Id": tenant
      },
    })
      .then(response => {
        console.log('Create Product API Response:', response.data);
        setAlertObj({
          active: true,
          type: "success",
          msg: "Product Created Successfully"
        });
        // Reset form after successful creation
        setNewProduct({
          content_id: '',
          title: '',
          description: '',
          item_price: 0,
          sale_price: 0,
          status: 'active',
          availability: ''
        });
      })
      .catch(error => {
        console.error('API Error:', error.response.data);
        setAlertObj({
          active: true,
          type: "error",
          msg: error.response.data.message || "Failed to Create Product"
        });
      });
  };

  const addProductBody = () => {
    return (
      <>
        {/* Content ID */}
        <div style={{ position: 'relative' }}>
          <strong>Content ID: *</strong>
          <TextField
            margin="dense"
            id="content_id"
            placeholder="Enter Content ID"
            type="text"
            fullWidth
            onChange={handleNewProductChange('content_id')}
            value={newProduct.content_id}
            required
          />
        </div>

        {/* Product Title */}
        <div style={{ position: 'relative' }}>
          <strong>Product Title: *</strong>
          <TextField
            margin="dense"
            id="title"
            placeholder="Enter title"
            type="text"
            fullWidth
            onChange={handleNewProductChange('title')}
            value={newProduct.title}
            required
          />
          <Typography 
            variant="body2" 
            sx={{ 
              color: 'gray', 
              position: 'absolute', 
              right: '10px', 
              top: '10px',
              pointerEvents: 'none'
            }}>
            {`${newProduct.title.length}/50`}
          </Typography>
        </div>

        {/* Description */}
        <div style={{ position: 'relative' }}>
          <strong>Description:</strong>
          <TextField
            margin="dense"
            id="description"
            placeholder="Enter description"
            multiline
            rows={4}
            type="text"
            fullWidth
            onChange={handleNewProductChange('description')}
            value={newProduct.description}
          />
          <Typography 
            variant="body2"
            sx={{ 
              color: 'gray',
              position: 'absolute',
              right: '10px',
              top: '10px',
              pointerEvents: 'none'
            }}>
            {`${newProduct.description.length}/250`}
          </Typography>
        </div>

        {/* Item Price */}
        <div style={{ position: 'relative' }}>
          <strong>Item Price: *</strong>
          <TextField
            margin="dense"
            id="item_price"
            placeholder="Enter item price"
            type="number"
            fullWidth
            onChange={handleNewProductChange('item_price')}
            value={newProduct.item_price}
            required
          />
        </div>

        {/* Sale Price */}
        <div style={{ position: 'relative' }}>
          <strong>Sale Price:</strong>
          <TextField
            margin="dense"
            id="sale_price"
            placeholder="Enter Sale price"
            type="number"
            fullWidth
            onChange={handleNewProductChange('sale_price')}
            value={newProduct.sale_price}
          />
        </div>

        {/* Status */}
        <div style={{ position: 'relative' }}>
          <strong>Status:</strong>
          <Select
            value={newProduct.status}
            onChange={handleNewProductChange('status')}
            fullWidth
          >
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
          </Select>
        </div>

        {/* Availability */}
        <div style={{ position: 'relative' }}>
          <strong>Availability:</strong>
          <Select
            value={newProduct.availability}
            onChange={handleNewProductChange('availability')}
            fullWidth
          >
            <MenuItem value="">Select an option</MenuItem>
            <MenuItem value="in_stock">In Stock</MenuItem>
            <MenuItem value="out_of_stock">Out of Stock</MenuItem>
            <MenuItem value="coming_soon">Coming Soon</MenuItem>
            <MenuItem value="discontinued">Discontinued</MenuItem>
          </Select>
        </div>
      </>
    );
  };

  const updateProductBody = () => {
    return (
      <>
        {/* Title */}
        <div style={{ position: 'relative' }}>
          <strong>Title:</strong>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            placeholder="Enter title"
            type="text"
            fullWidth
            onChange={handleProductChange('title')}
            value={editedProduct.title}
            required
          />
          <Typography 
            variant="body2" 
            sx={{ 
              color: 'gray', 
              position: 'absolute', 
              right: '10px', 
              top: '10px',
              pointerEvents: 'none'
            }}>
            {`${editedProduct.title.length}/50`}
          </Typography>
        </div>

        {/* Description */}
        <div style={{ position: 'relative' }}>
          <strong>Description:</strong>
          <TextField
            margin="dense"
            id="description"
            placeholder="Enter description"
            multiline
            rows={4}
            type="text"
            fullWidth
            onChange={handleProductChange('description')}
            value={editedProduct.description}
          />
          <Typography 
            variant="body2"
            sx={{ 
              color: 'gray',
              position: 'absolute',
              right: '10px',
              top: '10px',
              pointerEvents: 'none'
            }}>
            {`${editedProduct.description.length}/250`}
          </Typography>
        </div>

        {/* Item Price */}
        <div style={{ position: 'relative' }}>
          <strong>Item Price:</strong>
          <TextField
            margin="dense"
            id="item_price"
            placeholder="Enter item price"
            type="number"
            fullWidth
            onChange={handleProductChange('item_price')}
            value={editedProduct.item_price}
            required
          />
        </div>

        {/* Sale Price */}
        <div style={{ position: 'relative' }}>
          <strong>Sale Price:</strong>
          <TextField
            margin="dense"
            id="sale_price"
            placeholder="Enter Sale price"
            type="number"
            fullWidth
            onChange={handleProductChange('sale_price')}
            value={editedProduct.sale_price}
          />
        </div>

        {/* Status - Dropdown */}
        <div style={{ position: 'relative' }}>
          <strong>Status:</strong>
          <Select
            value={editedProduct.status}
            onChange={handleProductChange('status')}
            fullWidth
          >
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
          </Select>
        </div>

        {/* Availability - Dropdown */}
        <div style={{ position: 'relative' }}>
          <strong>Availability:</strong>
          <Select
            value={editedProduct.availability}
            onChange={handleProductChange('availability')}
            fullWidth
          >
            <MenuItem value="">Select an option</MenuItem>
            <MenuItem value="in_stock">In Stock</MenuItem>
            <MenuItem value="out_of_stock">Out of Stock</MenuItem>
            <MenuItem value="coming_soon">Coming Soon</MenuItem>
            <MenuItem value="discontinued">Discontinued</MenuItem>
          </Select>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (selectedClient) {
      setEditedProduct({
        title: selectedClient.title || '',
        description: selectedClient.description || '',
        item_price: selectedClient.item_price || 0,
        sale_price: selectedClient.sale_price || 0,
        status: selectedClient.status || 'active',
        availability: selectedClient.availability || ''
      });
    }
  }, [
    selectedClient
  ]);

  useEffect(() => {
    let timeoutId;
    
    if (alertObj.active) {
      timeoutId = setTimeout(() => {
          handleAlertClose();
      }, 5000);
    }
    
    // Cleanup function to clear the timeout when component unmounts or when alertObj changes
    return () => {
      if (timeoutId) {
          clearTimeout(timeoutId);
      }
    };
  }, [alertObj]);


  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={modalState.isOpen}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2, position: 'relative' }} id="customized-dialog-title">
        {/* Conditionally render title based on modalState.mode */}
        {modalState.mode === 'add_product' ? "Add Product"
          : modalState.mode === 'update_product' ? "Edit Product"
          : ''}

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 0,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>


      <DialogContent dividers>
        <Collapse in={alertObj.active}>
          <Alert sx={{ mb: 2 }} severity={alertObj.type} onClose={handleAlertClose}>
            {alertObj.msg}
          </Alert>
        </Collapse>

        {
        modalState.mode === 'add_product' ? (
          addProductBody()
        ) :
          (modalState.mode === 'update_product') && (
            updateProductBody()
          )
        }
      </DialogContent>
      
      <DialogActions>
        {
          modalState.mode === 'add_product' ? (
            <>
              <Button variant="contained" onClick={handleCreateProductSave}>Save</Button>
              <Button variant="contained" onClick={handleClose}>Close</Button>
            </>
          ) :
            modalState.mode === 'update_product' ? (
              <>
                <Button variant="contained" onClick={handleActionUpdateSave}>Save</Button>
                <Button variant="contained" onClick={handleClose}>Close</Button>
              </>
            ) : (
              <Button autoFocus onClick={handleClose}>Close</Button>
            )
        }
      </DialogActions>
    </BootstrapDialog>
  );
}

export default ProductsFormDialog;
