import React, { useState, useEffect, useRef } from 'react';
import {
    Select,
    MenuItem,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Typography,
} from '@mui/material';

const SingleContact = (props) => {
    const { setCountryCode, setDialCode, setFirstName, setLastName } = props;

    const [singleContactCountryCode, setSingleContactCountryCode] = useState('');
    const [phoneNumberSuffix, setPhoneNumberSuffix] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [firstName, setFirstNameLocal] = useState('');
    const [lastName, setLastNameLocal] = useState('');

    const handlePhoneNumberChange = (e) => {
        // Only allow numbers
        const value = e.target.value.replace(/\D/g, '');
        
        setPhoneNumberSuffix(value);

        // Validate phone number length
        if (value.length !== 10) {
            setPhoneError('Phone number must be exactly 10 digits');
        } else {
            setPhoneError('');
        }
    }
    
    const handleConcatenation = () => {
        if (singleContactCountryCode && phoneNumberSuffix && phoneNumberSuffix.length === 10) {
            setCountryCode(singleContactCountryCode);
            setDialCode(phoneNumberSuffix);
        } else {
            setCountryCode('');
            setDialCode('');
        }
    }

    // Handle first name changes and update parent component state
    const handleFirstNameChange = (e) => {
        setFirstNameLocal(e.target.value);
        setFirstName(e.target.value);
    };

    // Handle last name changes and update parent component state
    const handleLastNameChange = (e) => {
        setLastNameLocal(e.target.value);
        setLastName(e.target.value);
    };

    useEffect(() => {
        handleConcatenation();
    }, [singleContactCountryCode, phoneNumberSuffix]);
    
    return (
        <>
            {/* First Name and Last Name Fields */}
            <Grid container spacing={2} sx={{mt: 1}}>
                <Grid item xs={12}>
                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }} gutterBottom>
                        Contact Information
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle1" sx={{ mb: 1 }} gutterBottom>
                        First Name *
                    </Typography>
                    <TextField
                        fullWidth
                        label="First Name"
                        variant="outlined"
                        value={firstName}
                        onChange={handleFirstNameChange}
                        required
                    />
                </Grid>
                <Grid item xs={9}>
                    <Typography variant="subtitle1" sx={{ mb: 1 }} gutterBottom>
                        Last Name *
                    </Typography>
                    <TextField
                        sx={{ width: '80%' }}
                        label="Last Name"
                        variant="outlined"
                        value={lastName}
                        onChange={handleLastNameChange}
                        required
                    />
                </Grid>
            </Grid>

            {/* Original Phone Number Fields */}
            <Grid container spacing={2} alignItems="center" sx={{mt: 1}}>
                <Grid item xs={3}>
                    <Typography variant="subtitle1" sx={{ mt: 1, mb: 1 }} gutterBottom>
                        Country Code *
                    </Typography>
                    <FormControl fullWidth>
                        <InputLabel>Country Code *</InputLabel>
                        <Select
                            label="Country Code"
                            value={singleContactCountryCode}
                            onChange={(e) => setSingleContactCountryCode(e.target.value)}
                            fullWidth
                            required
                        >
                            <MenuItem value="">Select Country Code</MenuItem>
                            <MenuItem value="91">IND (+91)</MenuItem>
                            <MenuItem value="1">US (+1)</MenuItem>
                            <MenuItem value="971">UAE (+971)</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={9}>
                    <Typography variant="subtitle1" sx={{ mt: 1, mb: 1 }} gutterBottom>
                        Phone Number *
                    </Typography>
                    <TextField
                        sx={{ width: '80%' }}
                        id="outlined-basic"
                        label="Phone Number"
                        variant="outlined"
                        value={phoneNumberSuffix}
                        onChange={handlePhoneNumberChange}
                        required
                    />
                </Grid>
                {phoneError && (
                    <Typography 
                        color="error" 
                        variant="caption" 
                        sx={{ 
                            display: 'block', 
                            mt: 1, 
                            ml: 1 
                        }}
                    >
                        {phoneError}
                    </Typography>
                )}
            </Grid>
        </>
    );
};

export default SingleContact;