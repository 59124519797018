import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  IconButton,
  Collapse,
  LinearProgress,
  InputAdornment
} from '@mui/material';
import {
  Person,
  TrendingUp,
  CalendarToday,
  AttachMoney,
  ArrowUpward,
  Search,
  FilterList,
  ExpandMore,
  ExpandLess
} from '@mui/icons-material';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:8000/api`;

const RMDashboardTab = () => {
  const [expandedCards, setExpandedCards] = useState({
    clientEngagement: true,
    interactionSummary: true,
    aumTrend: true,
    clientList: true
  });

  const [dashboardData, setDashboardData] = useState({
    rm_info: {},
    total_aum: 0,
    last_client_contact: null,
    engagement_metrics: {
      last_3_months: { engaged_clients: 0, total_clients: 0, percentage: 0, meeting_count: 0 },
      last_6_months: { engaged_clients: 0, total_clients: 0, percentage: 0, meeting_count: 0 },
      last_12_months: { engaged_clients: 0, total_clients: 0, percentage: 0, meeting_count: 0 }
    },
    clients: []
  });

  // Mock data (same as provided)
  const profileData = {
    rmName: "Sarah Johnson",
    totalAUM: "₹2.45B",
    aumTrend: "+8.2%",
    lastFundAddition: {
      amount: "₹125M",
      date: "2025-01-15",
      trend: "+12.5%"
    },
    lastConversation: "2025-01-28",
    aumHistory: [
      { month: 'Aug', aum: 2.1 },
      { month: 'Sep', aum: 2.2 },
      { month: 'Oct', aum: 2.25 },
      { month: 'Nov', aum: 2.3 },
      { month: 'Dec', aum: 2.4 },
      { month: 'Jan', aum: 2.45 }
    ],
    clients: [
      {
        id: '1',
        name: 'John Smith',
        aum: '₹450M',
        lastContact: '2025-01-28',
        investmentType: 'Equity',
        status: 'active'
      },
      // ... other clients
    ]
  };

  const toggleCard = (cardName) => {
    setExpandedCards(prev => ({
      ...prev,
      [cardName]: !prev[cardName]
    }));
  };

  // Helper function to format currency
  const formatCurrency = (amount) => {
    if (!amount && amount !== 0) return 'N/A';
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 2
    }).format(amount);
  };

  // Helper function to format date
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    try {
      return new Date(dateString).toLocaleDateString();
    } catch (error) {
      return 'Invalid Date';
    }
  };

  const fetchDashboardData = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const tenant = localStorage.getItem('tenant');
      const headers = {
        "Content-Type": "application/json",
        "X-Tenant-Id": tenant
      };

      const response = await fetch(`${URL}/rm-dashboard/rm/list/?rm_id=${user.id}`, {headers});
      const data = await response.json();
      setDashboardData(data);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  return (
    <Box sx={{ p: 3, maxWidth: 'xl', margin: 'auto' }}>
      {/* Header */}
      <Typography variant="h4" gutterBottom>
        My Insights
      </Typography>
      <Typography variant="subtitle1" color="textSecondary" gutterBottom>
        Relationship Manager Dashboard
      </Typography>

      {/* Key Metrics Grid */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        {/* AUM Card */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader
              title="Total AUM"
              action={<CurrencyRupeeIcon />}
            />
            <CardContent>
              <Typography variant="h5">
                {formatCurrency(dashboardData.total_aum)}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                As of latest holding date
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Client Engagement */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader
              title="Total Clients"
              action={<Person />}
            />
            <CardContent>
              <Typography variant="h5">
                {dashboardData.clients?.length || 0}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Active Clients
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Last Contact */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader
              title="Last Client Contact"
              action={<CalendarToday />}
            />
            <CardContent>
              <Typography variant="h5">
                {formatDate(dashboardData.last_client_contact)}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {dashboardData.last_client_contact ? 'Last client interaction' : 'No recent activity'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* AUM Trend */}
      <Card sx={{ mb: 3 }}>
        <CardHeader
          title="AUM Trend"
          action={
            <IconButton onClick={() => toggleCard('aumTrend')}>
              {expandedCards.aumTrend ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          }
        />
        <Collapse in={expandedCards.aumTrend}>
          <CardContent>
            <Box sx={{ width: '100%', height: 300, mx: 'auto' }}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart 
                  data={dashboardData.aumHistory}
                  margin={{ top: 10, right: 30, left: 50, bottom: 10 }} // Increased left margin for Y-axis
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis 
                    tickFormatter={(value) => {
                      // Format large numbers with appropriate suffixes
                      if (value >= 10000000) return `${(value / 10000000).toFixed(2)}Cr`;
                      if (value >= 100000) return `${(value / 100000).toFixed(2)}L`;
                      if (value >= 1000) return `${(value / 1000).toFixed(2)}K`;
                      return value.toFixed(2);
                    }}
                    width={55} // Fixed width for Y-axis to ensure enough space
                  />
                    <Tooltip 
                      formatter={(value) => {
                        // Format full currency value
                        const formattedValue = new Intl.NumberFormat('en-IN', {
                          style: 'currency',
                          currency: 'INR',
                          maximumFractionDigits: 2
                        }).format(value);
                        
                        // Add appropriate suffix in brackets
                        let suffix = "";
                        if (value >= 10000000) { // 1 Crore = 10,000,000
                          suffix = ` (${(value / 10000000).toFixed(2)}Cr)`;
                        } else if (value >= 100000) { // 1 Lakh = 100,000
                          suffix = ` (${(value / 100000).toFixed(2)}L)`;
                        } else if (value >= 1000) {
                          suffix = ` (${(value / 1000).toFixed(2)}K)`;
                        }
                        
                        return [formattedValue + suffix, "AUM"];
                      }}
                    />
                  <Line type="monotone" dataKey="aum" stroke="#1976d2" />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </CardContent>
        </Collapse>
      </Card>


      {/* Client Engagement Overview and Interaction Summary */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        {/* Client Engagement Overview */}
        <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
          <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardHeader
              title="Client Engagement Overview"
              action={
                <IconButton onClick={() => toggleCard('clientEngagement')}>
                  {expandedCards.clientEngagement ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              }
            />
            <Collapse in={expandedCards.clientEngagement}>
              <CardContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                  {/* Engagement Metrics */}
                  {['last_3_months', 'last_6_months', 'last_12_months'].map((period) => (
                    <Box key={period}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography variant="body2" fontWeight="medium">
                          {`Last ${period.split('_')[1]} Months:`} {dashboardData.engagement_metrics?.[period]?.engaged_clients || 0} / {dashboardData.engagement_metrics?.[period]?.total_clients || 0} clients
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {dashboardData.clients?.length || 0} clients
                        </Typography>
                      </Box>
                      <LinearProgress
                        variant="determinate"
                        value={dashboardData.engagement_metrics?.[period]?.percentage || 0}
                        sx={{ height: 8, borderRadius: 1 }}
                      />
                    </Box>
                  ))}
                </Box>
              </CardContent>
            </Collapse>
          </Card>
        </Grid>

        {/* Interaction Summary */}
        <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
          <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardHeader
              title="Interaction Summary"
              action={
                <IconButton onClick={() => toggleCard('interactionSummary')}>
                  {expandedCards.interactionSummary ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              }
            />
            <Collapse in={expandedCards.interactionSummary}>
              <CardContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {/* Interaction Summary Metrics */}
                  {[
                    { label: 'Active Clients', key: 'active_clients', bgColor: '#dbeafe', iconColor: '#2563eb', Icon: Person },
                    { label: 'Meetings Conducted', key: 'meetings_conducted', bgColor: '#dcfce7', iconColor: '#16a34a', Icon: CalendarToday },
                    { label: 'Portfolio Reviews', key: 'portfolio_reviews', bgColor: '#f3e8ff', iconColor: '#9333ea', Icon: TrendingUp },
                  ].map(({ label, key, bgColor, iconColor, Icon }) => (
                    <Box
                      key={key}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 2,
                        bgcolor: '#f8fafc',
                        borderRadius: 1,
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Box sx={{ p: 1, bgcolor: bgColor, borderRadius: 1, display: 'flex', alignItems: 'center' }}>
                          <Icon sx={{ color: iconColor }} />
                        </Box>
                        <Box>
                          <Typography variant="body2" fontWeight="medium">{label}</Typography>
                          <Typography variant="body2" color="text.secondary">
                            {key === 'active_clients' ? 'Till date' : 'This month'}
                          </Typography>
                        </Box>
                      </Box>
                      <Typography variant="h6">
                        {dashboardData?.interaction_summary?.[key] === null ? 'N/A' : dashboardData?.interaction_summary?.[key]}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </CardContent>
            </Collapse>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RMDashboardTab;