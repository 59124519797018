import React, { useState, useEffect } from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Chip from '@mui/material/Chip';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { TextField } from '@mui/material';
import { MenuItem } from '@mui/material';
import { Select } from '@mui/material';
import { Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Alert } from '@mui/material';

import dayjs from 'dayjs';
import { WHATSAPP_BILLING_LIST_ENDPOINT, WHATSAPP_BILLING_CREATE_ENDPOINT } from '../../config/urls';
import { useAuth } from '../../hooks/AuthProvider';

const columns = [
  { id: 'date', label: 'Date' },
  { id: 'currency', label: 'Currency' },
  { id: 'marketing', label: 'Marketing Messages' },
  { id: 'utility', label: 'Utility Messages' },
  { id: 'service', label: 'Services' },
  { id: 'marketingCharges', label: 'Marketing Charges' },
  { id: 'serviceCharges', label: 'Service Charges' },
  { id: 'utilityCharges', label: 'Utility Charges' },
  { id: 'totalCharges', label: 'Total Charges' },
];

export default function AppUsageTable({ refreshKey, dateInput }) {
    const { getValidToken } = useAuth();
    const [rowCount, setRowCount] = useState(0);
    
    const [isLoading, setIsLoading] = useState(false); 
    
    const [appUsageData, setAppUsageData] = useState([]);

    const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:8000/api`;
    const [controller, setController] = useState({
        page: 0,
        rowsPerPage: 20
    });

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [dateError, setDateError] = useState(null);

    // Added function to handle cron job execution
    const handleRunCron = async () => {
        if (!fromDate || !toDate) return;

        setIsLoading(true);
        const token = await getValidToken();
        const tenant = localStorage.getItem('tenant');

        try {
        const response = await fetch(WHATSAPP_BILLING_CREATE_ENDPOINT, {
            method: 'POST',
            headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "X-Tenant-Id": tenant
            },
            body: JSON.stringify({
            from_date: dayjs(fromDate).format("YYYY-MM-DD"),
            to_date: dayjs(toDate).format("YYYY-MM-DD")
            })
        });

        if (!response.ok) {
            throw new Error('Failed to run cron job');
        }

        // Refresh the table data after successful cron job
        await fetchUsageData();
        } catch (error) {
        console.error('Error running cron job:', error.message);
        } finally {
        setIsLoading(false);
        }
    };

    const fetchUsageData = async () => {
        let searchDateURL = '';
        if (dateInput) {
        let dateString = dayjs(dateInput).format("YYYY-MM-DD");
        searchDateURL = `&search_date=${dateString}`;

        }

        // Construct query parameters for server-side filtering and pagination
        const queryParams = new URLSearchParams({
        limit: controller.rowsPerPage.toString(),
        offset: (controller.serverSidePage * controller.rowsPerPage).toString(),
        from_date: fromDate ? dayjs(fromDate).format("YYYY-MM-DD") : '',
        to_date: toDate ? dayjs(toDate).format("YYYY-MM-DD") : ''
        });

        const token = await getValidToken();
        const tenant = localStorage.getItem('tenant');  

        try {
        const response = await fetch(`${WHATSAPP_BILLING_LIST_ENDPOINT}?${queryParams}${searchDateURL}`, {
            headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "X-Tenant-Id": tenant
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch usage data');
        }
        const jsonData = await response.json();

        console.log("GET Usage Data: ", jsonData);

        setAppUsageData(jsonData.results);
        setRowCount(jsonData.count);
        } catch (error) {
        console.error('Error fetching usage data:', error.message);
        }
    };
  
    useEffect(() => {
        fetchUsageData();
    }, [
        controller.serverSidePage, 
        controller.rowsPerPage, 
        refreshKey, 
        dateInput, 
        fromDate, 
        toDate
    ]);

    // pagination
    const handlePageChange = (event, newPage) => {
        setController(prev => ({
        ...prev,
        page: newPage,
        serverSidePage: newPage  // Sync server-side page with UI page
        }));
    };

    const handleChangeRowsPerPage = (event) => {
        setController({
        ...prev => ({
            ...prev,
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
            serverSidePage: 0  // Reset to first page
        })
        });
    };

    const handleFromDateChange = (newValue) => {
        if (toDate && newValue && dayjs(newValue).isAfter(toDate)) {
        setDateError('From Date must be less than or equal to To Date');
        } else {
        setDateError(null);
        setFromDate(newValue);
        }
    };

    const handleToDateChange = (newValue) => {
        if (fromDate && newValue && dayjs(newValue).isBefore(fromDate)) {
        setDateError('To Date must be greater than or equal to From Date');
        } else {
        setDateError(null);
        setToDate(newValue);
        }
    };

    const handleResetDateFilter = () => {
        setFromDate(null);
        setToDate(null);
        setDateError(null);
    };

    return (
        <div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    flexDirection: 'column',
                    p: 1,
                    m: 1,
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                }}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                <DatePicker
                                label="From Date"
                                value={fromDate}
                                onChange={handleFromDateChange}
                                renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                <DatePicker
                                label="To Date"
                                value={toDate}
                                onChange={handleToDateChange}
                                renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2}>
                            <Button 
                                variant="outlined"
                                onClick={handleResetDateFilter} 
                                sx={{ height: '100%' }}
                            >
                                Reset
                            </Button>
                        </Grid>
                        {/* Removed Run Cron Button */}
                        {/* <Grid item xs={2}>
                            <Button
                                variant="contained"
                                onClick={handleRunCron}
                                disabled={!fromDate || !toDate || isLoading}
                            >
                                {isLoading ? 'Running...' : 'Run Cron'}
                            </Button>
                        </Grid> */}

                    </Grid>
                    {dateError && (
                        <Alert 
                            severity="error" 
                            sx={{ 
                                width: '100%', 
                                marginTop: 1 
                            }}
                        >
                            {dateError}
                        </Alert>
                    )}

                </Box>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align="left"
                                    style={{ minWidth: column.minWidth, border: '1px solid rgba(224, 224, 224, 1)' }}
                                >
                                    <h3>{column.label}</h3>
                                </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {appUsageData.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={columns.length} align="center">
                                    No match found
                                </TableCell>
                            </TableRow>
                        ) : (
                            appUsageData.map((usage, index) => (
                            <TableRow key={index}>
                                <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }} sx={{ width: '10%' }} >{usage.date}</TableCell>
                                <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{usage.currency}</TableCell>
                                <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{usage.marketing}</TableCell>
                                <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{usage.utility}</TableCell>
                                <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{usage.service}</TableCell>
                                <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{usage.marketing_charges}</TableCell>
                                <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{usage.service_charges}</TableCell>
                                <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{usage.utility_charges}</TableCell>
                                <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{usage.total_charges}</TableCell>
                            </TableRow>
                            ))
                        )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[20]}
                    component="div"
                    count={rowCount}
                    rowsPerPage={controller.rowsPerPage}
                    page={controller.page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
    }
