import * as React from "react";
 
// importing material UI components
import { useState } from 'react';
import FileUploadLogsTable from './FileUploadLogsTable';
import Container from '@mui/material/Container';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import Box from '@mui/material/Box';

const FileUploadLogs = (props) => { 

    const [value, setValue] = useState(null);
    const [datetimeArray, setDateTimeArray] = useState(['day', 'month', 'year']);
    const [cleared, setCleared] = useState(false);

    const handleDateChange = (newDate) => {
        setValue(newDate);
    }

    return (
        <div className="App">
        <Container maxWidth={false}>
            {/* <ButtonRow /> */}
        {/* <p>State: {props.userState} Time remaining: {props.remaining}</p> */}
            <h2 className="heading-title">File Upload Logs</h2>
            <div className='upload-date-picker-div'>
            <p>Latest Upload date</p>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <Box>
                <DemoItem label="">
                <DatePicker
                    sx={{ width: 260 }}
                    label=""
                    defaultValue={value}
                    onChange={(newValue) => handleDateChange(newValue)}
                    disableFuture
                    views={datetimeArray}
                    slotProps={{
                    field: { clearable: true, onClear: () => setCleared(true) },
                    }}
                />
                </DemoItem>
            </Box>
            </LocalizationProvider>
            </div>
            <FileUploadLogsTable key={props.refreshKey} dateInput={value} />
        </Container>
        </div>
    );
}
export default FileUploadLogs;
