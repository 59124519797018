import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemAvatar, ListItemText, Avatar, Divider, Typography, Box, Chip } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { useChatContext } from '../hooks/ChatProvider';

const ChatList = () => {
  const [chats, setChats] = useState([]);

  const { selectedUser, setSelectedUser } = useChatContext();

  const handleClick = (user) => {
    setSelectedUser(user);
    localStorage.setItem("lastTopicSelected", JSON.stringify(user.topic));
  };

  const parseMessage = (message) => {
    // Replace markdown-like syntax with HTML tags
    const formattedMessage = message ?? ""
        .replace(/_([^_]+)_/g, '<i>$1</i>') // Italic: _text_
        .replace(/\*([^\*]+)\*/g, '<b>$1</b>') // Bold: *text*
        .replace(/~([^~]+)~/g, '<s>$1</s>'); // Strikethrough: ~text~

    return formattedMessage;
  };

  const adminSubscriberCode = localStorage.getItem("code");

  useEffect(() => {
    // Initialize with sample dummy chats
    // setChats([
    //   {
    //     id: 1,
    //     topic: "topic_1",
    //     sender: "919876543210",  // here sender == sender 
    //     name: "John Doe",
    //     message: "Hey, I need some help with my order.",
    //     supportUser: null,
    //   },
    //   {
    //     id: 2,
    //     topic: "topic_2",
    //     sender: "919812345678",
    //     name: "Jane Smith",
    //     message: "Can you provide an update on my request?",
    //     supportUser: "support_agent_1",
    //   },
    //   {
    //     id: 3,
    //     topic: "topic_3",
    //     sender: "919711122233",
    //     name: "Michael Johnson",
    //     message: "Is there a way to track my delivery?",
    //     supportUser: null,
    //   },
    // ]);

    const socketURL = process.env.NODE_ENV === 'production' ? `wss://dev.chatbot.bizcollab360.com/ws/chat/list/admin/${adminSubscriberCode}/` : `ws://localhost:8000/ws/chat/list/admin/${adminSubscriberCode}/`;
    
    const socket = new WebSocket(socketURL);
  
    socket.onmessage = (e) => {
      const data = JSON.parse(e.data);
      const { topic, publisher, last_msg, support_subscriber, notification_type } = data;
      const { message } = last_msg || {};
      const { phone, name } = publisher || {};  // {phone: "919619187415", code: "KEfTvHFjMFrbK9f8VktDZ2jRA5Ts85_psRjqRZqthz4", name: "Rakhi Patil"}
      
      setChats((prevChats) => {
        let updatedChats = [...prevChats];
        let selectedUserChanged = false;
  
        if (notification_type === "topics.remove") {
          updatedChats = updatedChats.filter(chat => {
            if (chat.topic === topic && selectedUser?.topic === topic) {
              selectedUserChanged = true;
            }
            return chat.topic !== topic;
          });
        } else if (notification_type === "topics.update" || notification_type === undefined) {
          const existingChatIndex = updatedChats.findIndex(chat => chat.topic === topic);
          if (existingChatIndex !== -1) {
            updatedChats[existingChatIndex] = {
              ...updatedChats[existingChatIndex],
              message,
              topic,
              supportUser: support_subscriber,
              name,
            };
          } else {
            updatedChats.push({
              id: prevChats.length + 1,
              topic,
              phone,
              name,
              message,
              supportUser: support_subscriber,
            });
          }
        } else if (notification_type === "topics.update.support_subscriber") {
          const existingChatIndex = updatedChats.findIndex(chat => chat.topic === topic);
          if (existingChatIndex !== -1) {
            updatedChats[existingChatIndex] = {
              ...updatedChats[existingChatIndex],
              supportUser: support_subscriber,
            };
            if (selectedUser && selectedUser.topic === topic) {
              setSelectedUser({
                ...selectedUser,
                supportUser: support_subscriber,
              });
            }
          }
        }
  
        if (selectedUserChanged || !selectedUser) {
          const nextUser = updatedChats[0] || null;
          setSelectedUser(nextUser);
          if (nextUser) {
            localStorage.setItem("lastTopicSelected", JSON.stringify(nextUser.topic));
          } else {
            localStorage.removeItem("lastTopicSelected");
          }
        }
  
        return updatedChats;
      });
    };
  
    return () => socket.close();
  }, [selectedUser, setSelectedUser]);
  

  return (
    <Box
        sx={{
          maxHeight: '550px',
          overflowY: 'auto',
          scrollbarWidth: 'thin', // For Firefox
          scrollbarColor: '#555 transparent', // For Firefox
          '&::-webkit-scrollbar': {
            width: '8px', // Wider scrollbar
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent', // Invisible track
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(255, 255, 255, 0.3)', // Semi-transparent white
            borderRadius: '10px',
            transition: 'background 0.3s ease-in-out',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: 'rgba(255, 255, 255, 0.6)', // Darker on hover
          },
        }}
      >
    <List>
      {chats.length > 0 ? (
        chats.map((chat, index) => (
          <React.Fragment key={chat.id}>
            <ListItem button onClick={() => handleClick(chat)}>
              <ListItemAvatar>
                <Avatar><PersonIcon /></Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${chat.name}`}
                secondary={
                  <Typography component="span" variant="body2" style={{ color: '#d9d9d9' }}>
                     +{chat.sender}
                   </Typography>
                }
                // primary={chat.name || `+${chat.phone}`}
                // secondary={
                //   <Typography component="span" variant="body2" style={{ color: '#d9d9d9' }}>
                //     {parseMessage(chat.message?.length > 50 ? `${chat.message.substring(0, 50)}...` : chat.message)}
                //   </Typography>
                // }
              />
              {chat.supportUser === null && (
                <Chip label="new" color="error" size="small" />
              )}
            </ListItem>
            {index < chats.length - 1 && <Divider component="li" variant="middle" />}
          </React.Fragment>
        ))
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" py={5} color="textSecondary">
          <ChatBubbleOutlineIcon fontSize="large" />
          <Typography variant="h6" mt={2}>
            No Chats Available
          </Typography>
          <Typography variant="body2">
            Start a new conversation to see your chats here.
          </Typography>
        </Box>
      )}
    </List>
    </Box>
  );
};

export default ChatList;
