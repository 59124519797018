import * as React from "react";
 
// importing material UI components
import { useState } from 'react';
import ProductsTable from './ProductsTable';
import Container from '@mui/material/Container';

const Products = (props) => {

  return (
    <div className="App">
      <Container maxWidth={false}>
        <>
          <h2 className="heading-title">Products</h2>
          <ProductsTable 
            refreshkey={props.refreshKey}
          />
        </>
      </Container>

    </div>
  );
}
export default Products;
