import * as React from "react";
 
// importing material UI components
import { useState } from 'react';
import WhatsAppMessageTable from './WhatsAppMessageTable';
import Container from '@mui/material/Container';

const WhatsAppMessage = (props) => { 
  return (
    <div className="App">
      {/* <Header WhatsAppMessageState={props.WhatsAppMessageState}/> */}
      <Container maxWidth={false}>
      {/* <p>State: {props.WhatsAppMessageState} Time remaining: {props.remaining}</p> */}
        <h2 className="heading-title">Chat Logs</h2>
        <WhatsAppMessageTable key={props.refreshKey} />
      </Container>
    </div>
  );
}
export default WhatsAppMessage;
