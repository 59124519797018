import React from 'react';
import bizcollab360imgUrl from '../assets/images/logo2.png';
import botbridgeimgUrl from '../assets/images/botbridge_logo.png';
import carepmsimgUrl from '../assets/images/Care_New_Logo.png';

const logoStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
  marginBottom: '10px'
};

// Base image style
const baseImgStyle = {
  maxHeight: '45px',
  objectFit: 'contain',
};

// Specific styles for each logo based on their natural dimensions
const logoStyles = {
  bizcollab360: {
    ...baseImgStyle,
    width: 'auto', // Square logo can have fixed width
  },
  carepms: {
    ...baseImgStyle,
    width: 'auto', // Allow width to adjust proportionally for rectangular logo
  }
};

export default function Logo() {
  const tenant = localStorage.getItem('tenant');
  
  // Determine which logo to display based on tenant value
  const logoUrl = tenant === 'carepms' ? carepmsimgUrl : botbridgeimgUrl;
  const companyName = tenant === 'carepms' ? '' : '';   //TODO: integrate backend and make dynamic
  const currentImgStyle = tenant === 'carepms' ? logoStyles.carepms : logoStyles.bizcollab360;
  
  return (
    <div style={logoStyle}>
      <img src={logoUrl} style={currentImgStyle} alt="Company Logo" />
      <div>
        <h4>{companyName}</h4>
      </div>
    </div>
  );
}