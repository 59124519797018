import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  Link,
  styled,
  Alert,
  Checkbox,
  InputAdornment,
  CardHeader,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DescriptionIcon from '@mui/icons-material/Description';
import { Search } from '@mui/icons-material';
import { format } from 'date-fns';

import axios from 'axios';

import FeedbackResults from './FeedbackResults';

const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:8000/api`;

// Styled components
const DashboardCard = styled(Card)(({ theme }) => ({
  background: '#ffffff',
  borderRadius: '12px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)',
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
  },
  marginBottom: theme.spacing(3),
}));

const InfoCard = styled(Card)(({ theme }) => ({
  height: '100%',
  background: 'linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%)',
  borderRadius: '8px',
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: 600,
  color: '#2c3e50',
  marginBottom: theme.spacing(3),
}));

const SubCard = styled(Card)(({ theme }) => ({
  background: '#f8f9fa',
  borderRadius: '8px',
  marginBottom: theme.spacing(2),
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: '8px',
  '& .MuiTableCell-head': {
    backgroundColor: '#f8f9fa',
    fontWeight: 600,
  },
}));

const ClientDetailsTab = ({ clientCode: initialClientCode }) => {

  const [historySearched, setHistorySearched] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [paginationState, setPaginationState] = useState({
    page: 0,
    rowsPerPage: 10,
    totalCount: 0
  });

  const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:8000/api`;

  const [showForm, setShowForm] = useState(false);
  const [meetingDate, setMeetingDate] = useState(null);
  const [meetingDetails, setMeetingDetails] = useState({
    communicationMode: '',
    description: '',
  });

  const [open, setOpen] = useState(false);
  
  const handleClose = () => {
    setOpen(false);
  };

  const [searchValue, setSearchValue] = useState(initialClientCode || "");
  const [charCount, setCharCount] = useState(initialClientCode ? initialClientCode.length : 0);

  // Add search handling function
  const updateSearchValue = (value) => {
    setSearchValue(value);
    setCharCount(value.length);

    // Reset historySearched when search value is cleared
    if (!value) {
      setHistorySearched(false);
      setMeetings([]);
    }
    
    // Only fetch if there's a valid client code
    if (value) {
      fetchClientDetails(value);
      fetchMeetings(value);
    }
  };

  // New state for meetings and feedbacks
  const [meetings, setMeetings] = useState([]);

  // Add state for selected meeting feedback
  const [selectedMeetingFeedback, setSelectedMeetingFeedback] = useState(null);
  const tenant = localStorage.getItem('tenant');
  // Modify the feedback click handler
  const handleFeedbackClick = async (meetingId) => {
    
    try {
      const response = await axios.get(`${URL}/client-meetings/feedback-retrieve/${meetingId}/`, {
        headers: {
          'X-Tenant-Id': tenant
        }
      });
      

      // Filter feedbacks for the selected meeting
      const meetingFeedbacks = await response.data;
      
      // Transform the feedback data into the expected format
      const transformedFeedback = {
        serviceFeedback: {},
        portfolioPerformance: {}
      };

      meetingFeedbacks.forEach(feedback => {
        // Based on the question, determine if it's service or portfolio feedback
        // You might want to add more sophisticated logic here based on your requirements
        
        if (feedback.question_type.toLowerCase().includes('service')) {
          transformedFeedback.serviceFeedback[feedback.question] = feedback.answer;
        } else if (feedback.question_type.toLowerCase().includes('portfolio')) {
          transformedFeedback.portfolioPerformance[feedback.question] = feedback.answer;
        }
      });

      setSelectedMeetingFeedback(transformedFeedback);
      setOpen(true);
    } catch (error) {
      console.error('Error fetching meetings:', error);
      handleErrorAlert(error.response?.status || 500, 'Error fetching meetings');
    }
  };

  // state for API data
  const [questions, setQuestions] = useState({
    service: [],
    portfolio: []
  });

  const [clientData, setClientData] = useState(null);

  const [serviceFeedback, setServiceFeedback] = useState({});
  const [portfolioPerformance, setPortfolioPerformance] = useState({});

  const [alertObj, setAlertObj] = useState({
    active: false,
    msg: "",
    type: "",
    statusCode: ""
  });

  // Add new state for task creation
  const [showReminderCreator, setShowReminderCreator] = useState(false);
  const [reminderDetails, setReminderDetails] = useState({
    subject: `Follow-up meeting with ${clientData?.client_name}, Client code ${clientData?.client_code}`,
    description: '',
    startDate: null,
    endDate: null
  });

  // Alert handling functions
  const handleSuccessAlert = (message) => {
    setAlertObj({
      active: true,
      type: "success",
      msg: message,
      statusCode: 201
    });
  };
  
  const handleErrorAlert = (statusCode, message) => {
    setAlertObj({
      active: true,
      type: "error",
      msg: message,
      statusCode: statusCode
    });
  };

  const handleAlertClose = () => {
    setAlertObj({
      active: false,
      type: "",
      msg: "",
      statusCode: ""
    });
  };

  // Function to fetch client details
  const fetchClientDetails = async (searchClientCode) => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const headers = {
        "Content-Type": "application/json",
        "X-Tenant-Id": tenant
      };
      const response = await fetch(`${URL}/client-meetings/clients-details/${searchClientCode}/?user_id=${user.id}`, {
        headers: {
          "Content-Type": "application/json",
          "X-Tenant-Id": tenant
        }
      });
      const data = await response.json();

      setClientData(data);
    } catch (error) {
      console.error('Error fetching client details:', error);
      setClientData(null);
    }
  };

  // Function to fetch questions
  const fetchQuestions = async () => {
    try {
      const tenant = localStorage.getItem('tenant');
      const headers = {
        "Content-Type": "application/json",
        "X-Tenant-Id": tenant
      };

      const response = await fetch(`${URL}/client-meetings/questions/`, {headers});
      const data = await response.json();
      
      // Separate questions by type
      const serviceQuestions = data.results.filter(q => q.question_type === 'service');
      const portfolioQuestions = data.results.filter(q => q.question_type === 'portfolio');
      
      setQuestions({
        service: serviceQuestions,
        portfolio: portfolioQuestions
      });
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  };

  // Function to fetch meetings
  const fetchMeetings = async (searchClientCode) => {
    try {
      setHistorySearched(true); // Add this line to indicate a search was attempted
      const tenant = localStorage.getItem('tenant');
      const user = JSON.parse(localStorage.getItem("user"));
      const headers = {
        "Content-Type": "application/json",
        "X-Tenant-Id": tenant
      };
      const response = await fetch(
        `${URL}/client-meetings/retrieve-meetings/${searchClientCode}/?user_id=${user.id}&limit=${paginationState.rowsPerPage}&offset=${paginationState.page * paginationState.rowsPerPage}&page=${paginationState.page + 1}`, {headers}
      );
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const jsonData = await response.json();
      setMeetings(jsonData.results);
      setPaginationState(prev => ({
        ...prev,
        totalCount: jsonData.count
      }));
    } catch (error) {
      console.error('Error fetching meetings:', error);
      setMeetings([]); // Ensure meetings is empty on error
      // handleErrorAlert(error.response?.status || 500, 'Error fetching meetings');
    }
  };

  // Fetch client details on initial render
  useEffect(() => {
    if (initialClientCode) {
      setSearchValue(initialClientCode);
      setCharCount(initialClientCode.length);
      setHistorySearched(false); // Reset history search status
      fetchClientDetails(initialClientCode);
      fetchMeetings(initialClientCode);
    }
  }, [initialClientCode]);

  // Modify data fetching useEffect to use searchValue
  useEffect(() => {
    if (searchValue) {
      fetchQuestions();
      fetchMeetings(searchValue);
    }
  }, [
    searchValue,
    paginationState.page, 
    paginationState.rowsPerPage, 
  ]);

  // Modify data fetching useEffect to use searchValue
  useEffect(() => {
    if (searchValue) {
      setReminderDetails(prev => ({
        ...prev,
        subject: `Follow-up meeting with ${clientData?.client_name}, Client code ${clientData?.client_code}`
      }))
    }
  }, [
    showReminderCreator, 
  ]);

  const handlePageChange = (event, newPage) => {
    setPaginationState(prev => ({
      ...prev,
      page: newPage
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setPaginationState(prev => ({
      ...prev,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    }));
  };

  // Modified JSX for rendering questions dynamically
  const renderQuestions = (questionType) => {
    const questionList = questions[questionType];
    return questionList.map((question) => {
      // Only render questions that don't have a parent (top-level questions)
      if (!question.parent_question) {
        return (
          <Box key={question.id}>
            <FormControl 
              required={question.is_mandatory} 
              fullWidth 
              margin="normal"
            >
              <Typography variant="body2">{question.question_text}</Typography>
              <RadioGroup
                row
                value={questionType === 'service' ? 
                  (serviceFeedback[question.id] || '') : 
                  (portfolioPerformance[question.id] || '')
                }
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (questionType === 'service') {
                    setServiceFeedback(prev => ({
                      ...prev,
                      [question.id]: newValue
                    }));
                  } else {
                    setPortfolioPerformance(prev => ({
                      ...prev,
                      [question.id]: newValue,
                      // Set sub-questions to '0' when parent is answered 'no'
                      ...(question.has_sub_question && newValue === 'no' 
                        ? questionList
                            .filter(subQ => subQ.parent_question === question.id)
                            .reduce((acc, subQ) => ({ ...acc, [subQ.id]: '0' }), {})
                        : {})
                    }));
                  }
                }}
              >
                {question.answer_type === 'satisfaction' ? (
                  <>
                    <FormControlLabel value="satisfied" control={<Radio />} label="Satisfied" />
                    <FormControlLabel value="not_satisfied" control={<Radio />} label="Not Satisfied" />
                    <FormControlLabel value="no_comments" control={<Radio />} label="No comments" />
                  </>
                ) : question.answer_type === 'happiness' ? (
                  <>
                    <FormControlLabel value="happy" control={<Radio />} label="Happy" />
                    <FormControlLabel value="unhappy" control={<Radio />} label="Unhappy" />
                  </>
                ) : question.answer_type === 'yes_no_no_comments' ? (
                  <>
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                    <FormControlLabel value="no_comments" control={<Radio />} label="No comments" />
                  </>
                ) : (
                  <>
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                  </>
                )}
              </RadioGroup>
            </FormControl>

            {/* Render sub-questions if parent question has value 'yes' */}
            {question.has_sub_question && 
             (questionType === 'service' ? serviceFeedback[question.id] : portfolioPerformance[question.id]) === 'yes' && (
              <Box sx={{ ml: 4, mt: 2 }}>
                {questionList
                  .filter(subQ => subQ.parent_question === question.id)
                  .map(subQuestion => (
                    <FormControl 
                      key={subQuestion.id}
                      required={subQuestion.is_mandatory} 
                      fullWidth 
                      margin="normal"
                    >
                      <Typography variant="body2">{subQuestion.question_text}</Typography>
                      {subQuestion.answer_type === 'text' ? (
                        <TextField
                          select
                          value={questionType === 'service' ? 
                            (serviceFeedback[subQuestion.id] || '0') : 
                            (portfolioPerformance[subQuestion.id] || '0')
                          }
                          onChange={(e) => {
                            if (questionType === 'service') {
                              setServiceFeedback(prev => ({
                                ...prev,
                                [subQuestion.id]: e.target.value
                              }));
                            } else {
                              setPortfolioPerformance(prev => ({
                                ...prev,
                                [subQuestion.id]: e.target.value
                              }));
                            }
                          }}
                        >
                          {[...Array(11)].map((_, index) => (
                            <MenuItem key={index} value={index.toString()}>
                              {index}
                            </MenuItem>
                          ))}
                        </TextField>
                      ) : null}
                    </FormControl>
                  ))}
              </Box>
            )}
          </Box>
        );
      }
      return null;
    });
  };

  // Sample client details
  const clientDetails = {
    'Client Code': clientData ? clientData.client_code ? clientData.client_code : 'Not Available' : '',
    'Client Name': clientData ? clientData.client_name ? clientData.client_name : 'Not Available' : '',
    'Total Portfolio Value': clientData ? clientData.port_val ? clientData.port_val : 'Not Available' : '',
    'Phone Number': clientData ? clientData.phone_no ? clientData.phone_no : 'Not Available' : '',
    'Strategy Name': clientData ? clientData.invst_strategy ? clientData.invst_strategy : 'Not Available' : '',
    'One-Month Return': clientData ? clientData.one_month_return ? clientData.one_month_return : 'Not Available' : '',
    'Six-Month Return': clientData ? clientData.six_month_return ? clientData.six_month_return : 'Not Available' : '',
    'One-Year Return': clientData ? clientData.one_year_return ? clientData.one_year_return : 'Not Available' : '',
    'Three-Year Return': clientData ? clientData.three_year_return ? clientData.three_year_return : 'Not Available' : '',
    'Five-Year Return': clientData ? clientData.five_year_return ? clientData.five_year_return : 'Not Available' : '',
    'Returns Since Inception': clientData ? clientData.since_inception_return ? clientData.since_inception_return : 'Not Available' : '',
  };

  const handleDescriptionChange = (e) => {
    if (e.target.value.length <= 500) {
      setMeetingDetails({
        ...meetingDetails,
        description: e.target.value,
      });
    }
  };

  const validateForm = () => {
    if (!meetingDate || !meetingDetails.communicationMode || !meetingDetails.description) {
      return false;
    }

    if (Object.keys(serviceFeedback).length < questions.service.length) {
      return false;
    }

    return Object.values(serviceFeedback).every(value => value !== '');
  };

  // Modified submit handler for API integration
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prevent duplicate submissions
    if (isSubmitting) return;

    const user = JSON.parse(localStorage.getItem("user"));

    if (validateForm()) {
      // Set submission state to true when starting the submission
      setIsSubmitting(true);

      // Prepare feedbacks array
      const feedbacks = [];
      let question = null;
      
      // Add service feedback
      Object.entries(serviceFeedback).forEach(([field, value]) => {
        // convert string field to number
        field = parseInt(field, 10);
        
        question = questions.service.find(q => q.id === field);

        // Add feedback
        if (question) {
          feedbacks.push({
            question: question.id,
            answer: value.toLowerCase(),
            created_by: user.id
          });
        }
      });

      // Add portfolio feedback
      Object.entries(portfolioPerformance).forEach(([field, value]) => {
        field = parseInt(field, 10);
        
        // Skip the numberOfPMS field as it's not a question
        if (field === 'numberOfPMS') return;
        
        question = questions.portfolio.find(q => q.id === field);
        if (question) {
          // Check if this is the PMS exposure question
          const isPMSQuestion = question.question_text === "Does he has other PMS Exposure?";
          
          // Create the feedback object
          const feedbackObj = {
            question: question.id,
            answer: value.toLowerCase(),
            created_by: user.id
          };

          feedbacks.push(feedbackObj);
        }
      });

      // Add meeting details
      const meetingData = {
        date: format(meetingDate, 'yyyy-MM-dd'),    // do not change format
        mode_of_communication: meetingDetails.communicationMode.toLowerCase(),
        description: meetingDetails.description,
        client_code: searchValue,
        created_by: user.id,
        feedbacks: feedbacks
      };

      if (showReminderCreator) {
        meetingData.task = {
          ...reminderDetails,
          startDate: format(reminderDetails.startDate, 'yyyy-MM-dd HH:mm:ss'),
          endDate: format(reminderDetails.endDate, 'yyyy-MM-dd HH:mm:ss')
        }
      }

      console.log("Payload (Meeting Data): ", meetingData);

      const tenant = localStorage.getItem('tenant');
      const headers = {
        "Content-Type": "application/json",
        "X-Tenant-Id": tenant
      };

      try {
        const response = await axios.post(`${URL}/client-meetings/create-meetings/`, meetingData, {headers});
        if (response.status === 201) {
          handleSuccessAlert('Meeting details saved successfully');
          // Reset form
          setMeetingDate(null);
          setMeetingDetails({ communicationMode: '', description: '' });
          setServiceFeedback({});
          setPortfolioPerformance({});
          setShowForm(false);

          if (searchValue) {
            fetchMeetings(searchValue);
          }
        } else {
          handleErrorAlert(response.status, 'Error saving meeting details: ' + response.data.message);
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        handleErrorAlert(
          error.response?.status || 500,
          (error.response?.data?.message || 
          (error.response?.data && error.response?.data.length > 50 ? 'Error saving meeting details' : error.response?.data)) || 
          'Error saving meeting details'
        );
      } finally {
        // Reset submission state when the API call completes (success or failure)
        setIsSubmitting(false);
      }
    } else {
      handleErrorAlert(400, 'Please fill all mandatory fields');
      // Re-enable the submit button
      setIsSubmitting(false);
    }
  };

  // Execute once
  useEffect(() => {
    fetchQuestions();
  }, [])

  // Fetch data on component mount
  useEffect(() => {
    fetchQuestions();
  }, [
    meetingDate,
    meetingDetails,
    serviceFeedback,
    portfolioPerformance
  ]);

  // Add new effect to handle sub-question default values
  useEffect(() => {
    questions.portfolio.forEach(question => {
      if (question.has_sub_question) {
        const subQuestions = questions.portfolio.filter(q => q.parent_question === question.id);
        
        // Set default value '0' for sub-questions when parent answer changes to 'no'
        if (portfolioPerformance[question.id] === 'no') {
          subQuestions.forEach(subQ => {
            setPortfolioPerformance(prev => ({
              ...prev,
              [subQ.id]: '0'
            }));
          });
        }
      }
    });
  }, [questions.portfolio, portfolioPerformance]);

  useEffect(() => {
    // Find the PMS exposure question
    const pmsQuestion = questions.portfolio.find(q => 
      q.question_text === "Does he has other PMS Exposure?"
    );
    
    // If the answer is yes and numberOfPMS isn't set, initialize it
    if (pmsQuestion && portfolioPerformance[pmsQuestion.id] === 'yes' && !portfolioPerformance.numberOfPMS) {
      setPortfolioPerformance(prev => ({
        ...prev,
        numberOfPMS: '1' // Default to 1 PMS
      }));
    }
  }, [portfolioPerformance, questions.portfolio]);
  
  useEffect(() => {
    let timeoutId;
    
    if (alertObj.active) {
      timeoutId = setTimeout(() => {
        handleAlertClose();
      }, 5000);
    }
    
    // Cleanup function to clear the timeout when component unmounts
    // or when alertObj changes
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [alertObj]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth="lg">
        <Typography variant="h4" component="h1" sx={{ mb: 2, color: '#2c3e50', fontWeight: 600 }}>
          Client Details
        </Typography>

        {/* Add search bar at the top left side */}
        <TextField
          sx={{ width: 250, mb: 2 }}
          size="small"
          placeholder="Search"
          title='Search by Client Code'
          value={searchValue}
          onChange={(e) => updateSearchValue(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="caption">{charCount}/50</Typography>
              </InputAdornment>
            )
          }}
        />

        {/* Section 1: Client Information Cards */}
        <DashboardCard>
          <CardContent>
            <CardTitle>Client Information</CardTitle>
            <Grid container spacing={3}>
              {Object.entries(clientDetails).map(([key, value]) => (
                <Grid item xs={12} sm={6} md={4} key={key}>
                  <InfoCard>
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                      {key}
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 'auto', fontWeight: 500 }}>
                      {value}
                    </Typography>
                  </InfoCard>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </DashboardCard>

        {/* Section 2: Client History */}
        <DashboardCard>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <CardTitle sx={{ mb: 0 }}>Client History</CardTitle>
            </Box>
            <StyledTableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Mode</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Feedback Form</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historySearched && meetings.length === 0 ? (
                        <TableRow>
                        <TableCell colSpan={4} align="center">
                          <Typography variant="body1" color="textSecondary">
                            No records found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                    meetings.map((meeting) => (
                      <TableRow key={meeting.id}>
                        <TableCell>{meeting.mode_of_communication}</TableCell>
                        <TableCell>{meeting.date}</TableCell>
                        <TableCell>{meeting.description}</TableCell>
                        <TableCell>
                          <Link 
                            href="#" 
                            onClick={(e) => {
                              e.preventDefault();
                              // setOpen(true);
                              handleFeedbackClick(meeting.id);
                            }}
                            underline="none" 
                            sx={{ display: 'flex', alignItems: 'center', gap: 1, cursor: 'pointer' }}
                          >
                            <DescriptionIcon fontSize="small" />
                            {meeting.feedback_id || 'Loading...'}
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <Grid>
              <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={paginationState.totalCount}
                rowsPerPage={paginationState.rowsPerPage}
                page={paginationState.page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => {
                  if (!searchValue.trim()) {
                    handleErrorAlert(400, 'Enter client code');
                  } else if (!clientData || clientData.detail === "Not found.") {
                    handleErrorAlert(400, 'Invalid client code');
                  } else {
                    setShowForm(true);
                  }
                }}
                sx={{
                  mt: 3,
                  borderRadius: '8px',
                  textTransform: 'none',
                }}
              >
                Add Meeting
              </Button>
            </Grid>
          </CardContent>
        </DashboardCard>

        {/* Section 3: Meeting Minutes (Collapsible) */}
        <Collapse in={showForm}>
          <DashboardCard>
            <CardContent>
              <CardTitle>Record Minutes of Meeting</CardTitle>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <DatePicker
                    label="Date *"
                    value={meetingDate}
                    onChange={(newValue) => setMeetingDate(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth required />}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    select
                    label="Mode of Communication"
                    value={meetingDetails.communicationMode}
                    onChange={(e) => setMeetingDetails({...meetingDetails, communicationMode: e.target.value})}
                  >
                    {['Call', 'WhatsApp', 'Face to Face', 'Email', 'Client Place Visit', 'Client Visit to Care Office', 'Message', 'Others'].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    multiline
                    rows={4}
                    label="Description"
                    value={meetingDetails.description}
                    onChange={handleDescriptionChange}
                    helperText={`${meetingDetails.description.length}/500 characters`}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </DashboardCard>

          {/* Add Task Creator section */}
          <DashboardCard>
            <CardContent>
              <CardTitle>Set Email Reminder</CardTitle>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showReminderCreator}
                        onChange={(e) => setShowReminderCreator(e.target.checked)}
                      />
                    }
                    label="Add Reminder"
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <Collapse in={showReminderCreator}>
                    <Grid container spacing={3} >
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Reminder Subject"
                          value={reminderDetails.subject}
                          onChange={(e) => setReminderDetails({ ...reminderDetails, subject: e.target.value })}
                          required={showReminderCreator}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <DateTimePicker
                              label="Start Date *"
                              value={reminderDetails.startDate}
                              onChange={(newValue) => setReminderDetails({ ...reminderDetails, startDate: newValue })}
                              renderInput={(params) => <TextField {...params} fullWidth required={showReminderCreator} />}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DateTimePicker
                              label="End Date *"
                              value={reminderDetails.endDate}
                              onChange={(newValue) => setReminderDetails({ ...reminderDetails, endDate: newValue })}
                              renderInput={(params) => <TextField {...params} fullWidth required={showReminderCreator} />}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          rows={3}
                          label="Reminder Description"
                          value={reminderDetails.description}
                          onChange={(e) => setReminderDetails({ ...reminderDetails, description: e.target.value })}
                          required={showReminderCreator}
                        />
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
              </Grid>
            </CardContent>
          </DashboardCard>

          {/* Section 4: Feedback Details (Collapsible) */}
          <DashboardCard>
            <CardContent>
                <CardTitle>Feedback Details</CardTitle>
              
                {/* Service Feedback Sub-Card (Mandatory) */}
                <SubCard>
                  <CardContent>
                    <Typography variant="h6" color="primary" gutterBottom>
                      Service Feedback (Mandatory)
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        {renderQuestions('service')}
                      </Grid>
                    </Grid>
                  </CardContent>
                </SubCard>

              {/* Portfolio Performance Sub-Card (Optional) */}
              <SubCard>
                <CardContent>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    Portfolio Performance Feedback (Optional)
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      {renderQuestions('portfolio')}
                    </Grid>
                  </Grid>
                </CardContent>
              </SubCard>
            </CardContent>
          </DashboardCard>

          <Box sx={{ mt: 3, mb: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}   // avoid double click form submission
              size="large"
              sx={{
                borderRadius: '8px',
                padding: '12px 32px',
                textTransform: 'none',
                fontSize: '1rem',
              }}
            >
              {isSubmitting ? 'Saving...' : 'Save Meeting Details'}
            </Button>
          </Box>
        </Collapse>
        {/* Add Alert component at the top of the container */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {alertObj.active && (
              <Alert 
                severity={alertObj.type} 
                onClose={handleAlertClose}
                sx={{ mb: 2 }} // Add margin bottom for spacing
              >
                {alertObj.msg}
              </Alert>
            )}
          </Grid>
        </Grid>
      </Container>
      <FeedbackResults 
        open={open} 
        onClose={handleClose}
        feedback={selectedMeetingFeedback || {
          serviceFeedback: {},
          portfolioPerformance: {}
        }}
      />
    </LocalizationProvider>
  );
};

export default ClientDetailsTab;