import React, { useState } from 'react'
import SearchInputButton from "./SearchInputButton.js";
// import TableComponent from "./TableComponent.js";
import DatePickerAndButtons from "./DatePickerAndButtons.js";
import DailyTableComponent from './DailyTableComponent.js';
import MonthlyTablyComponent from './MonthlyTableComponent.js'
import {
  Box,
  Container,
  Typography,
  CardHeader,
  TextField,
  InputAdornment,
  Button,
  ButtonGroup
} from '@mui/material';
import {
  Search,
} from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function FileUploadData({refreshKey}) {
  const [searchValue, setSearchValue] = useState("");
  const [holdings, setHoldings] = useState("daily");
  const [dateValue, setDateValue] = useState(null);
  
  const [charCount, setCharCount] = useState(0);
  
  const updateSearchValue = (value) => {
    if (value.length > 50) {
      return;
    }
    setSearchValue(value);
    setCharCount(value.length);
  }

  const updateDateValue = (value) => {
    setDateValue(value);
  }

  const updateHoldingType = (value) =>{
    setHoldings(value);
  } 

  return (
    <div>
      <Container maxWidth={false}>
        <div className='search-header'>
          <h2 className="heading-title">{holdings === 'daily' ? 'Daily Holdings' : 'Monthly Holdings'}</h2>
        </div>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 2, width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <TextField
              sx={{ width: 250 }}
              size="small"
              placeholder="Search"
              title="Search Client Code, Name or Phone" // This shows on hover
              value={searchValue}
              onChange={(e) => updateSearchValue(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="caption">{charCount}/50</Typography>
                  </InputAdornment>
                )
              }}
            />
            <p>{holdings === "daily" ? "Latest Holdings Date" : "Latest Value Date"}</p>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <DatePicker
                sx={{ width: 260 }}
                label={holdings === "daily" ? "DD/MM/YYYY" : "MMM/YYYY"}
                defaultValue={null}
                onChange={(newValue) => updateDateValue(newValue)}
                disableFuture
                views={holdings === "daily" ? ['day', 'month', 'year'] : ['month', 'year']}
                slotProps={{
                  field: { clearable: true },
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ marginLeft: 'auto' }}>
            <ButtonGroup variant="outlined" aria-label="Basic button group">
              <Button 
                variant={holdings === 'daily' ? 'contained' : 'outlined'}
                onClick={() => updateHoldingType('daily')}
              >
                Daily
              </Button>
              <Button 
                variant={holdings === 'monthly' ? 'contained' : 'outlined'}
                onClick={() => updateHoldingType('monthly')}
              >
                Monthly
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
        { holdings === "daily" 
          ? <DailyTableComponent searchInput={searchValue} key={refreshKey} dateInput={dateValue} /> 
          : <MonthlyTablyComponent searchInput={searchValue} key={refreshKey} dateInput={dateValue} /> 
        } 
      </Container>
    </div>
  )
}
