// The code set up is used for creating the authentication context in React using the Context API. 
// It creates an AuthContext using createContext() to manage the authentication state.

// The AuthProvider component is designed to wrap the application and provide the authentication context to its child components using the AuthContext.Provider
import { useContext, createContext, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { OTP_VERIFICATION_ENDPOINT, REFRESH_TOKEN_ENDPOINT } from '../config/urls'; // Added refresh token endpoint
import { jwtDecode } from 'jwt-decode'; // Corrected import
import dayjs from 'dayjs'; // Used to handle token expiration time

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem("refresh_token") || ""); // Storing refresh token
  const [permissions, setPermissions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      setUser(decodedToken.user);
      setPermissions(decodedToken.permissions);

      // Check if the token is about to expire and refresh it
      const tokenExpiry = dayjs.unix(decodedToken.exp);
      if (tokenExpiry.diff(dayjs()) < 1) {
        refreshAccessToken();
      }
    }
  }, [token]);

  // Function to refresh access token
  const refreshAccessToken = async () => {
    try {
      const response = await fetch(REFRESH_TOKEN_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refresh: refreshToken }),
      });
      const res = await response.json();

      if (response.status === 200) {
        setToken(res.access);
        localStorage.setItem("token", res.access);
        
        const decodedToken = jwtDecode(res.access);
        setUser(decodedToken.user);
        setPermissions(decodedToken.permissions);
        localStorage.setItem("code", decodedToken.code);
      } else {
        logOut();
      }
    } catch (err) {
      logOut();
    }
  };

  // Get a valid token, refresh it if it's expired
  const getValidToken = async () => {
    const decodedToken = jwtDecode(token);
    const tokenExpiry = dayjs.unix(decodedToken.exp);
    
    if (tokenExpiry.diff(dayjs()) < 1) {
      await refreshAccessToken(); // Refresh token if expired
    }
    return localStorage.getItem("token"); // Return the latest token
  };

  const verifyOtpUrl = OTP_VERIFICATION_ENDPOINT;

  const loginAction = async (data) => {
    try {
      const response = await fetch(verifyOtpUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const res = await response.json();
      console.log("Login Response:", res); // Debugging
  
      if (response.status === 200) {
        setToken(res.access);
        setRefreshToken(res.refresh);
        localStorage.setItem("token", res.access);
        localStorage.setItem("refresh_token", res.refresh);
        localStorage.setItem("role", res.role);
        
        const decodedToken = jwtDecode(res.access);
        console.log("Decoded Token:", decodedToken); // Debugging
  
        setUser(res.user);
        setPermissions(decodedToken.permissions);
        localStorage.setItem("code", decodedToken.code);
        localStorage.setItem("permissions", JSON.stringify(decodedToken.permissions));
        localStorage.setItem("user", JSON.stringify(res.user));
        localStorage.setItem("tenant", res.tenant);
        localStorage.setItem("features", JSON.stringify(res.features));
        localStorage.setItem("apps", JSON.stringify(res.apps));
        localStorage.setItem("MAX_USER_COUNT", res.MAX_USER_COUNT);
        localStorage.setItem("plan", decodedToken.plan);

        if (res.tenant) {
          console.log("Redirecting to tenant:", res.tenant); // Debugging
          // redirectToTenantDomain(res.tenant);
          navigate("/");
        } else {
          navigate("/");
        }
        return { msg: 'Valid OTP', type: 'success' };
      }
      throw new Error(res.msg);
    } catch (err) {
      console.error("Login Error:", err);
      return { msg: 'Invalid OTP', type: 'error' };
    }
  };
  

  const logOut = () => {
    setUser(null);
    setToken("");
    setRefreshToken("");
    setPermissions([]);
    localStorage.clear();
    navigate("/login");
  };

  const redirectToTenantDomain = (subdomain) => {
    const protocol = window.location.protocol;
    const host = window.location.host;
    
    console.log("Current Host:", host);
    console.log("Subdomain:", subdomain);
  
    let newDomain;
    if (host.includes("localhost") || host.includes("127.0.0.1")) {
      newDomain = `${subdomain}.localhost:3000`;
    } else {
      const domain = host.split('.').slice(1).join('.');
      newDomain = `${subdomain}.${domain}`;
    }
  
    console.log("Redirecting to:", `${protocol}//${newDomain}`);
  
    window.location.href = `${protocol}//${newDomain}`;
  };
  

  return (
    <AuthContext.Provider value={{ token, user, permissions, loginAction, logOut, getValidToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
}