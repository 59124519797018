import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import Collapse from '@mui/material/Collapse';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

// Material UI Icons
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from "@mui/icons-material/Lock";
import GroupIcon from '@mui/icons-material/Group';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Inventory2RoundedIcon from '@mui/icons-material/Inventory2Rounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import ForumIcon from '@mui/icons-material/Forum';
import ChatIcon from '@mui/icons-material/Chat';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CampaignIcon from '@mui/icons-material/Campaign';
import GroupsIcon from '@mui/icons-material/Groups';
import BarChartIcon from '@mui/icons-material/BarChart';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ErrorIcon from '@mui/icons-material/Error';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import QrCodeIcon from '@mui/icons-material/QrCode';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Tooltip from '@mui/material/Tooltip';
import CategoryIcon from '@mui/icons-material/Category';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SyncIcon from '@mui/icons-material/Sync';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import FlagIcon from '@mui/icons-material/Flag';
import InsightsIcon from '@mui/icons-material/Insights';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HistoryIcon from '@mui/icons-material/History';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import Home from './Home';
import CustomerHomePage from './Customers/CustomersHome';
import UploadHistory from './UploadHistory';
import BlankTab from './BlankTab';
import CustomersTab from './customers/CustomersTab';
import DriversTab from './drivers/DriversTab';
import OrdersTab from './orders/OrdersTab';
import DriverOrderReportTab from './reports/drivers/DriverOrderReportTab';
import ZoneOrderReportTab from './reports/zones/ZoneOrderReportTab';
import CustomerOrderReportTab from './reports/customers/CustomerOrderReportTab';
import MonthlyPerformanceReportTab from './reports/graphs/MonthlyPerformanceReportTab';
import TimeSlotWiseReportTab from './reports/graphs/TimeSlotWiseReportTab';

import UsersTab from './users/UsersTab';
import JobsTab from './jobs/JobsTab';
import ZonesTab from './zones/ZonesTab';
import ZoneAssignmentsTab from './zones/ZonesAssignmentTab';
import ChatDashboard from './ChatDashboard';
import ChatHistoryDashboard from './ChatHistoryDashboard';
import Whatsapp from './Whatsapp/Whatsapp';
import Header from './Header';
import Footer from './Footer';
import { useAuth } from '../hooks/AuthProvider';
import WhatsAppMessage from './WhatsAppMessage';
import MarketingLogsTable from './MarketingLogs/MarketingLogs';
import ErrorLogsTable from './ErrorLogs';
import Broadcast from './BroadcastTemplates/Broadcast';
import Audience from './Audience/Audience';
import Campaigns from './Campaigns/Campaigns';
import AppUsage from './AppUsage/AppUsage';
import MetaCard from './MetaCard/MetaCard';
import GenerateQR from './GenerateQR/GenerateQR';
import SettingsDashboard from './SettingsDashboard/SettingsDashboard';
import NoAccessPage from './NoAccessPage';
import Products from './Products/Products';
import OrderItem from './OrderItem/OrderItem';
import ClientDetails from './ClientDetails/ClientDetails';
import RMDashboard from './RMDashboard/RMDashboard';
import RMMetricsDashboard from './SuperRMDashboard/RMMetricsDashboard';
import SyncKRClientLog from './SyncKRClientLogs';
import Dashboard from './Dashboard';
import CustomerMsgDashboard from './CustomerMsgDashboard';
import UserEngagementOverview from './UserEngagementOverview/UserEngagementOverview';
import BroadcastCampaigns from './CampaignManagement/BroadcastCampaigns';
import CampaignManagement from './CampaignManagement/CampaignManagement';

import FileUploadLogs from './FileUploadLogs/FileUploadLogs'
import TemplateAnalytics from './TemplateAnalytics/TemplateAnalytics';

import { useTheme } from '@mui/material/styles';

const expandedDrawerWidth = 270;
const collapsedDrawerWidth = 80;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const tenant = localStorage.getItem('tenant');

const sidebarData = {
  "1": { title: "Customers", component: (props) => <CustomersTab {...props} />, icon: <GroupIcon />, app: "custom", tenant: ["oasis"], roles: ["admin", "manager"], permission: null, feature: null },
  "2": { title: "Drivers", component: (props) => <DriversTab {...props} />, icon: <LocalShippingIcon />, app: "custom", tenant: ["oasis"], roles: ["admin", "manager"], permission: null, feature: null },
  "3": { title: "Order Requests", component: (props) => <OrdersTab {...props} />, icon: <Inventory2RoundedIcon />, app: "custom", tenant: ["oasis"], roles: ["admin", "manager"], permission: null, feature: null },
  "4": { title: "Zones", component: (props) => <ZonesTab {...props} />, icon: <AddLocationIcon />, app: "custom", tenant: ["oasis"], roles: ["admin", "manager"], permission: null, feature: null },
  "5": { title: "Zone Assignment", component: (props) => <ZoneAssignmentsTab {...props} />, icon: <EditLocationAltIcon />, app: "custom", tenant: ["oasis"], roles: ["admin", "manager"], permission: null, feature: null },
  "6": { title: "Order Requests Per Drivers", component: (props) => <DriverOrderReportTab {...props} />, icon: <PersonIcon />, app: "reports", tenant: ["oasis"], roles: ["admin", "manager"], permission: null, feature: null },
  "7": { title: "Order Requests Per Zone", component: (props) => <ZoneOrderReportTab {...props} />, icon: <LocationOnRoundedIcon />, app: "reports", tenant: ["oasis"], roles: ["admin", "manager"], permission: null, feature: null },
  "8": { title: "Order Requests Per Customers", component: (props) => <CustomerOrderReportTab {...props} />, icon: <GroupIcon />, app: "reports", tenant: ["oasis"], roles: ["admin", "manager"], permission: null, feature: null },
  "9": { title: "Order Requests Per Month", component: (props) => <MonthlyPerformanceReportTab {...props} />, icon: <CalendarMonthIcon />, app: "reports", tenant: ["oasis"], roles: ["admin", "manager"], permission: null, feature: null },
  "10": { title: "Order Requests Per Timeslot", component: (props) => <TimeSlotWiseReportTab {...props} />, icon: <AccessTimeIcon />, app: "reports", tenant: ["oasis"], roles: ["admin", "manager"], permission: null, feature: null },
  "11": { title: "Profile", component: (props) => <SettingsDashboard {...props} />, icon: <DashboardIcon />, app: "settings", tenant: [], roles: ["admin", "manager", "user"], permission: "dashboard.view", feature: null },
  "12": { title: "Cron", component: (props) => <JobsTab {...props} />, icon: <TaskAltIcon />, app: "custom", tenant: ["oasis"], roles: ["admin", "manager"], permission: null, feature: null },
  "13": { title: "Live Agent", component: (props) => <ChatDashboard {...props} />, icon: <ChatIcon />, app: "live_agent", tenant: [], roles: ["admin", "manager", "user"], permission: "live_agent_basic.view", feature: "live_agent_basic" },
  "14": { title: "WhatsApp Templates", component: (props) => <Whatsapp {...props} />, icon: <WhatsAppIcon />, app: "whatsapp_marketing", tenant: [], roles: ["admin", "manager", "user"], permission: "whatsapp_template.view", feature: "whatsapp_template" },
  "15": { title: "WhatsApp Messages", component: (props) => <WhatsAppMessage {...props} />, icon: <ForumIcon />, app: "whatsapp_marketing", tenant: [], roles: ["admin", "manager"], permission: "wa_messages_logs.view", feature: "wa_messages_logs" },
  "16": { title: "Broadcast Templates", component: (props) => <Broadcast {...props} />, icon: <FlagIcon />, app: "whatsapp_marketing", tenant: [], roles: ["admin", "manager"], permission: "broadcast_template.view", feature: "broadcast_template" },
  "17": { title: "Audience", component: (props) => <Audience {...props} />, icon: <GroupsIcon />, app: "whatsapp_marketing", tenant: [], roles: ["admin", "manager"], permission: "audience.view", feature: "audience" },
  // "18": { title: "Campaigns", component: (props) => <Campaigns {...props} />, icon: <CampaignIcon />, app: "whatsapp_marketing", tenant: [], roles: ["admin", "manager", "user"], permission: "campaigns.view", feature: "campaigns" },
  "19": { title: "WhatsApp Billing", component: (props) => <AppUsage {...props} />, icon: <BarChartIcon />, app: "billing", tenant: [], roles: ["admin"], permission: "whatsapp_billing.view", feature: "whatsapp_billing" },
  "20": { title: "Chat Logs", component: (props) => <WhatsAppMessage {...props} />, icon: <SmartToyIcon />, app: "logs", tenant: [], roles: ["admin", "manager"], permission: "wa_messages_logs.view", feature: "wa_messages_logs" },
  "21": { title: "Broadcast Logs", component: (props) => <MarketingLogsTable {...props} />, icon: <ForumIcon />, app: "logs", tenant: [], roles: ["admin", "manager"], permission: "template_logs.view", feature: "template_logs" },
  // "22": { title: "Error Logs", component: (props) => <ErrorLogsTable {...props} />, icon: <ErrorIcon />, app: "logs", tenant: [], roles: ["admin", "manager"], permission: null, feature: null },
  "23": { title: "Meta Rate Card", component: (props) => <MetaCard {...props} />, icon: tenant === "carepms" ? <CurrencyRupeeIcon /> : <AttachMoneyIcon />, app: "billing", tenant: [], roles: ["admin"], permission: "meta_rate_card.view", feature: "meta_rate_card" },
  "24": { title: "Users", component: (props) => <UsersTab {...props} />, icon: <PersonIcon />, app: "settings", tenant: [], roles: ["admin"], permission: "users.view", feature: "users" },
  "25": { title: "Tools", component: (props) => <GenerateQR {...props} />, icon: <QrCodeIcon />, app: "settings", tenant: [], roles: ["admin", "manager"], permission: "tools.view", feature: "tools" },
  "26": { title: "Products", component: (props) => <Products {...props} />, icon: <CategoryIcon />, app: "commerce", tenant: [], roles: ["admin", "manager"], permission: "commerce_product.view", feature: "commerce_product" },
  "27": { title: "Order Item", component: (props) => <OrderItem {...props} />, icon: <ListAltIcon />, app: "commerce", tenant: [], roles: ["admin", "manager"], permission: "commerce_order.view", feature: "commerce_order" },
  "28": { title: "My Insights", component: (props) => <RMDashboard {...props} />, icon: <AssignmentIndIcon />, app: "custom", tenant: ["carepms"], roles: ["admin", "manager"], permission: "my_dashboard.view", feature: null },
  "29": { title: "Client Directory", component: (props) => <CustomerHomePage {...props} />, icon: <PeopleAltIcon />, app: "custom", tenant: ["carepms"], roles: ["admin", "manager"], permission: "client_details.view", feature: null },
  "30": { title: "Portfolio Holdings", component: (props) => <Home {...props} />, icon: <AccountBalanceIcon />, app: "custom", tenant: ["carepms"], roles: ["admin", "manager"], permission: "holdings.view", feature: null },
  "31": { title: "Client Interactions", component: (props) => <ClientDetails {...props} />, icon: <ListAltIcon />, app: "custom", tenant: ["carepms"], roles: ["admin", "manager"], permission: "client_details.view", feature: null },
  "32": { title: "RM Performance Tracker", component: (props) => <RMMetricsDashboard {...props} />, icon: <AssessmentIcon />, app: "custom", tenant: ["carepms"], roles: ["admin"], permission: "rm_metrics.view", feature: null },
  "33": { title: "File Upload", component: (props) =>  null, icon: <FileUploadIcon />, app: "custom", tenant: ["carepms"], roles: ["admin"], permission: "upload.add", feature: null },
  "34": { title: "Upload Tracker", component: (props) => <UploadHistory {...props} />, icon: <HistoryIcon />, app: "custom", tenant: ["carepms"], roles: ["admin"], permission: "upload.view", feature: null },
  // "35": { title: "Dashboard", component: (props) => <Dashboard {...props} />, icon: <LeaderboardIcon />, app: "analytics", tenant: [], roles: ["admin", "manager"], permission: "dashboard.view", feature: "dashboard" },
  // "36": { title: "Message By Customer", component: (props) => <CustomerMsgDashboard {...props} />, icon: <QueryStatsIcon />, app: "analytics", tenant: [], roles: ["admin", "manager"], permission: null, feature: null },
  // "37": { title: "Sync Report Data", component: (props) => <SyncKRClientLog {...props} />, icon: <SyncIcon />, app: "analytics", tenant: [], roles: ["admin", "manager"], permission: null, feature: null },
  "38": { title: "Chat History", component: (props) => <ChatHistoryDashboard {...props} />, icon: <SyncIcon />, app: "live_agent", tenant: [], roles: ["admin", "manager"], permission: "live_agent_basic.view", feature: "gen_ai_live_agent" },
  "39": { title: "File Upload Logs", component: (props) => <FileUploadLogs {...props} />, icon: <HistoryIcon />, app: "logs", tenant: [], roles: ["admin"], permission: "audience.view", feature: null },
  "40": { title: "User Engagement Overview", component: (props) => <UserEngagementOverview {...props} />, icon: <QueryStatsIcon />, app: "analytics", tenant: ["carepms"], roles: ["admin", "manager"], permission: "user_engagement_overview.view", feature: "user_engagement_overview" },
  "41": { title: "Campaign Management", component: (props) => <CampaignManagement {...props} />, icon: <QueryStatsIcon />, app: "whatsapp_marketing", tenant: [], roles: ["admin", "manager"], permission: "campaigns.view", feature: "campaigns" },
  "42": { title: "Template Analytics", component: (props) => <TemplateAnalytics {...props} />, icon: <InsightsIcon />, app: "analytics", tenant: ["carepms"], roles: ["admin", "manager"], permission: "template_analytics.view", feature: "template_analytics" },
};

const sidebarSections = {
  finance360: { title: "Finance 360", icon: <AccountBalanceIcon />, app: "finance360" },
  whatsappChat: { title: "WhatsApp Chat", icon: <ChatIcon />, app: "live_agent" },
  whatsappMarketing: { title: "WhatsApp Marketing", icon: <CampaignIcon />, app: "whatsapp_marketing" },
  settings: { title: "Settings", icon: <DashboardIcon />, app: "settings" },
  logs: { title: "Logs", icon: <ListAltIcon />, app: "logs" },
  billing: { 
    title: "Billing", 
    icon: tenant === "carepms" ? <CurrencyRupeeIcon /> : <AttachMoneyIcon />, 
    app: "billing" 
  },
  analytics: { title: "Analytics", icon: <AssessmentIcon />, app: "analytics" },
  commerce: { title: "WhatsApp Commerce", icon: <CategoryIcon />, app: "commerce" }
};


                                                                                     
export default function MiniDrawer(props) {
  const permissionsFromStorage = JSON.parse(localStorage.getItem('permissions') || '[]');
  const appsFromStorage = JSON.parse(localStorage.getItem('apps') || '[]');
  const featuresFromStorage = JSON.parse(localStorage.getItem('features') || '[]');
  const [selectedComponentKey, setSelectedComponentKey] = useState(localStorage.getItem("activePage") || "11"); // Keep track of the selected component key
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [uploadStatus, setUploadStatus] = useState(null);
  const [activeButton, setActiveButton] = useState('Daily');
  const [loading, setLoading] = useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerPinned, setDrawerPinned] = useState(false);
  const theme = useTheme();
  const [hoveredSection, setHoveredSection] = useState(null);
  const [activeSection, setActiveSection] = useState(null);
  // Get user permissions from localStorage
  const [userApps, setUserApps] = useState(appsFromStorage);
  const [userFeatures, setUserFeatures] = useState(featuresFromStorage);
  const [userPermissions, setUserPermissions] = useState(permissionsFromStorage);

  // Filter out sections that are not in the apps list
  const updatedSidebarSections = Object.fromEntries(
    Object.entries(sidebarSections).filter(([key, value]) => userApps.includes(value.app))
  );

  let features = ["custom", "reports"]; 
  const planFeatures = JSON.parse(localStorage.getItem('features')) || [];
  features = [...features, ...planFeatures];

  const tenant = localStorage.getItem('tenant');
  
  const masterList = Object.keys(sidebarData).filter(
    key => Array.isArray(sidebarData[key].tenant) && sidebarData[key].tenant.includes(tenant) && sidebarData[key].app !== "reports"
  );
  
  const reportList = Object.keys(sidebarData).filter(
    key => Array.isArray(sidebarData[key].tenant) && sidebarData[key].tenant.includes(tenant) && sidebarData[key].app === "reports"
  );

  const userRole = localStorage.getItem('role');

  const handleButtonClick = (buttonName) => setActiveButton(buttonName);
  const handleClickOpen = (key) => {
    const hasAccess = isAccessible(key);
    if (hasAccess) setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAlertOpen(false);
    setFile(null);
  };
  const handleFileChange = (event) => setFile(event.target.files[0]);

  // Mouse enter/leave handlers for the drawer
  const handleDrawerEnter = () => {
    if (!drawerPinned) {
      setDrawerOpen(true);
    }
  };

  const handleDrawerLeave = () => {
    if (!drawerPinned) {
      setDrawerOpen(false);
    }
  };

  // Handle manual toggle of the drawer
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const toggleDrawerPin = () => {
    setDrawerPinned(!drawerPinned);
    setDrawerOpen(true); // Ensure drawer is open when pinning
  };

  useEffect(() => {
    let timeoutId;
    
    if (alertOpen) {
      timeoutId = setTimeout(() => {
        setAlertOpen(false);
      }, 5000);
    }
    
    // Cleanup function to clear the timeout when component unmounts
    // or when alertObj changes
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [alertOpen]);

  const handleUploadClick = async () => {
    const fileInput = document.getElementById('file-upload');
    const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:8000/api`;
    let fileUploadUrl = `${URL}/history/upload-csv/`;
    const user_data = JSON.parse(localStorage.getItem("user"));
    const tenant = localStorage.getItem("tenant");

    if (fileInput) {
      const inputFile = fileInput.files[0];
      const inputFileType = activeButton.toLowerCase();

      if (inputFileType === "monthly portfolio") {
        fileUploadUrl = `${URL}/investments/documents/upload-chunks/`;
      }

      if (inputFile) {
        const formData = new FormData();
        formData.append('file', inputFile);
        formData.append('file_type', inputFileType);
        formData.append('user_id', user_data.id);

        try {
          setLoading(true);
          const response = await fetch(fileUploadUrl, { method: 'POST', headers: {'X-tenant-Id': tenant} ,body: formData });

          if (response.ok) {
            setUploadStatus('success');
            setAlertOpen(true);
            setAlertMessage("File has been successfully loaded and the customer data will be updated.");
            if (props.onUploadSuccess) props.onUploadSuccess();
            setFile(null);
            fileInput.value = null;
          } else {
            setUploadStatus('error');
            setAlertOpen(true);
            setAlertMessage(response.status === 413 ? "The selected file exceeds the size limit. Please choose a smaller file." : 'Failed to upload file.');
            setFile(null);
            fileInput.value = null;
          }
        } catch (error) {
          console.error('Error occurred during file upload:', error);
          setUploadStatus('error');
          setAlertOpen(true);
          setAlertMessage('Error occurred during file upload.');
          setFile(null);
          fileInput.value = null;
        } finally {
          setLoading(false);
        }
      } else {
        setAlertOpen(true);
        setAlertMessage("Please select a file to upload.");
      }
    } else {
      console.warn('File input element not found');
    }
  };

  
  // Updated function to check if the user has access based on roles, apps, features and permissions
  const isAccessible = (key) => {
    
    const menuItem = sidebarData[key];
    if (!menuItem) return false;
    
    // // Check if app is accessible to the user
    // const appCheck = userApps.includes(menuItem.app);
    
    // // Check if user has the required role
    // const roleCheck = menuItem.roles.includes(userRole);
    
    // // Check if specific feature is enabled (if relevant)
    // // If menuItem.feature is null/undefined, consider it as always available
    // const featureCheck = !menuItem.feature || userFeatures.includes(menuItem.feature);
    
    // Check if user has required permissions (if the item has a permission property)
    // If permission is null, no specific permission is required
    const permissionCheck = !menuItem.permission || userPermissions.includes(menuItem.permission);
    
  //  console.log(appCheck, roleCheck, featureCheck, permissionCheck);
    // return appCheck && roleCheck && featureCheck && permissionCheck;
    return permissionCheck;
  };

  const getAccessRestrictionReason = (key) => {
    const menuItem = sidebarData[key];
    if (!menuItem) return "Unknown item";
    
    if (!userApps.includes(menuItem.app))
      return "App not accessible";
    
    if (!menuItem.roles.includes(userRole))
      return "Role restriction";
    
    if (menuItem.feature && !userFeatures.includes(menuItem.feature))
      return "Feature not enabled";
    
    if (menuItem.permission && !userPermissions.includes(menuItem.permission))
      return `Missing permission: ${menuItem.permission}`;
    
    return "Access restricted";
  };

// Handle sidebar navigation
const handleListItemClick = (key) => {
  // Store the previous key before updating
  const prevKey = selectedComponentKey;
  
  const pageKey = isAccessible(key) ? key : "noAccess";
  localStorage.setItem("activePage", pageKey);
  
  // Only update the state if it's actually changing
  if (prevKey !== pageKey) {
    setSelectedComponentKey(pageKey);    
  }
};

// Create a memoized component wrapper at the top level of the component
const MemoizedComponent = React.useMemo(() => {
  // This map will hold our memoized components
  const componentCache = {};
  
  return (key) => {
    // Return cached component if we already created it
    if (componentCache[key]) return componentCache[key];
    
    if (!isAccessible(key)) {
      componentCache[key] = NoAccessPage;
      return componentCache[key];
    }

    const Component = sidebarData[key]?.component;
    if (!Component) {
      componentCache[key] = NoAccessPage;
      return componentCache[key];
    }

    componentCache[key] = key === "16" 
      ? (props) => <Component {...props} onNavigate={handleListItemClick} />
      : Component;
      
    return componentCache[key];
  };
}, []);

// Get the corresponding component
const getComponentWithProps = (key) => {
  return MemoizedComponent(key);
};

  
  const SelectedComponent = getComponentWithProps(selectedComponentKey);

  // Render a section of the sidebar with collapsible functionality
  const renderSection = (title, keysList, sectionId) => {
    // Check if this section is expanded
    const isExpanded = hoveredSection === sectionId && drawerOpen;
    
    return (
      <>
        <List 
          subheader={
            <ListSubheader 
              component="div" 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: drawerOpen ? 'space-between' : 'center',
                paddingRight: '8px',
                cursor: 'pointer',
                minHeight: '40px',
                bgcolor: 'background.paper',
              }}
              onMouseEnter={() => setHoveredSection(sectionId)}
            >
              {drawerOpen && title}
              {drawerOpen ? (
                isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />
              ) : (
                <MenuIcon fontSize="small" />
              )}
            </ListSubheader>
          }
          onMouseEnter={() => setHoveredSection(sectionId)}
          onMouseLeave={() => setHoveredSection(null)}
        >
          <Collapse in={isExpanded}>
            {keysList.map((key) => {
              const isDisabled = !isAccessible(key);
              const restrictionReason = isDisabled ? getAccessRestrictionReason(key) : "";

              return (
                <ListItem
                  key={key}
                  disablePadding
                  sx={{
                    "& .MuiListItemButton-root": {
                      padding: "5px 20px",
                      opacity: isDisabled ? 0.6 : 1,
                    },
                    "& .MuiListItemIcon-root": {
                      minWidth: 32,
                    },
                  }}
                >
                  <ListItemButton 
                    onClick={() => key === "33" ? handleClickOpen(key) : (isDisabled ? null : handleListItemClick(key))}
                    disabled={isDisabled}
                  >
                    <ListItemIcon>
                      {React.cloneElement(sidebarData[key].icon, { fontSize: "small" })}
                    </ListItemIcon>
                    <ListItemText
                      primary={sidebarData[key].title}
                      primaryTypographyProps={{
                        fontSize: "small",
                        fontWeight: 500,
                      }}
                    />
                    {isDisabled && 
                      <Tooltip title={restrictionReason}>
                        <LockIcon fontSize="small" sx={{ 
                          color: restrictionReason.includes("permission") ? "red" : "gray", 
                          ml: 1 
                        }} />
                      </Tooltip>
                    }
                  </ListItemButton>
                </ListItem>
              );
            })}
          </Collapse>
          
          {/* For collapsed drawer state */}
          {!drawerOpen && keysList.map((key) => {
            const isDisabled = !isAccessible(key);
            const restrictionReason = isDisabled ? getAccessRestrictionReason(key) : "";
            
            return (
              <ListItem
                key={key}
                disablePadding
                sx={{
                  "& .MuiListItemButton-root": {
                    padding: "8px",
                    justifyContent: 'center',
                    opacity: isDisabled ? 0.6 : 1,
                  },
                  "& .MuiListItemIcon-root": {
                    minWidth: 'auto',
                  },
                }}
              >
                <Tooltip title={
                  isDisabled ? 
                    `${sidebarData[key].title} (${restrictionReason})` : 
                    sidebarData[key].title
                } placement="right">
                  <ListItemButton 
                    onClick={() => key === "33" ? handleClickOpen(key) : (isDisabled ? handleClickOpen(key)  : handleListItemClick(key))}
                    disabled={isDisabled}
                  >
                    <ListItemIcon>
                      {React.cloneElement(sidebarData[key].icon, { fontSize: "small" })}
                    </ListItemIcon>
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            );
          })}
        </List>
        <Divider />
      </>
    );

  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <CssBaseline />
      <Header />

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2, position: 'relative' }} id="customized-dialog-title">
          Upload File
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 0,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Collapse in={alertOpen}>
            {uploadStatus === 'success' ? (
              <Alert sx={{ mb: 2 }} severity="success">{alertMessage}</Alert>
            ) : (
              <Alert sx={{ mb: 2 }} severity="error">{alertMessage}</Alert>
            )}
          </Collapse>
          <div className="toggle-container">
            <ButtonGroup variant="outlined" aria-label="Basic button group">
              {['Daily', 'Monthly', 'Clients', 'Monthly Portfolio'].map(buttonName => (
                <Button
                  key={buttonName}
                  variant={activeButton === buttonName ? 'contained' : 'outlined'}
                  onClick={() => handleButtonClick(buttonName)}
                >
                  {buttonName}
                </Button>
              ))}
            </ButtonGroup>
          </div>
          <div className='file-upload'>
            <Typography gutterBottom>
              Please select a file to upload:
            </Typography>
            <input type="file" onChange={handleFileChange} id="file-upload" />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleUploadClick} disabled={loading || !file}>
            {loading ? 'Uploading...' : 'Upload'}
          </Button>
        </DialogActions>
      </BootstrapDialog>

      <Box sx={{ display: 'flex', flexGrow: 1, position: 'relative' }}>
        <Drawer
          variant="permanent"
          open={drawerOpen}
          onMouseEnter={handleDrawerEnter}
          onMouseLeave={handleDrawerLeave}
          sx={{
            width: drawerOpen ? expandedDrawerWidth : collapsedDrawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            [`& .MuiDrawer-paper`]: {
              width: drawerOpen ? expandedDrawerWidth : collapsedDrawerWidth,
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
              overflowX: 'hidden',
              zIndex: 1200,
              marginTop: '75px', // Match the header height
              height: 'calc(100% - 75px)', // Adjust height to account for header
              pt: 0, // No padding at top
            },
          }}
        >
          <Box sx={{ overflow: 'auto' }}>
            {Object.keys(updatedSidebarSections).map((sectionId) => {
              const section = updatedSidebarSections[sectionId];
              const isActive = activeSection === sectionId;
              
              return (
                <ListItem
                  key={sectionId}
                  disablePadding
                  sx={{
                    "& .MuiListItemButton-root": {
                      padding: drawerOpen ? "12px 16px" : "12px 8px",
                      justifyContent: drawerOpen ? 'flex-start' : 'center',
                    },
                    "& .MuiListItemIcon-root": {
                      minWidth: drawerOpen ? 40 : 'auto',
                    },
                    backgroundColor: isActive ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
                    // mb: 1,
                  }}
                >
                  <Tooltip title={!drawerOpen ? section.title : ""} placement="right">
                    <ListItemButton onClick={() => setActiveSection(isActive ? null : sectionId)}>
                      <ListItemIcon 
                        sx={{ 
                          color: isActive ? 'primary.main' : 'inherit',
                          '& .MuiSvgIcon-root': {
                            fontSize: '30px'  // Increased from 30px to 32px
                          }
                        }}
                      >
                        {section.icon}
                      </ListItemIcon>
                      {drawerOpen && (
                        <ListItemText
                          primary={section.title}
                          primaryTypographyProps={{
                            fontSize: "18px",  // Increased from "small" to a specific size
                            fontWeight: 500,
                          }}
                        />
                      )}
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
              );
            })}
          </Box>
        </Drawer>

        {/* Sub-options Drawer */}
<Drawer
  variant="persistent"
  anchor="left"
  open={activeSection !== null}
  sx={{
    width: expandedDrawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: expandedDrawerWidth,
      position: 'absolute',
      left: collapsedDrawerWidth,
      height: 'calc(100% - 75px)',
      marginTop: '75px',
      zIndex: 1100,
      pt: 0,
    },
  }}
>
  <Divider />
  <List>
  {activeSection && (
    // Get the appropriate menu items based on the active section
    Object.keys(sidebarData)
      .filter(key => {
        // Filter based on the active section (maps to app property)
        if (activeSection === "finance360") {
          return Array.isArray(sidebarData[key].tenant) && 
                 sidebarData[key].tenant.includes(tenant) && 
                 sidebarData[key].app === "custom";
        } else if (activeSection === "whatsappChat") {
          return sidebarData[key].app === "live_agent";
        }else if (activeSection === "whatsappMarketing") {
          return sidebarData[key].app === "whatsapp_marketing";
        }  
        else {
          // Match the exact app name from the section
          return sidebarData[key].app === activeSection;
        }
      })
      .map((key) => {
        const isDisabled = !isAccessible(key);
        const restrictionReason = isDisabled ? getAccessRestrictionReason(key) : "";
        
        return (
          <ListItem
            key={key}
            disablePadding
            sx={{
              "& .MuiListItemButton-root": {
                padding: "10px 20px",
                opacity: isDisabled ? 0.6 : 1,
              },
              "& .MuiListItemIcon-root": {
                minWidth: 40,
              },
            }}
          >
            <ListItemButton 
              onClick={() => key === "33" ? handleClickOpen(key) : (isDisabled ? handleClickOpen(key) : handleListItemClick(key))}
              // disabled={isDisabled}
            >
              <ListItemIcon sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: '30px'
                }
              }}>
                {React.cloneElement(sidebarData[key].icon, { fontSize: "inherit" })}
              </ListItemIcon>
              <ListItemText
                primary={sidebarData[key].title}
                primaryTypographyProps={{
                  fontSize: "18px",
                  fontWeight: 500,
                }}
              />
              {isDisabled && 
                <Tooltip title={restrictionReason}>
                  <LockIcon sx={{ 
                    fontSize: '24px', 
                    color: restrictionReason.includes("permission") ? "red" : "gray", 
                    ml: 1 
                  }} />
                </Tooltip>
              }
            </ListItemButton>
          </ListItem>
        );
      })
  )}
  </List>
</Drawer>

        {/* Main Content */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            left: activeSection 
              ? `${collapsedDrawerWidth + expandedDrawerWidth}px`
              : `${collapsedDrawerWidth}px`,
            right: 0,
            top: '0px',
            bottom: 0, // Ensure it stretches to bottom of container
            overflow: 'auto', // Allow scrolling if content is too large
            transition: theme.transitions.create('left', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }}
        >
          <Toolbar sx={{ my: 0 }} />
          <Box sx={{ flexGrow: 1, mb: 1 }}>
            {SelectedComponent && <SelectedComponent />}
          </Box>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
