import * as React from "react";
 
// importing material UI components
import { useState } from 'react';
import Container from '@mui/material/Container';

import ClientDetailsTab from "./ClientDetailsTab";

const ClientDetails = (props) => {

  return (
    <div className="App">
      <Container maxWidth={false}>
        <>
          {/* <h2 className="heading-title">Client Details</h2> */}
          <ClientDetailsTab 
            key={props.refreshKey}
            clientCode={props.clientCode}
          />
        </>
      </Container>

    </div>
  );
}
export default ClientDetails;