import { Container, Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";

const NoAccessPage = () => {
  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 3,
        mt: 25
      }}
    >
      <LockIcon style={{ fontSize: 80, color: "#ccc" }} />
      <Typography variant="h6" color="textSecondary" mt={2} align="center">
        You don't have access to this page. Please contact the admin.
      </Typography>
    </Container>
  );
};

export default NoAccessPage;

// // NoAccessPage.js
// import React, { useState, useEffect } from 'react';
// import { 
//   Box, 
//   Typography, 
//   Paper, 
//   Button, 
//   Alert, 
//   AlertTitle, 
//   Divider,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   Chip,
//   Card,
//   CardHeader,
//   CardContent
// } from '@mui/material';
// import LockIcon from '@mui/icons-material/Lock';
// import PersonOffIcon from '@mui/icons-material/PersonOff';
// import BlockIcon from '@mui/icons-material/Block';
// import BusinessIcon from '@mui/icons-material/Business';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import InfoIcon from '@mui/icons-material/Info';
// import SecurityIcon from '@mui/icons-material/Security';
// import { useNavigate } from 'react-router-dom';

// const NoAccessPage = () => {
//   const navigate = useNavigate();
//   const [sessionInfo, setSessionInfo] = useState({
//     role: '',
//     tenant: '',
//     features: []
//   });
  
//   useEffect(() => {
//     // Get session info from localStorage
//     const role = localStorage.getItem('role') || 'unknown';
//     const tenant = localStorage.getItem('tenant') || 'unknown';
//     const features = JSON.parse(localStorage.getItem('features') || '[]');
    
//     setSessionInfo({
//       role,
//       tenant,
//       features: features.join(', ') || 'none'
//     });
//   }, []);
  
//   const handleGoBack = () => {
//     // Go to the previous page or to a safe default page
//     navigate(-1);
//   };
  
//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         height: '70vh',
//         bgcolor: 'background.default',
//         p: 2
//       }}
//     >
//       <Card
//         elevation={4}
//         sx={{
//           maxWidth: 550,
//           width: '100%',
//           borderRadius: 2,
//           overflow: 'hidden',
//           border: '1px solid',
//           borderColor: 'primary.light',
//         }}
//       >
//         <CardHeader
//           avatar={
//             <Box
//               sx={{
//                 bgcolor: 'primary.main',
//                 color: 'primary.contrastText',
//                 borderRadius: '50%',
//                 p: 1,
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center'
//               }}
//             >
//               <SecurityIcon />
//             </Box>
//           }
//           title={
//             <Typography variant="h5" component="h1" fontWeight="500">
//               Access Restricted
//             </Typography>
//           }
//           subheader="You don't have permission to access this module"
//           sx={{
//             bgcolor: 'primary.light',
//             color: 'primary.contrastText',
//             '& .MuiCardHeader-subheader': {
//               color: 'primary.contrastText',
//               opacity: 0.8
//             }
//           }}
//         />

//         <CardContent sx={{ p: 3 }}>
//           <Box sx={{ mb: 3 }}>
//             <Typography variant="subtitle1" fontWeight="500" gutterBottom>
//               Why am I seeing this?
//             </Typography>
            
//             <List disablePadding>
//               <ListItem sx={{ pl: 0, py: 1 }}>
//                 <ListItemIcon sx={{ minWidth: 42 }}>
//                   <PersonOffIcon color="primary" />
//                 </ListItemIcon>
//                 <ListItemText 
//                   primary="Permission Required" 
//                   secondary="Your account lacks the necessary permissions" 
//                 />
//               </ListItem>
//               <ListItem sx={{ pl: 0, py: 1 }}>
//                 <ListItemIcon sx={{ minWidth: 42 }}>
//                   <BlockIcon color="primary" />
//                 </ListItemIcon>
//                 <ListItemText 
//                   primary="Plan Limitation" 
//                   secondary="This feature is not included in your current subscription" 
//                 />
//               </ListItem>
//               <ListItem sx={{ pl: 0, py: 1 }}>
//                 <ListItemIcon sx={{ minWidth: 42 }}>
//                   <BusinessIcon color="primary" />
//                 </ListItemIcon>
//                 <ListItemText 
//                   primary="Tenant Restriction" 
//                   secondary="This module is unavailable for your organization" 
//                 />
//               </ListItem>
//             </List>
//           </Box>

//           <Divider sx={{ mb: 3 }} />
          
          
//           <Alert 
//             severity="warning"
//             variant="outlined"
//             sx={{ width: '100%' }}
//           >
//             <AlertTitle>Need Assistance?</AlertTitle>
//             Contact your system administrator with your role and tenant information if you believe you should have access to this page.
//           </Alert>
//         </CardContent>
//       </Card>
//     </Box>
//   );
// };

// export default NoAccessPage;

// // NoAccessPage.js
// import React, { useState, useEffect } from 'react';
// import { 
//   Box, 
//   Typography, 
//   Paper, 
//   Button, 
//   Alert, 
//   AlertTitle, 
//   Divider,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   Chip
// } from '@mui/material';
// import LockIcon from '@mui/icons-material/Lock';
// import PersonOffIcon from '@mui/icons-material/PersonOff';
// import CancelIcon from '@mui/icons-material/Cancel';
// import BusinessIcon from '@mui/icons-material/Business';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import InfoIcon from '@mui/icons-material/Info';
// import { useNavigate } from 'react-router-dom';

// const NoAccessPage = () => {
//   const navigate = useNavigate();
//   const [sessionInfo, setSessionInfo] = useState({
//     role: '',
//     tenant: '',
//     features: []
//   });
  
//   useEffect(() => {
//     // Get session info from localStorage
//     const role = localStorage.getItem('role') || 'unknown';
//     const tenant = localStorage.getItem('tenant') || 'unknown';
//     const features = JSON.parse(localStorage.getItem('features') || '[]');
    
//     setSessionInfo({
//       role,
//       tenant,
//       features: features.join(', ') || 'none'
//     });
//   }, []);
  
//   const handleGoBack = () => {
//     // Go to the previous page or to a safe default page
//     navigate(-1);
//   };
  
//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         height: '70vh',
//       }}
//     >
//       <Paper
//         elevation={3}
//         sx={{
//           p: 4,
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//           maxWidth: 850,
//           width: '100%',
//           borderRadius: 2,
//           overflow: 'hidden',
//           position: 'relative',
//         }}
//       >
//         {/* Header with improved alignment */}
//         <Box 
//           sx={{ 
//             bgcolor: 'error.light', 
//             color: 'error.contrastText',
//             width: 'calc(100% + 32px)', // Adjust for padding
//             py: 2,
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//             mb: 3,
//             mx: 0, // Negative margin to extend full width
//             mt: 0,
//             position: 'relative', // Add position relative
//           }}
//         >
//           <Box sx={{ 
//             display: 'flex', 
//             alignItems: 'center',
//             justifyContent: 'center',
//           }}>
//             <LockIcon sx={{ fontSize: 36, mr: 1.5 }} />
//             <Typography 
//               variant="h5" 
//               component="h1" 
//               fontWeight="500"
//               sx={{ lineHeight: 1.2 }} // Adjust line height
//             >
//               Access Denied
//             </Typography>
//           </Box>
//         </Box>

//         <Typography variant="body1" sx={{ mb: 3, fontWeight: 500 }}>
//           You don't have permission to access this module.
//         </Typography>
        
//         <Divider sx={{ width: '100%', mb: 3 }} />
        
//         <Typography variant="subtitle1" fontWeight="500" sx={{ mb: 1, alignSelf: 'flex-start' }}>
//           Possible reasons:
//         </Typography>
        
//         <List sx={{ width: '100%', mb: 3 }}>
//           <ListItem>
//             <ListItemIcon>
//               <PersonOffIcon color="error" />
//             </ListItemIcon>
//             <ListItemText 
//               primary="Insufficient permissions" 
//               secondary="Your user role doesn't have the required access rights" 
//             />
//           </ListItem>
//           <ListItem>
//             <ListItemIcon>
//               <CancelIcon color="error" />
//             </ListItemIcon>
//             <ListItemText 
//               primary="Subscription limitation" 
//               secondary="This feature is not included in your current plan" 
//             />
//           </ListItem>
//           <ListItem>
//             <ListItemIcon>
//               <BusinessIcon color="error" />
//             </ListItemIcon>
//             <ListItemText 
//               primary="Tenant restriction" 
//               secondary="This module is not available for your organization" 
//             />
//           </ListItem>
//         </List>
        
        
        
//         <Alert 
//           severity="warning"
//           sx={{ width: '100%' }}
//         >
//           <AlertTitle>Need Help?</AlertTitle>
//           If you believe you should have access to this page, please contact your administrator with your role and tenant information.
//         </Alert>
//       </Paper>
//     </Box>
//   );
// };

// export default NoAccessPage;