import React, { useState, useEffect } from 'react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
// import '../css/SearchIcon.css';
import { IconButton } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { DRIVERS_SEARCH_ENDPOINT } from '../../../config/urls';
import { useAuth } from "../../../hooks/AuthProvider";

// Determine the API URL based on the environment
const URL = DRIVERS_SEARCH_ENDPOINT;

const SearchInput = ({ updateSearchValue }) => {
  const [showInput, setShowInput] = useState(false);
  const [query, setQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getValidToken } = useAuth();


  const handleInputChange = (event, value) => {
    setQuery(value);
    if (value === '') {
      updateSearchValue(''); // Clear search filter
    } else {
      fetchSearchResults(value);
    }
  };

  const handleSearch = () => {
    updateSearchValue(query);
  };

  const handleSearchIconClick = () => {
    setShowInput(true);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleOptionSelect = (event, value) => {
    if (value && value.id) {
      updateSearchValue(value.id);
    }
  };

  const fetchSearchResults = async (searchQuery) => {
    setLoading(true);
    try {
      const token = await getValidToken();

      const tenant = localStorage.getItem('tenant');
      const headers = {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
        "X-Tenant-Id": tenant
      };
      const response = await fetch(`${URL}?search_value=${searchQuery}`, { headers });
      const data = await response.json();
      setOptions(data.results);
    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <div className='search-row'>
      
      {
        showInput ? (
          <Autocomplete
            id="asynchronous-demo"
            sx={{ width: 500 }}
            size="small"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={(option) => `${option.name} (${option.phone_no})`}
            options={options}
            loading={loading}
            onInputChange={(event, value) => handleInputChange(event, value)}
            onChange={handleOptionSelect}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search driver's name or phone"
                onChange={(event) => handleInputChange(event, event.target.value)}
                onKeyPress={handleKeyPress}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <div>
                  <strong>{option.name}</strong>
                  <br />
                  <small>Phone: {option.phone_no}</small>
                </div>
              </li>
            )}
          />
        ) : (
          <IconButton onClick={handleSearchIconClick} color="primary">
            <SearchOutlinedIcon />
          </IconButton>
        )
      }
    </div>
  );
};

export default SearchInput;
