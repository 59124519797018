import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    Grid,
    Typography,
    Container,
    TextField,
    Button,
    Alert,
    IconButton,
    Autocomplete,
    RadioGroup, 
    Radio, 
    FormControlLabel,
    Divider,
    FormControl,
    CircularProgress
} from '@mui/material';
import OneTimeBroadcast from './BroadcastTypes/OneTimeBroadcast';
import RecurringBroadcast from './BroadcastTypes/RecurringBroadcast';
import DeleteIcon from '@mui/icons-material/Delete';
import MessageNode from "../Whatsapp/MessageNode";
import { TEMPLATE_ENDPOINT, TEMPLATE_BROADCAST_ENDPOINT, CAMPAIGN_LIST_ENDPOINT } from '../../config/urls';
import UploadCSV from './CampaignOptions/UploadCSV';
import SingleContact from './CampaignOptions/SingleContact';
import SelectSegment from './CampaignOptions/SelectSegment';
import axios from 'axios';
import { useAuth } from '../../hooks/AuthProvider';

const BroadcastTemplates = (props) => {
    const { getValidToken } = useAuth();
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [templateOptions, setTemplateOptions] = useState([]);
    const [templateData, setTemplateData] = useState(null);
    const [templatePreviewData, setTemplatePreviewData] = useState(null);
    const [apiData, setApiData] = useState(null);
    const [requiredFields, setRequiredFields] = useState({});
    const fileInputRef = useRef(null);
    const [mediaType, setMediaType] = useState('none');
    const [mediaFile, setMediaFile] = useState(null);
    const [requiredFieldsToRender, setRequiredFieldsToRender] = useState([]);
    const [formValues, setFormValues] = useState({});
    const [showCampaignOptions, setShowCampaignOptions] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState('');
    const [campaignsData, setCampaignsData] = useState([]);
    const [csvFile, setCsvFile] = useState(null);
    const [campaignName, setCampaignName] = useState('');
    const [campaignDescription, setCampaignDescription] = useState('');
    const [selectedSegment, setSelectedSegment] = useState('Select a segment');
    const [selectedSegmentList, setSelectedSegmentList] = useState([]);
    const [phoneNumberList, setPhoneNumberList] = useState([]);
    const [countryCode, setCountryCode] = useState('');
    const [dialCode, setDialCode] = useState('');
    const [alertObj, setAlertObj] = useState({ active: false, msg: "", type: "" });
    const [csvError, setCSVError] = useState(null);
    const [showAllErrors, setShowAllErrors] = useState(false);
    const [loading, setLoading] = useState(false);

    const [showTemplateSelection, setShowTemplateSelection] = useState(false);
    const [broadcastType, setBroadcastType] = useState('now'); // 'now', 'one-time', or 'recurring'
    const [scheduleDate, setScheduleDate] = useState('');
    const [broadcastTime, setBroadcastTime] = useState('');
    const [notifyBefore, setNotifyBefore] = useState(true);
    const [notifyStatus, setNotifyStatus] = useState(true);

    const [categoryFilter, setCategoryFilter] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const handleAlertClose = () => setAlertObj({ active: false, type: "", msg: "" });

    const handleMediaFileChange = (event) => {
        const file = event.target.files[0];
        if (file) setMediaFile(file);
    };

    const handleDeleteMediaFile = () => {
        setMediaFile(null);
        if (fileInputRef.current) fileInputRef.current.value = '';
        handleTemplateChange({ target: { value: selectedTemplate || '' } });
    };

    const handleCategoryFilterChange = (event) => {
        setCategoryFilter(event.target.value);
      };

    const fetchTemplatesData = useCallback(async () => {
        const token = await getValidToken();
        const tenant = localStorage.getItem('tenant');
        try {
            const response = await fetch(`${TEMPLATE_ENDPOINT}?category_filter=${categoryFilter}&limit=1000`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "X-Tenant-Id": tenant
                }
            });
            if (!response.ok) throw new Error('Failed to fetch templates data');
            const jsonData = await response.json();
            const approvedTemplates = {
                ...jsonData,
                results: jsonData.results.filter(template => template.status === 'APPROVED' && template.active)
            };
            setApiData(approvedTemplates);
            setTemplateOptions(approvedTemplates.results.map(item => item.template_name));
            setRequiredFields(approvedTemplates.required_fields);
        } catch (error) {
            console.error('Error fetching templates data:', error.message);
        }
    }, [getValidToken, categoryFilter]);

    const fetchCampaignsData = useCallback(async () => {
        const token = await getValidToken();
        const tenant = localStorage.getItem('tenant');
        try {
            const response = await fetch(`${CAMPAIGN_LIST_ENDPOINT}?limit=1000`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "X-Tenant-Id": tenant   
                }
            });
            if (!response.ok) throw new Error('Failed to fetch campaigns data');
            const jsonData = await response.json();
            const campaignNames = jsonData.results.map(campaign => campaign.campaign_name.toLowerCase());
            setCampaignsData(campaignNames);
        } catch (error) {
            console.error('Error fetching campaigns data:', error.message);
        }
    }, [getValidToken]);

    const handleTemplateChange = (event) => {
        const selectedTemplateName = event.target.value;
        setSelectedTemplate(selectedTemplateName);
        if (selectedTemplateName === '') {
            resetForm();
        } else {
            const selectedTemplate = apiData?.results.find(item => item.template_name === selectedTemplateName);
            if (selectedTemplate) {
                setTemplatePreviewData(selectedTemplate.template_preview_json);
                setTemplateData(selectedTemplate.template_json);
                resetForm();
            } else {
                resetForm();
            }
            setMediaType('none');
            updateRequiredFields(selectedTemplateName);
        }
    };

    const resetForm = () => {
        setFormValues({});
        setRequiredFieldsToRender([]);
        setMediaType('none');
        setMediaFile(null);
        if (fileInputRef.current) fileInputRef.current.value = '';
    };

    // HANDLE BROADCAST TYPE CHANGE
    const handleBroadcastTypeChange = (event) => {
        setBroadcastType(event.target.value);
        // Reset scheduling fields when changing broadcast type
        if (event.target.value === 'now') {
            setSelectedCampaign('');
            setCsvFile(null);
            setCountryCode('');
            setDialCode('');
            setSelectedSegment('Select a segment');
            setSelectedSegmentList([]);
            setPhoneNumberList([]);
        }

        if (event.target.value !== 'one-time') {
            setScheduleDate('');
            setBroadcastTime('');
            setSelectedCampaign('');
        }
    };

    // HANDLE CANCEL
    const handleCancelSchedule = () => {
        setBroadcastType('now');
        setScheduleDate('');
        setBroadcastTime('');
    };

    const updateRequiredFields = (templateName) => {
        const requiredFieldsForTemplate = requiredFields[templateName];
        if (requiredFieldsForTemplate) {
            const fieldsToRender = [];
            const updatedFormValues = { ...formValues };

            const fieldHandlers = {
                hparameter: () => {
                    fieldsToRender.push(
                        <Grid container direction="column" key="hparameter" sx={{ marginBottom: 2, width: '50%' }}>
                            <TextField key="hparameter" label="Header Parameter" name='hparameter' onChange={handleInputChange} required />
                        </Grid>
                    );
                    updatedFormValues.hparameter = '';
                },
                bparameter: () => {
                    const bparameterCount = requiredFieldsForTemplate.filter(field => field === 'bparameter').length;
                    for (let i = 0; i < bparameterCount; i++) {
                        fieldsToRender.push(
                            <Grid container direction="column" key={`bparameter-${i}`} sx={{ marginBottom: 2, width: '50%' }}>
                                <TextField key={`bparameter-${i}`} label={`Body Parameter ${i + 1}`} name={`bparameter-${i}`} onChange={handleInputChange} required />
                            </Grid>
                        );
                        updatedFormValues[`bparameter-${i}`] = '';
                    }
                },
                urlparameter: () => {
                    fieldsToRender.push(
                        <Grid container direction="column" key="urlparameter" sx={{ marginBottom: 2, width: '50%' }}>
                            <TextField key="urlparameter" label="URL Parameter" name='urlparameter' onChange={handleInputChange} required />
                        </Grid>
                    );
                    updatedFormValues.urlparameter = '';
                },
                imageparameter: () => {
                    setMediaType('image');
                    fieldsToRender.push(getFileInput('image'));
                    updatedFormValues.imageparameter = '';
                },
                videoparameter: () => {
                    setMediaType('video');
                    fieldsToRender.push(getFileInput('video'));
                    updatedFormValues.videoparameter = '';
                },
                documentparameter: () => {
                    setMediaType('document');
                    fieldsToRender.push(getFileInput('document'));
                    updatedFormValues.documentparameter = '';
                },
                locationparameter: () => {
                    fieldsToRender.push(
                        <>
                            <Grid container direction="column" key="locationparameter_name" sx={{ marginBottom: 2, width: '50%' }}>
                                <TextField key="locationparameter_name" label="Location Name" name='locationparameter_name' onChange={handleInputChange} required />
                            </Grid>
                            <Grid container direction="column" key="locationparameter_address" sx={{ marginBottom: 2, width: '50%' }}>
                                <TextField key="locationparameter_address" label="Location Address" name='locationparameter_address' onChange={handleInputChange} required />
                            </Grid>
                            <Grid container direction="column" key="locationparameter_latitude" sx={{ marginBottom: 2, width: '50%' }}>
                                <TextField key="locationparameter_latitude" label="Location Latitude" name='locationparameter_latitude' onChange={handleInputChange} required />
                            </Grid>
                            <Grid container direction="column" key="locationparameter_longitude" sx={{ marginBottom: 2, width: '50%' }}>
                                <TextField key="locationparameter_longitude" label="Location Longitude" name='locationparameter_longitude' onChange={handleInputChange} required />
                            </Grid>
                        </>
                    );
                    updatedFormValues.locationparameter_name = '';
                    updatedFormValues.locationparameter_address = '';
                    updatedFormValues.locationparameter_latitude = '';
                    updatedFormValues.locationparameter_longitude = '';
                }
            };

            const uniqueFields = [...new Set(requiredFieldsForTemplate)];
            uniqueFields.forEach(field => {
                if (fieldHandlers[field]) fieldHandlers[field]();
            });

            setFormValues(updatedFormValues);
            setRequiredFieldsToRender(fieldsToRender);
        } else {
            resetForm();
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues(prevFormValues => ({ ...prevFormValues, [name]: value }));
    };

    const handleSubmit = async (event) => {

        setLoading(true);  // Set loading to true before making API call

        if (event) event.preventDefault();
        if (selectedTemplate === '' || (mediaType !== 'none' && !mediaFile)) {
            setAlertObj({ active: true, type: "error", msg: "Media file not uploaded or template not selected" });
            return;
        }

        const payload = new FormData();
        payload.append('template_json', JSON.stringify(templateData));
        Object.keys(formValues).forEach(key => payload.append(key, formValues[key]));
        if (mediaFile) payload.append('file', mediaFile);

        if (selectedCampaign === 'option1') {
            if (campaignName && csvFile) {
                payload.append('campaign_option', selectedCampaign);
                payload.append('campaign_name', campaignName);
                payload.append('campaign_description', campaignDescription);
                payload.append('csv_file', csvFile);
            } else {
                if (!csvFile) {
                    setAlertObj({ active: true, type: "error", msg: "Please upload a CSV file" });
                    return;
                }
                setAlertObj({ active: true, type: "error", msg: "Please enter all the required fields" });
                return;
            }
        } else if (selectedCampaign === 'option2') {
            if (campaignName && selectedSegment && phoneNumberList.length > 0) {
                payload.append('campaign_option', selectedCampaign);
                payload.append('campaign_name', campaignName);
                payload.append('campaign_description', campaignDescription);
                payload.append('segment_name_list', JSON.stringify(selectedSegmentList));
            } else {
                setAlertObj({ active: true, type: "error", msg: "Please enter all the required fields" });
                return;
            }
        } else if (selectedCampaign === 'option3') {
            if (countryCode && dialCode && firstName && lastName) {
                payload.append('campaign_option', selectedCampaign);
                payload.append('country_code', countryCode);
                payload.append('dial_code', dialCode);
                payload.append('first_name', firstName);
                payload.append('last_name', lastName);
            } else {
                setAlertObj({ active: true, type: "error", msg: "Please enter all required fields including first name, last name, and phone number" });
                return;
            }
        }

        if (broadcastType === 'one-time' || broadcastType === 'recurring') {
            // Add scheduling information to payload
            payload.append('broadcast_type', broadcastType);
            
            if (broadcastType === 'one-time') {
                const localDate = new Date(scheduleDate); // Ensure it's a Date object
                const localTime = new Date(broadcastTime).toLocaleTimeString('en-GB', {  
                    hour: '2-digit',  
                    minute: '2-digit',  
                    hour12: false  
                }); // Converts to HH:MM format in local timezone

                const formattedDate = localDate.toLocaleDateString('en-CA'); // YYYY-MM-DD
               
                payload.append('schedule_date', formattedDate);
                payload.append('broadcast_time', localTime);
                // payload.append('schedule_date', scheduleDate);
                // payload.append('broadcast_time', broadcastTime);
            }
            
            payload.append('notify_before', notifyBefore ? true : false);
            payload.append('notify_status', notifyStatus ? true : false);
        }

        const token = await getValidToken();
        const tenant = localStorage.getItem('tenant');

        try {
            const response = await axios.post(TEMPLATE_BROADCAST_ENDPOINT, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                    "X-Tenant-Id": tenant
                },
            });
            if (broadcastType === 'one-time' || broadcastType === 'recurring') {
                setAlertObj({ active: true, type: "success", msg: "Broadcast has been scheduled successfully" });
            }
            else {
                setAlertObj({ active: true, type: "success", msg: "Broadcast Complete" });
            }
            setTimeout(() => props.onNavigate('41'), 3000);
        } catch (error) {
             
            if (error.response.data.message && error.response.data.error_dict && error.response.data.error_dict.file) {
                const errorSummary = error.response.data.error_dict.file.error_summary || "File upload failed due to validation errors.";
                const errorDetails = error.response.data.error_dict.file.errors || [];
                setCSVError({ summary: errorSummary || "An error occurred.", details: errorDetails || [] });
              }
            else {
                setAlertObj({ active: true, type: "error", msg: `Broadcast Failed - ${error.response.status === 401 ? 'Unauthorized' : error.response.data.message}` });
            }
        }
        finally {
            setLoading(false);
          }
    };

    useEffect(() => {
        handleBroadcastTypeChange({ target: { value: broadcastType } });
    }, [broadcastType]);

    const handleCampaignClick = (event) => {
        event.preventDefault();
        
        // Check if media file is required but not uploaded
        if (mediaType !== 'none' && !mediaFile) {
            setAlertObj({ active: true, type: "error", msg: "Please upload the required media file" });
            return;
        }
        
        if (event.target.checkValidity()) {
            setShowCampaignOptions(true);
        } else {
            event.target.reportValidity();
        }
    };

    const getFileInput = (type) => {
        const fileTypes = {
            image: {
                accept: '.jpg, .png',
                label: 'Choose .JPG or .PNG*',
            },
            video: {
                accept: '.mp4',
                label: 'Choose .MP4 file*',
            },
            document: {
                accept: '.pdf',
                label: 'Choose .PDF file*',
            },
        };

        if (fileTypes[type]) {
            const { accept, label } = fileTypes[type];
            return (
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <input
                            ref={fileInputRef}
                            id="file-upload"
                            name="uploadedFile"
                            type="file"
                            accept={accept}
                            onChange={handleMediaFileChange}
                            style={{ display: 'none' }}
                            required
                        />
                        <label htmlFor="file-upload">
                            <Button
                                variant="contained"
                                component="span"
                                sx={{ marginTop: 1, marginBottom: 1 }}
                            >
                                {label}
                            </Button>
                        </label>
                    </Grid>
                </Grid>
            );
        }
        return null;
    };

    const FileUploadDetails = () => {
        if (!mediaFile) {
            return null;
        }

        return (
            <Grid container alignItems="center" spacing={2}>
                <Grid item>
                    <Typography variant="body2">
                        File uploaded: {mediaFile.name}
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton
                        aria-label="delete"
                        onClick={handleDeleteMediaFile}
                        size="small"
                    >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>
        );
    };

    const handleNextClick = () => {
        if (categoryFilter) {
            setShowTemplateSelection(true);
            // Clear any existing template selection
            setSelectedTemplate('');
            // Explicitly fetch data with the current category filter
            fetchTemplatesData();
        } else {
            setAlertObj({ active: true, type: "error", msg: "Please select a template type first" });
        }
    };

    useEffect(() => {
        // Initial data fetch - should run only once when component mounts
        fetchTemplatesData();
        fetchCampaignsData();
    }, []);

    useEffect(() => {
        // fetchTemplatesData();
        // fetchCampaignsData();
        setRequiredFieldsToRender([...requiredFieldsToRender]);
    }, [mediaType, mediaFile, selectedTemplate, formValues, csvFile]);

    // TODO: Enable this when necessary its not necessary for broadcast
    // useEffect(() => {
    //     let timeoutId;
    //     if (alertObj.active) {
    //         timeoutId = setTimeout(handleAlertClose, 5000);
    //     }
    //     return () => clearTimeout(timeoutId);
    // }, [alertObj]);

    useEffect(() => {
        // Initial data fetch - should run only once when component mounts
        fetchTemplatesData();
    }, [categoryFilter]);

    return (
        <Container maxWidth={false}>
            <div sx={{ padding: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <div sx={{ padding: 2 }}>
                            <form onSubmit={handleCampaignClick}>
                                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                                    Select Broadcast Template Type
                                </Typography>
                                <Grid item xs={6}>
                                    <FormControl component="fieldset">
                                        <RadioGroup 
                                            row
                                            value={categoryFilter} 
                                            onChange={handleCategoryFilterChange}
                                        >
                                            <FormControlLabel value="marketing" control={<Radio />} label="Marketing" />
                                            <FormControlLabel value="utility" control={<Radio />} label="Utility" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Button 
                                    variant="contained" 
                                    onClick={handleNextClick}
                                    sx={{ mt: 1, mb: 1 }}
                                >
                                    Next
                                </Button>

                                {/* Select Template Section - Only show after category selection and Next button click */}
                                {showTemplateSelection && categoryFilter && (
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" sx={{ marginBottom: 1 }}>
                                                Select Template
                                            </Typography>
                                            <Autocomplete
                                                sx={{ width: '50%' }}
                                                options={templateOptions}
                                                value={selectedTemplate}
                                                onChange={(event, newValue) => handleTemplateChange({ target: { value: newValue || '' } })}
                                                renderInput={(params) => (
                                                    <TextField 
                                                        {...params} 
                                                        label="Select a template" 
                                                        variant="outlined"
                                                        required
                                                    />
                                                )}
                                                getOptionLabel={(option) => option}
                                                isOptionEqualToValue={(option, value) => option === value}
                                                noOptionsText="No templates found"
                                            />
                                            <Typography variant="body2" sx={{ marginTop: 1, color: 'gray' }}>
                                                Note: Only {categoryFilter} templates are available
                                            </Typography>
                                        </Grid>
                                    
                                    
                                        <Grid item xs={12} sx={{ marginTop: 2 }}>
                                            {mediaFile && <FileUploadDetails />}
                                        </Grid>

                                        {requiredFieldsToRender}
                                        
                                        <Button 
                                            variant="contained" 
                                            sx={{ marginTop: 2 }} 
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (mediaType !== 'none' && !mediaFile) {
                                                    setAlertObj({ active: true, type: "error", msg: "Please upload the required media file" });
                                                    return;
                                                }
                                                handleCampaignClick(e);
                                            }}
                                            type="submit"
                                        >
                                            Create Campaign
                                        </Button>
                                    </>
                                )}
                            </form>

                            {selectedTemplate && (
                                <form onSubmit={handleSubmit}>
                                    {showCampaignOptions && (
                                        <Grid container direction="column" sx={{ marginTop: 1 }}>
                                            <Divider sx={{ width: '100%', mt: 2 }} />
                                            
                                            <>
                                                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                                                    Select Campaign Type:
                                                </Typography>
                                                <RadioGroup
                                                    value={selectedCampaign}
                                                    onChange={(e) => setSelectedCampaign(e.target.value)}
                                                >
                                                    <FormControlLabel value="option1" control={<Radio />} label="Upload Audience using CSV" />
                                                    <FormControlLabel value="option2" control={<Radio />} label="Send templates using segments" />
                                                    <FormControlLabel value="option3" control={<Radio />} label="Send template to a single contact" />
                                                </RadioGroup>
                                                <Divider sx={{ width: '100%', mt: 2 }} />
                                            </>
                                            {/* Add Broadcast Type Selection here */}
                                            {selectedCampaign && (
                                                <Grid item xs={12} sx={{ marginBottom: 2, marginTop: 2 }}>
                                                    <Typography variant="h6" sx={{ marginBottom: 1 }}>
                                                        Broadcast Type
                                                    </Typography>
                                                    <RadioGroup
                                                        row
                                                        value={broadcastType}
                                                        onChange={handleBroadcastTypeChange}
                                                    >
                                                        <FormControlLabel value="now" control={<Radio />} label="Now" />
                                                        <FormControlLabel value="one-time" control={<Radio />} label="One-time" />
                                                        <FormControlLabel value="recurring" control={<Radio />} label="Recurring" />
                                                    </RadioGroup>
                                                </Grid>
                                            )}
                                            
                                            {/* Only show campaign type options for "now" broadcast type */}
                                        </Grid>
                                    )}

                                    {/* <Divider sx={{ width: '100%', mt: 2 }} /> */}

                                    {selectedCampaign === 'option1' && (
                                        <UploadCSV 
                                            campaignName={campaignName}
                                            setCampaignName={setCampaignName}
                                            campaignDescription={campaignDescription}
                                            setCampaignDescription={setCampaignDescription}
                                            csvFile={csvFile}
                                            setCsvFile={setCsvFile}
                                            campaignsData={campaignsData}
                                        />
                                    )}

                                    {selectedCampaign === 'option2' && (
                                        <SelectSegment
                                            campaignName={campaignName}
                                            setCampaignName={setCampaignName}
                                            campaignDescription={campaignDescription}
                                            setCampaignDescription={setCampaignDescription}
                                            selectedSegment={selectedSegment}
                                            setSelectedSegment={setSelectedSegment}
                                            selectedSegmentList={selectedSegmentList}
                                            setSelectedSegmentList={setSelectedSegmentList}
                                            phoneNumberList={phoneNumberList}
                                            setPhoneNumberList={setPhoneNumberList}
                                            campaignsData={campaignsData}
                                        />
                                    )}

                                    {selectedCampaign === 'option3' && (
                                        <SingleContact
                                            setCountryCode={setCountryCode}
                                            setDialCode={setDialCode}
                                            setFirstName={setFirstName}
                                            setLastName={setLastName}
                                        />
                                    )}

                                    {selectedCampaign && broadcastType === 'now' && (
                                        <Button variant="contained" type="submit" sx={{ marginTop: 2 }} disabled={loading}>
                                            {loading ? <CircularProgress size={24} /> : "Broadcast"} 
                                        </Button>
                                        
                                    )}
                                </form>
                            )}

                            {selectedTemplate && broadcastType === 'one-time' && (
                                <OneTimeBroadcast
                                    campaignName={campaignName}
                                    setCampaignName={setCampaignName}
                                    campaignDescription={campaignDescription}
                                    setCampaignDescription={setCampaignDescription}
                                    scheduleDate={scheduleDate}
                                    setScheduleDate={setScheduleDate}
                                    broadcastTime={broadcastTime}
                                    setBroadcastTime={setBroadcastTime}
                                    notifyBefore={notifyBefore}
                                    setNotifyBefore={setNotifyBefore}
                                    notifyStatus={notifyStatus}
                                    setNotifyStatus={setNotifyStatus}
                                    campaignsData={campaignsData}
                                    onCancel={handleCancelSchedule}
                                    onSubmit={handleSubmit}
                                />
                            )}

                            {selectedTemplate && broadcastType === 'recurring' && (
                                <RecurringBroadcast
                                    campaignName={campaignName}
                                    setCampaignName={setCampaignName}
                                    campaignDescription={campaignDescription}
                                    setCampaignDescription={setCampaignDescription}
                                    scheduleDate={scheduleDate}
                                    setScheduleDate={setScheduleDate}
                                    broadcastTime={broadcastTime}
                                    setBroadcastTime={setBroadcastTime}
                                    notifyBefore={notifyBefore}
                                    setNotifyBefore={setNotifyBefore}
                                    notifyStatus={notifyStatus}
                                    setNotifyStatus={setNotifyStatus}
                                    campaignsData={campaignsData}
                                    onCancel={handleCancelSchedule}
                                    onSubmit={handleSubmit}
                                />
                            )}
                            
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <MessageNode 
                            templateName={templatePreviewData?.template_name || ''}
                            language={templatePreviewData?.language || 'english'}
                            headerType={templatePreviewData?.headerType || 'none'}
                            headerText={templatePreviewData?.headerText || ''}
                            headerFile={mediaFile || templatePreviewData?.headerFile || null}
                            body={templatePreviewData?.body || 'No template selected'}
                            sampleBodyFields={templatePreviewData?.sampleBodyFields || []}
                            footer={templatePreviewData?.footer || ''}
                            allButtons={templatePreviewData?.allButtons || []}
                            mode='view'
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                    {alertObj.active && (
                        <Alert severity={alertObj.type} onClose={handleAlertClose}>
                            {alertObj.msg}
                        </Alert>
                    )}
                    
                    {csvError && (
                            <Alert severity="error" sx={{ width: "100%" }} onClose={handleAlertClose}>
                            <Typography variant="body1" fontWeight="bold">
                                {csvError.summary}
                            </Typography>
                            {csvError.details.length > 0 && (
                                <ul style={{ margin: 0, paddingLeft: "20px" }}>
                                {(showAllErrors ? csvError.details : csvError.details.slice(0, 5)).map((errMsg, index) => (
                                    <li key={index}>{errMsg}</li>
                                ))}
                                </ul>
                            )}
                            {csvError.details.length > 5 && (
                                <Typography
                                variant="body2"
                                sx={{ cursor: "pointer", color: "blue", textDecoration: "underline", mt: 1 }}
                                onClick={() => setShowAllErrors(!showAllErrors)}
                                >
                                {showAllErrors ? "Show less..." : "Read more..."}
                                </Typography>
                            )}
                            </Alert>
                        )}
                </Grid>
            </div>
        </Container>
    );
};

export default React.memo(BroadcastTemplates);