import * as React from "react";
 
// importing material UI components
import { useState } from 'react';
import WhatsappTable from './WhatsappTable';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import CreateTemplate from './CreateTemplate';
import { 
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';

const Whatsapp = (props) => { 
  
  const [componentState, setComponent] = useState(1);
  const [templatesNamesData, setTemplatesNamesData] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');
  const [charCount, setCharCount] = useState(0);

  const handleSearchQueryChange = (event) => {
    if (event.target.value.length > 50) {
      return;
    }
    const value = event.target.value;
    setSearchQuery(event.target.value);
    setCharCount(value.length);
  };

  const handleCreateTemplateClick = (newState) => {
    setComponent(newState);
  };

  return (
    <div className="App">
      <Container maxWidth={false}>
        {componentState === 1 ? (
          <>
            <h2 className="heading-title">WhatsApp Templates</h2>
            
            {/* Search Field */}
            <Grid item xs={2} md={2} mb={2}>
              <TextField
                sx={{ width: 250 }}
                size="small"
                placeholder="Search"
                title="Search by Template Name"
                value={searchQuery}
                onChange={handleSearchQueryChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="caption">{charCount}/50</Typography>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>

            {/* WhatsApp Templates Table */}
            <WhatsappTable 
              refreshkey={props.refreshKey} 
              setTemplatesNamesData={setTemplatesNamesData} 
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              handleCreateTemplateClick={handleCreateTemplateClick}
            />
          </>
        ) : (
          <>
            <h2 className="heading-title">WhatsApp Templates <span style={{ opacity: 0.6 }}>{'>'}</span> Create Template</h2>
            <Button variant="contained" onClick={() => handleCreateTemplateClick(1)}>Go Back</Button>
            <CreateTemplate 
              templatesNamesData={templatesNamesData}
              setComponent={setComponent}
            />
          </>
        )}
      </Container>

    </div>
  );
}
export default Whatsapp;
