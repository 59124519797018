// import React, { useState, useEffect, useRef } from 'react';
// import { Box, Typography, TextField, IconButton, Avatar, Alert,  Stack, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItemText, ListItemIcon } from '@mui/material';
// import SendIcon from '@mui/icons-material/Send';
// import {
//     FormatItalic,
//     FormatBold,
//     StrikethroughS,
//     Code,
//     FormatQuote,
//     FormatListBulleted,
//     FormatListNumbered,
//   } from '@mui/icons-material';
// import PersonIcon from '@mui/icons-material/Person';
// import ChatIcon from '@mui/icons-material/Chat';
// import { useChatHistoryContext } from '../hooks/ChatHistoryProvider';

// import { blue } from '@mui/material/colors';
// import dayjs from "dayjs";
// import utc from "dayjs/plugin/utc";
// import EmojiPicker from 'emoji-picker-react';
// import timezone from "dayjs/plugin/timezone";
// import RefreshIcon from '@mui/icons-material/Refresh';
// import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
// import TranslateIcon from '@mui/icons-material/Translate';
// // import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
// import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
// import { CHAT_START_TOPIC_ENDPOINT, CHAT_END_TOPIC_ENDPOINT, CHAT_HISTORY_ENDPOINT } from '../config/urls';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import Divider from '@mui/material/Divider';
// import Modal from '@mui/material/Modal';
// import ArrowRightIcon from '@mui/icons-material/ArrowRight';
// import { useAuth } from "../hooks/AuthProvider";

// // Initialize plugins
// dayjs.extend(utc);
// dayjs.extend(timezone);

// // Set default timezone
// dayjs.tz.setDefault("Asia/Kolkata");

// const ChatWindow = () => {
//     const { selectedUser, setChatHistory } = useChatHistoryContext();

//     console.log("Selected User ", selectedUser);

//     const [data, setData] = useState([]);
//     const [alertOpen, setAlertOpen] = useState(false);
//     const [inputValue, setInputValue] = useState("");
//     const [isEndChatModalOpen, setIsEndChatModalOpen] = useState(false);
//     const [selectedTone, setSelectedTone] = useState("");
//     const { getValidToken } = useAuth();
//     const socket = useRef(null);
//     const [modalOpen, setModalOpen] = useState(false);  
//     const [responseText, setResponseText] = useState("");
//     const [totalCreditLeft, setTotalCreditLeft] = useState(0);
//     const chatContainerRef = useRef(null);
    
//     const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:8000/api`;
//     const NEWURL = process.env.NODE_ENV === 'production' ? '' : `http://localhost:8000`;

//     const adminSubscriberCode = localStorage.getItem("code");
//     const [open, setOpen] = React.useState(false);
//     const tenant = localStorage.getItem("tenant");

//     const parseMessage = (message) => {
//         // Replace markdown-like syntax with HTML tags
//         const formattedMessage = message
//             .replace(/_([^_]+)_/g, '<i>$1</i>') // Italic: _text_
//             .replace(/\*([^\*]+)\*/g, '<b>$1</b>') // Bold: *text*
//             .replace(/~([^~]+)~/g, '<s>$1</s>') // Strikethrough: ~text~
//             .replace(/`([^`]+)`/g, '<code>$1</code>'); // Strikethrough: ~text~
    
//         return formattedMessage;
//     };

//     useEffect(() => {
//         if (!selectedUser) return;
//         fetchChatHistory();
//     }, [selectedUser]);

    
    
//     const fetchChatHistory = async () => {
//         if (!selectedUser) return;
    
//         try {
//             const token = await getValidToken();
//             const response = await fetch(CHAT_HISTORY_ENDPOINT + "?search_query=" + selectedUser.sender, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                     "X-tenant-ID": tenant
//                 },
//             });
    
//             if (!response.ok) throw new Error("Failed to fetch chat history");
    
//             const chatData = await response.json();
//             setData(chatData.data);
//         } catch (error) {
//             console.error("Error fetching chat history:", error);
//         }
//     };

//     useEffect(() => {
//         // Auto-scroll only when new messages arrive
//         if (chatContainerRef.current) {
//             chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
//         }
//     }, [data]);

//     return (
//         <Box display="flex" flexDirection="column" height="100%">
//             <Box padding={2} borderBottom="2px solid #ccc" display="flex" alignItems="center">
//                 <Avatar><PersonIcon /></Avatar>
//                 <Typography variant="h6" component="div" marginLeft={2} flexGrow={1}>
//                     {selectedUser ? `${selectedUser.name} +${selectedUser.sender}` : `Chat with User`}
//                 </Typography>
//                 {
//                 selectedUser ? (
//                 <Button
//                         variant="contained"
//                         color="primary"
//                         onClick={fetchChatHistory}
//                         startIcon={<RefreshIcon />}
                        
//                     >
//                         Refresh
//                 </Button>
//                 ) : null
//                 }
//             </Box>
//             <Box flex="1" overflow="auto" padding={4} display="flex" flexDirection="column" sx={{ maxHeight: "calc(100vh - 200px)" }} >
//                     {data.length === 0 ? (
//                         // Center "No chat found" message
//                         <Box 
//                             display="flex" 
//                             flex="1" 
//                             justifyContent="center" 
//                             alignItems="center" 
//                             textAlign="center"
//                             ref={chatContainerRef}
                            
//                         >
//                             <Box>
//                                 <ChatIcon sx={{ fontSize: 60, color: "gray" }} />
//                                 <Typography variant="h6" color="gray">No chat found</Typography>
//                             </Box>
//                         </Box>
//                     ) : (
//                         data.map((message, index) => (
//                             <Box key={index} marginBottom={2} display="flex" flexDirection={message.utype === "SPT" ? 'row-reverse' : 'row'}>
//                                 <Box maxWidth="70%" bgcolor={message.utype === "SPT" ? blue[500] : "#e0e0e0"} padding={1} borderRadius={3} position="relative" sx={{ wordBreak: 'break-word' }}>
//                                     <Stack spacing={0.5} direction="column">
//                                         <Typography wrap sx={{ fontSize: 14, textAlign: "left" }} color={message.utype === "SPT" ? "white" : "black"} dangerouslySetInnerHTML={{ __html: parseMessage(message.message) }} />
//                                         <Stack direction="row-reverse">
//                                             <Typography wrap sx={{ fontSize: 10 }} color={message.utype === "SPT" ? "white" : "black"}>{dayjs(message.timestamp).format('hh:mm A')}</Typography>
//                                         </Stack>
//                                     </Stack>
//                                 </Box>
//                             </Box>
//                         ))
//                     )}
//                 </Box>
//         </Box>
//     );
// };

// export default ChatWindow;

import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Button, Avatar, Stack } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import PersonIcon from '@mui/icons-material/Person';
import ChatIcon from '@mui/icons-material/Chat';
import { useChatHistoryContext } from '../hooks/ChatHistoryProvider';
import { blue } from '@mui/material/colors';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useAuth } from "../hooks/AuthProvider";
import { CHAT_HISTORY_ENDPOINT } from '../config/urls';

// Initialize plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Kolkata");

const ChatWindow = () => {
    const { selectedUser } = useChatHistoryContext();
    const [data, setData] = useState([]);
    const { getValidToken } = useAuth();
    const chatContainerRef = useRef(null);
    const tenant = localStorage.getItem("tenant");

    useEffect(() => {
        if (!selectedUser) return;
        fetchChatHistory();
    }, [selectedUser]);

    const fetchChatHistory = async () => {
        if (!selectedUser) return;

        try {
            const token = await getValidToken();
            const response = await fetch(`${CHAT_HISTORY_ENDPOINT}?search_query=${selectedUser.sender}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "X-tenant-ID": tenant
                },
            });

            if (!response.ok) throw new Error("Failed to fetch chat history");

            const chatData = await response.json();
            setData(chatData.data);
        } catch (error) {
            console.error("Error fetching chat history:", error);
        }
    };

    useEffect(() => {
        // Auto-scroll only when new messages arrive
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [data]);

    return (
        <Box display="flex" flexDirection="column" height="100%" sx={{ backgroundColor: "#f5f5f5" }}>
            <Box padding={2} borderBottom="2px solid #ccc" display="flex" alignItems="center">
                <Avatar><PersonIcon /></Avatar>
                <Typography variant="h6" component="div" marginLeft={2} flexGrow={1}>
                    {selectedUser ? `${selectedUser.name} +${selectedUser.sender}` : `Chat with User`}
                </Typography>
                {selectedUser && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={fetchChatHistory}
                        startIcon={<RefreshIcon />}
                    >
                        Refresh
                    </Button>
                )}
            </Box>
            <Box
                ref={chatContainerRef}
                flex="1"
                overflow="auto"
                padding={4}
                display="flex"
                flexDirection="column"
                sx={{ maxHeight: "calc(100vh - 200px)" }} // Adjust height if needed
            >
                {data.length === 0 ? (
                    <Box display="flex" flex="1" justifyContent="center" alignItems="center" textAlign="center">
                        <ChatIcon sx={{ fontSize: 60, color: "gray" }} />
                        <Typography variant="h6" color="gray">No chat found</Typography>
                    </Box>
                ) : (
                    data.map((message, index) => (
                        <Box key={index} marginBottom={2} display="flex" flexDirection={message.utype === "SPT" ? 'row-reverse' : 'row'}>
                            <Box maxWidth="70%" bgcolor={message.utype === "SPT" ? blue[500] : "#e0e0e0"} padding={1} borderRadius={3} position="relative" sx={{ wordBreak: 'break-word' }}>
                                <Stack spacing={0.5} direction="column">
                                    <Typography wrap sx={{ fontSize: 14, textAlign: "left" }} color={message.utype === "SPT" ? "white" : "black"} dangerouslySetInnerHTML={{ __html: message.message }} />
                                    <Stack direction="row-reverse">
                                        <Typography wrap sx={{ fontSize: 10 }} color={message.utype === "SPT" ? "white" : "black"}>{dayjs(message.timestamp).format('hh:mm A')}</Typography>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Box>
                    ))
                )}
            </Box>
        </Box>
    );
};

export default ChatWindow;
