import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { TextField, Typography, IconButton, Tooltip } from '@mui/material';
import { Grid, Card, CardContent } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DownloadIcon from '@mui/icons-material/Download';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format, subDays, parseISO } from 'date-fns';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip as RechartsTooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';

import { 
    USER_ENGAGEMENT_ENDPOINT,
    USER_ENGAGEMENT_EXPORT_ENDPOINT
} from '../../config/urls';
import { useAuth } from '../../hooks/AuthProvider';

export default function UserEngagementOverviewTab({ refreshKey }) {
    const { getValidToken } = useAuth();
    const [loading, setLoading] = useState(true);
    const [chartData, setChartData] = useState([]);
    const [summaryData, setSummaryData] = useState({
        messagesSent: 0,
        messagesDelivered: 0,
        messagesReceived: 0
    });

    const today = new Date();
    const [dateRange, setDateRange] = useState({
        startDate: subDays(today, 7),
        endDate: today,
        hasError: false
    });

    // Fetch data based on date range
    useEffect(() => {
        fetchEngagementData();
    }, [dateRange, refreshKey]);

    const fetchEngagementData = async () => {
        if (dateRange.hasError || dateRange.startDate > dateRange.endDate) {
            return;
        }

        setLoading(true);

        // Format dates for API query
        const startDateStr = format(dateRange.startDate, 'yyyy-MM-dd');
        const endDateStr = format(dateRange.endDate, 'yyyy-MM-dd');

        // Construct query parameters for server-side filtering and pagination
        const queryParams = new URLSearchParams({
        startDate: startDateStr,
        endDate: endDateStr
        });

        try {
        const token = await getValidToken();
        const tenant = localStorage.getItem('tenant');

        const response = await fetch(`${USER_ENGAGEMENT_ENDPOINT}?${queryParams}`, {
            headers: {
            'Authorization': `Bearer ${token}`,
            "Content-Type": "application/json",
            "X-Tenant-Id": tenant
            }
        });
        
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        
        const jsonData = await response.json();
        console.log("GET API response: ", jsonData);
        
        // Process the data for chart and summary
        processEngagementData(jsonData);
        } catch (error) {
        console.error('Error fetching data:', error.message);
        setChartData([]);
        setSummaryData({
            messagesSent: 0,
            messagesDelivered: 0,
            messagesReceived: 0
        });
        } finally {
        setLoading(false);
        }
    };

    const processEngagementData = (data) => {
        // For development/testing - remove this in production
        if (!data || !data.dailyStats) {
            // Sample data for development/testing
            const sampleData = generateSampleData();
            setChartData(sampleData.chartData);
            setSummaryData(sampleData.summaryData);
            return;
        }
    
        // Process actual API data
        const formattedChartData = data.dailyStats.map(day => ({
            date: day.date,
            messagesSent: day.messagesSent,
            messagesDelivered: day.messagesDelivered,
            messagesReceived: day.messagesReceived
        }));
    
        const totals = {
            messagesSent: data.summary.messagesSent || 0,
            messagesDelivered: data.summary.messagesDelivered || 0,
            messagesReceived: data.summary.messagesReceived || 0
        };
    
        // Check if all values are zero
        const hasNonZeroValues = formattedChartData.some(day => 
            day.messagesSent > 0 || day.messagesDelivered > 0 || day.messagesReceived > 0
        );
    
        // If all values are zero, set empty chart data to trigger "No Data Found" message
        if (!hasNonZeroValues) {
            setChartData([]);
        } else {
            setChartData(formattedChartData);
        }
        
        setSummaryData(totals);
    };

    // Function to generate sample data for development/testing
    const generateSampleData = () => {
        const dayCount = 7;
        const chartData = [];
        let totalSent = 0;
        let totalDelivered = 0;
        let totalReceived = 0;

        // Pattern mimicking the screenshots
        const pattern = [
        { sent: 120, delivered: 120, received: 80 },
        { sent: 90, delivered: 90, received: 75 },
        { sent: 40, delivered: 40, received: 30 },
        { sent: 1, delivered: 1, received: 1 },
        { sent: 60, delivered: 60, received: 50 },
        { sent: 110, delivered: 110, received: 90 },
        { sent: 150, delivered: 150, received: 120 },
        { sent: 460, delivered: 460, received: 423 }
        ];

        for (let i = 0; i < dayCount; i++) {
        const currentDate = subDays(dateRange.endDate, dayCount - 1 - i);
        const dayStats = pattern[i % pattern.length];
        
        chartData.push({
            date: format(currentDate, 'MMM d'),
            messagesSent: dayStats.sent,
            messagesDelivered: dayStats.delivered,
            messagesReceived: dayStats.received
        });
        
        totalSent += dayStats.sent;
        totalDelivered += dayStats.delivered;
        totalReceived += dayStats.received;
        }

        return {
        chartData,
        summaryData: {
            messagesSent: totalSent,
            messagesDelivered: totalDelivered,
            messagesReceived: totalReceived
        }
        };
    };

    const handleExport = async () => {
        try {
        const token = await getValidToken();
        const tenant = localStorage.getItem('tenant');
        
        // Format dates for API query
        const startDateStr = format(dateRange.startDate, 'yyyy-MM-dd');
        const endDateStr = format(dateRange.endDate, 'yyyy-MM-dd');
    
        const queryParams = new URLSearchParams({
            startDate: startDateStr,
            endDate: endDateStr
        });

        console.log("export URL: ", `${USER_ENGAGEMENT_EXPORT_ENDPOINT}?${queryParams}`);
    
        // Make sure this URL exactly matches the backend URL (no trailing slash)
        const response = await fetch(`${USER_ENGAGEMENT_EXPORT_ENDPOINT}?${queryParams}`, {
            headers: {
            'Authorization': `Bearer ${token}`,
            "X-Tenant-Id": tenant
            }
        });
        
        if (!response.ok) {
            throw new Error('Failed to export data');
        }
        
        // Create a blob from the response
        const blob = await response.blob();
        
        // Create a link element to download the file
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `user-engagement-${startDateStr}-to-${endDateStr}.csv`;
        
        // Append to the document and trigger download
        document.body.appendChild(a);
        a.click();
        
        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        } catch (error) {
        console.error('Error exporting data:', error.message);
        // Add proper error handling/notification here
        }
    };

    const handleStartDateChange = (newDate) => {
        // Don't allow null dates
        if (!newDate) return;
    
        const isInvalid = newDate > dateRange.endDate;

        setDateRange(prev => ({
            ...prev,
            startDate: newDate,
            hasError: isInvalid
        }));
    };

    const handleEndDateChange = (newDate) => {
        // Don't allow null dates
        if (!newDate) return;
    
        const isInvalid = newDate < dateRange.startDate;

        setDateRange(prev => ({
            ...prev,
            endDate: newDate,
            hasError: isInvalid
        }));
    };

    const handleResetDateRange = () => {
        setDateRange({
        startDate: subDays(new Date(), 7),
        endDate: new Date()
        });
    };

    // Calculate date range in days
    const dateDifferenceInDays = () => {
        if (dateRange.hasError) {
            return "Invalid date range";
        }
        return `${Math.round((dateRange.endDate - dateRange.startDate) / (1000 * 60 * 60 * 24))} days`;
    }

    // Custom tooltip for the chart
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            console.log("payload: ", payload);
        return (
            <Paper className="custom-tooltip" elevation={3} sx={{ p: 2, backgroundColor: 'white' }}>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{label}</Typography>
            {payload.map((entry, index) => (
                <Typography 
                key={`item-${index}`} 
                variant="body2" 
                style={{ color: entry.color }}
                >
                {entry.dataKey === 'messagesSent' ? 'Messages sent: ' : 
                entry.dataKey === 'messagesDelivered' ? 'Messages delivered: ' : 
                'Messages received: '}{entry.value}
                </Typography>
            ))}
            </Paper>
        );
        }
        return null;
    };

    return (
        <Box sx={{ width: '100%', mb: 4 }}>
        {/* Header */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
            {/* <IconButton sx={{ mr: 1 }}>
            <ArrowBackIcon />
            </IconButton> */}
            <Typography variant="h5" component="h1">
            User Engagement Overview
            </Typography>
        </Box>

        {/* Filters Section */}
        <Paper sx={{ p: 3, mb: 3 }}>
            <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={8}>
                <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
                <Typography variant="body1" sx={{ mr: 1 }}>
                    Date Range:
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        disableFuture
                        label="Start Date"
                        value={dateRange.startDate}
                        onChange={handleStartDateChange}
                        slotProps={{
                            textField: {
                                error: dateRange.startDate > dateRange.endDate,
                                helperText: dateRange.startDate > dateRange.endDate ? "Start date can't be after end date" : null
                            }
                        }}
                    />
                    <Typography variant="body1" sx={{ mx: 1 }}>to</Typography>
                    <DatePicker
                        disableFuture
                        label="End Date"
                        value={dateRange.endDate}
                        onChange={handleEndDateChange}
                        slotProps={{
                            textField: {
                                error: dateRange.endDate < dateRange.startDate,
                                helperText: dateRange.endDate < dateRange.startDate ? "End date can't be before start date" : null
                            }
                        }}
                    />
                </LocalizationProvider>
                <Button 
                    variant="outlined" 
                    color="primary" 
                    onClick={handleResetDateRange}
                    sx={{ height: '56px' }}
                >
                    Reset
                </Button>
                </Box>
                <Typography 
                    variant="body2" 
                    gutterBottom
                    sx={{ mt: 1 }}
                    color="text.secondary"
                >
                    Date Range: {dateDifferenceInDays()}
                </Typography>
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    onClick={handleExport}
                    disabled={dateRange.hasError}
                >
                    Export
                </Button>
            </Grid>
            </Grid>
        </Paper>

        {/* Statistics Cards */}
        <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={12} md={4}>
            <Card>
                <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography color="textSecondary" gutterBottom>
                    Messages sent
                    </Typography>
                    <Tooltip title="Total number of messages sent during the selected period">
                    <InfoIcon fontSize="small" color="action" />
                    </Tooltip>
                </Box>
                <Typography variant="h4" component="div">
                    {summaryData.messagesSent}
                </Typography>
                </CardContent>
            </Card>
            </Grid>
            
            <Grid item xs={12} md={4}>
            <Card>
                <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography color="textSecondary" gutterBottom>
                    Messages delivered
                    </Typography>
                    <Tooltip title="Total number of messages successfully delivered during the selected period">
                    <InfoIcon fontSize="small" color="action" />
                    </Tooltip>
                </Box>
                <Typography variant="h4" component="div">
                    {summaryData.messagesDelivered}
                </Typography>
                </CardContent>
            </Card>
            </Grid>
            
            <Grid item xs={12} md={4}>
            <Card>
                <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography color="textSecondary" gutterBottom>
                    Messages received
                    </Typography>
                    <Tooltip title="Total number of messages read by recipients during the selected period">
                    <InfoIcon fontSize="small" color="action" />
                    </Tooltip>
                </Box>
                <Typography variant="h4" component="div">
                    {summaryData.messagesReceived}
                </Typography>
                </CardContent>
            </Card>
            </Grid>
        </Grid>

        {/* Chart */}
        <Paper sx={{ p: 3, height: 400 }}>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Typography>Loading data...</Typography>
                </Box>
            ) : chartData.length === 0 ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column' }}>
                    <Typography variant="h6" color="text.secondary">No Data Found</Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                        No messages were recorded for the selected date range.
                    </Typography>
                </Box>
            ) : (
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                    data={chartData}
                    margin={{ top: 10, right: 30, left: 0, bottom: 10 }}
                    >
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis 
                        dataKey="date" 
                        padding={{ left: 30, right: 30 }} 
                    />
                    <YAxis />
                    <RechartsTooltip content={<CustomTooltip />} />
                    <Legend />
                    <Line
                        type="monotone"
                        dataKey="messagesSent"
                        name="Messages Sent"
                        stroke="#0d47a1"
                        strokeWidth={2}
                        dot={{ r: 5 }}
                        activeDot={{ r: 8 }}
                    />
                    <Line
                        type="monotone"
                        dataKey="messagesDelivered"
                        name="Messages Delivered"
                        stroke="#0d47a1"
                        strokeWidth={2}
                        dot={{ r: 5 }}
                        activeDot={{ r: 8 }}
                        strokeDasharray="5 5"
                    />
                    <Line
                        type="monotone"
                        dataKey="messagesReceived"
                        name="Messages Received"
                        stroke="#64b5f6"
                        strokeWidth={2}
                        dot={{ r: 5 }}
                        activeDot={{ r: 8 }}
                    />
                    </LineChart>
                </ResponsiveContainer>
            )}
        </Paper>
        </Box>
    );
}