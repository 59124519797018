import * as React from "react";
 
// importing material UI components
import { useState } from 'react';
import UserEngagementOverviewTab from './UserEngagementOverviewTab';
import Container from '@mui/material/Container';

const UserEngagementOverview = (props) => {

  return (
    <div className="App">
      <Container maxWidth={false}>
        <>
          {/* <h2 className="heading-title">User Engagement Overview</h2> */}
          <UserEngagementOverviewTab 
            refreshkey={props.refreshKey}
          />
        </>
      </Container>

    </div>
  );
}
export default UserEngagementOverview;
