import React, { useState, useEffect } from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { 
  TextField, 
  CardHeader, 
  InputAdornment, 
  Typography, 
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Modal,
  CircularProgress,
  Dialog, 
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert
} from '@mui/material';
import { Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import Search from '@mui/icons-material/Search';
import AudienceFormDialog from './AudienceFormDialog';
import AudienceFileFormDialog from './AudienceFileFormDialog';
import { AUDIENCE_GET_ENDPOINT, FILE_UPLOAD_AUDIENCE_ENDPOINT } from '../../config/urls';
import { useAuth } from '../../hooks/AuthProvider';

const columns = [
  { id: 'first_name', label: 'First Name' },
  { id: 'last_name', label: 'Last Name' },
  { id: 'country_code', label: 'Country Code' },
  { id: 'phone_no', label: 'Phone No' },
  { id: 'opted_in', label: 'Opted In' },
  { id: 'segments', label: 'Segment' },
  { id: 'email', label: 'Email' },
  { id: 'gender', label: 'Gender' },
  { id: 'age', label: 'Age' },
  { id: 'dob', label: 'DOB' },
  { id: 'action', label: 'Action' },
];

export default function AudienceTable({ refreshKey, searchQuery, updateRefreshKey }) {
  const { getValidToken } = useAuth();
  const [rowCount, setRowCount] = useState(0);
  const [audienceData, setAudienceData] = useState([]);
  const [audienceModalState, setAudienceModalState] = useState({ isOpen: false, selectedClient: null, mode: 'add', jsonData: '' });
  const [audienceFileModalState, setAudienceFileModalState] = useState({ isOpen: false, mode: 'add', jsonData: '' });
  const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:8000/api`;
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 20
  });
  const [allSegments, setAllSegments] = useState([]);
  const [activeSegments, setActiveSegments] = useState([]);
  const [allSegmentsDetails, setAllSegmentsDetails] = useState([]);
  const [allActiveSegmentsDetails, setAllActiveSegmentsDetails] = useState([]);
  const [segmentFilter, setSegmentFilter] = useState('');
  const [showAllErrors, setShowAllErrors] = useState(false);
  const sampleFileURL = "/sample.csv"; // File should be inside `public/sample.pdf`
  const userRole = localStorage.getItem('role');

  const audienceAddPermission = "audience.add";
  const audienceEditPermission = "audience.change";
  const permissions = localStorage.getItem("permissions") || [];

  // const hasReadPermission = permissions.includes(usersReadPermission);
  const hasAddPermission = permissions.includes(audienceAddPermission);
  const hasEditPermission = permissions.includes(audienceEditPermission);
  // const canAccessAI = hasReadPermission && hasAccessPermission;

  const [allZonesDetails, setAllZonesDetails] = useState([]);

  const [open, setOpen] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]; // Only allow a single file
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setError(null);
  };


  const handleUpload = async () => {
    if (!file) return;
  
    if (file.type !== "text/csv") {
      setError({ summary: "Invalid file type. Please upload a CSV file.", details: [] });
      return;
    }
  
    if (file.size > 10 * 1024 * 1024) {
      setError({ summary: "File size exceeds 10MB. Please upload a smaller file.", details: [] });
      return;
    }
  
    setLoading(true);
    setError(null);
  
    try {
      const formData = new FormData();
      formData.append("file", file);
      const tenant = localStorage.getItem("tenant");
      const user = JSON.parse(localStorage.getItem("user")); // Parse JSON string into an object
      if (user && user.id) {
          formData.append("user", user.id);
      } else {
          console.error("User ID not found in localStorage.");
      }
        
      const response = await fetch(FILE_UPLOAD_AUDIENCE_ENDPOINT, {
        method: "POST",
        body: formData,
        headers: {
          "X-Tenant-ID": tenant,
        },
      });
  
      const data = await response.json(); // Convert response to JSON
  
      if (!response.ok) {
        if (data.errors && data.errors.file) {
          const errorSummary = data.errors.file.error_summary || "File upload failed due to validation errors.";
          const errorDetails = data.errors.file.errors || [];
          throw { summary: errorSummary, details: errorDetails };
        } else {
          throw { summary: "File upload failed. Please try again.", details: [] };
        }
      }
  
      // Reset state on success
      fetchData();
      
      setFile(null);
      setError(null);
      handleClose();
    } catch (err) {
      setError({ summary: err.summary || "An error occurred.", details: err.details || [] });
    } finally {
      setLoading(false);
    }
  };
  

  const segmentOptions = [...new Set(allSegments)].map(segment => (
    <MenuItem key={segment} value={segment}>{segment}</MenuItem>
  ));

  const handleSegmentFilterChange = (event) => {
    setSegmentFilter(event.target.value);
  };

  const handleCreateSegmentButton = () => {
    setAudienceFileModalState({ isOpen: true, mode: 'add', jsonData: '' });
  };

  const handleEditSegmentButton = (audience) => {
    setAudienceModalState({ isOpen: true, selectedClient: null, mode: 'edit_segment', jsonData: '' });
  };

  const fetchData = async () => {
    let searchDateURL = '';

    // Construct query parameters for server-side filtering and pagination
    const queryParams = new URLSearchParams({
      limit: controller.rowsPerPage.toString(),
      offset: (controller.serverSidePage * controller.rowsPerPage).toString(),
      search_query: searchQuery
    });

    const token = await getValidToken();
    const tenant = localStorage.getItem('tenant');

    try {
      const response = await fetch(`${AUDIENCE_GET_ENDPOINT}?${queryParams}`, {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
          "X-Tenant-Id": tenant
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const jsonData = await response.json();

      console.log("GET API response: ", jsonData);

      setAudienceData(jsonData.results);
      setRowCount(jsonData.count);

      setAllSegments(jsonData.all_segments_details.map(segment => segment.segment_name));
      setActiveSegments(jsonData.all_segments_details.filter(segment => segment.active === true).map(segment => segment.segment_name));
      setAllSegmentsDetails(jsonData.all_segments_details);
      setAllActiveSegmentsDetails(jsonData.all_segments_details.filter(segment => segment.active === true));

      setAllZonesDetails(jsonData.all_zones_details);
      
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, [
    controller.serverSidePage, 
    controller.rowsPerPage, 
    refreshKey,
    searchQuery
  ]);

  // pagination
  const handlePageChange = (event, newPage) => {
    setController(prev => ({
      ...prev,
      page: newPage,
      serverSidePage: newPage  // Sync server-side page with UI page
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...prev => ({
        ...prev,
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
        serverSidePage: 0  // Reset to first page
      })
    });
  };

  const handleEditClick = (audience) => {
    setAudienceModalState({ isOpen: true, selectedClient: audience, mode: 'update_customer_segments' });
  };

  // const filteredAudience  = audienceData.filter((audience) => {

  //   const searchQueryMatch = audience.first_name.toLowerCase().includes(searchQuery.toLowerCase());

  //   return searchQueryMatch;
  // });

  return (
    <div>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, ml: 2 }}>
            {/* <FormControl sx={{ width: '200px', mt: 1 }}>
              <InputLabel sx={{ 
                display: 'flex', 
                width: '100%',
                // position: 'absolute',
                // top: '-6px',
              }}>
                Segments
              </InputLabel> 
              <Select
                value={segmentFilter}
                onChange={handleSegmentFilterChange}
                displayEmpty
                // size="small"
              >
                {segmentOptions}
              </Select>
            </FormControl> */}
          </Box>
          <Box sx={{ mt: 1 }}>
          {hasAddPermission && (
            <>
              <Button variant="contained" sx={{ marginRight: 2 }} onClick={handleOpen}>
                <CloudUploadIcon sx={{ mr: 1 }} /> Upload CSV
              </Button>
              <Button variant="contained" sx={{ marginRight: 2 }} onClick={handleEditSegmentButton}>
                <CreateIcon />
              </Button>
            </>
          )}
        </Box>
        </Box>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="left"
                    style={{ minWidth: column.minWidth, border: '1px solid rgba(224, 224, 224, 1)' }}
                  >
                    <h3>{column.label}</h3>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {audienceData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    No match found
                  </TableCell>
                </TableRow>
              ) : (
                audienceData.map((audience, index) => (
                  <TableRow key={index}>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{audience.first_name}</TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{audience.last_name}</TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)', width: '6%' }}>{audience.country_code}</TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{audience.phone_no}</TableCell>
                    <TableCell align="center" style={{ border: '1px solid rgba(224, 224, 224, 1)', width: '7%' }}>
                      {audience.opted_in === 1 ? 
                        <CheckCircleIcon sx={{ color: 'green' }} /> :
                        audience.opted_in === 0 ?
                          <CancelIcon sx={{ color: 'red' }} /> :
                          <CancelIcon sx={{ color: 'red' }} />
                      }
                    </TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                      {audience.segments ? 
                        audience.segments.join(', ') :
                          "Unknown"
                      }
                    </TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{audience.email}</TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{audience.gender}</TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{audience.age}</TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{audience.dob}</TableCell>
                    <TableCell align="center" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                      {
                        hasEditPermission && (
                          <Button variant="contained" onClick={() => handleEditClick(audience)}>EDIT</Button>
                        )
                      }
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20]}
          component="div"
          count={rowCount}
          rowsPerPage={controller.rowsPerPage}
          page={controller.page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <AudienceFormDialog
          modalState={audienceModalState}
          handleModalState={setAudienceModalState}
          selectedClient={audienceModalState.selectedClient}
          allSegments={allSegments}
          activeSegments={activeSegments}
          allSegmentsDetails={allSegmentsDetails}
          allActiveSegmentsDetails={allActiveSegmentsDetails}
          allZonesDetails={allZonesDetails}
          fetchData={fetchData}
        />
        <AudienceFileFormDialog
          modalState={audienceFileModalState}
          handleModalState={setAudienceFileModalState }
        />
      </Paper>
      
<Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Upload File</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center" gap={2} p={2} width="100%">
        <Typography variant="body2" component="span">
          Click{" "}
          <Typography
            variant="body2"
            component="a"
            href={sampleFileURL}
            download="sample.csv"
            style={{ textDecoration: "none", cursor: "pointer", color: "blue" }}
          >
            here
          </Typography>{" "}
          to download sample file
        </Typography>
          {/* Hidden File Input */}
          <input
            type="file"
            accept="text/csv"
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="file-input"
          />
          <label htmlFor="file-input">
            <Button variant="contained" component="span" startIcon={<CloudUploadIcon />} disabled={loading}>
              Select File
            </Button>
          </label>

          {/* Display Selected File */}
          {file && (
            <Box textAlign="center">
              <Typography variant="subtitle1">Selected File:</Typography>
              <Typography variant="body2">{file.name}</Typography>
            </Box>
          )}

          {/* Error Alert */}
          {error && (
            <Alert severity="error" sx={{ width: "100%" }}>
              <Typography variant="body1" fontWeight="bold">
                {error.summary}
              </Typography>
              {error.details.length > 0 && (
                <ul style={{ margin: 0, paddingLeft: "20px" }}>
                  {(showAllErrors ? error.details : error.details.slice(0, 5)).map((errMsg, index) => (
                    <li key={index}>{errMsg}</li>
                  ))}
                </ul>
              )}
              {error.details.length > 5 && (
                <Typography
                  variant="body2"
                  sx={{ cursor: "pointer", color: "blue", textDecoration: "underline", mt: 1 }}
                  onClick={() => setShowAllErrors(!showAllErrors)}
                >
                  {showAllErrors ? "Show less..." : "Read more..."}
                </Typography>
              )}
            </Alert>
          )}
        </Box>
      </DialogContent>
      
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleUpload} variant="contained" color="primary" disabled={!file || loading}>
          {loading ? <CircularProgress size={24} /> : "Upload"}
        </Button>
      </DialogActions>
    </Dialog>
    </div>
  );
}
