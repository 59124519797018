import React, { useState, useEffect, useRef } from 'react';
import {
    Grid,
    TextField,
    Button,
    Typography,
    IconButton,
    Stack
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const UploadCSV = ({
        campaignName, setCampaignName,
        campaignDescription, setCampaignDescription,
        csvFile, setCsvFile,
        campaignsData
    }) => {
    const [error, setError] = useState(null);
    const fileInputRef = useRef(null);
    const sampleFileURL = "/sample.csv";
    
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (!file.name.endsWith('.csv')) {
                setError('Please upload a CSV file');
                setCsvFile(null);
            } else {
                setError(null);
                setCsvFile(file);
            }
        }
    };

    const handleDeleteHeaderFile = () => {
        setCsvFile(null);
        // Reset the file input so the same file can be selected again
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleNameChange = (event) => {
        const newName = event.target.value;
        
        if (newName === '' || newName.trim().length > 0) {
            if (newName.length <= 50) {
                setCampaignName(newName);
            }
        }
    };
    
    const handleDescriptionChange = (event) => {
        const newDescription = event.target.value;
        
        if (newDescription === '' || newDescription.trim().length > 0) {
            if (newDescription.length <= 250) {
                setCampaignDescription(newDescription);
            }
        }
    };

    useEffect(() => {
        const isDuplicate = campaignsData.some(campaign => campaign.toLowerCase() === campaignName.toLowerCase());
        setError(isDuplicate);
    }, [csvFile, campaignName]);
    
    return (
        <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>

            {/* Campaign Name */}
            <Grid item xs={12}>
                <div style={{ position: 'relative', width: '100%', marginTop: 2, marginBottom: 2 }}>
                    {/* <Typography variant="h6" sx={{ marginBottom: 1, mt: 1 }}>
                        Campaign Name *
                    </Typography> */}
                    <TextField
                        label="Campaign Name"
                        value={campaignName}
                        onChange={handleNameChange}
                        fullWidth
                        error={error}
                        helperText={error ? "Campaign name must be unique." : ""}
                        required
                    />
                    <Typography 
                        variant="body2" 
                        sx={{ 
                            color: 'gray', 
                            position: 'absolute', 
                            right: '10px', 
                            top: '10px',
                            pointerEvents: 'none'
                        }}>
                        {`${campaignName.length}/50`}
                    </Typography>
                </div>
            </Grid>

            {/* Campaign Description */}
            <Grid item xs={12}>
                <div style={{ position: 'relative', width: '100%', marginTop: 2, marginBottom: 2 }}>
                    {/* <Typography variant="h6" sx={{ marginBottom: 1, mt: 1 }}>
                        Campaign Description
                    </Typography> */}
                    <TextField
                        label="Campaign Description"
                        multiline
                        rows={4}
                        value={campaignDescription}
                        onChange={handleDescriptionChange}
                        fullWidth
                    />
                    <Typography 
                        variant="body2" 
                        sx={{ 
                            color: 'gray', 
                            position: 'absolute', 
                            right: '10px', 
                            top: '10px',
                            pointerEvents: 'none'
                        }}>
                        {`${campaignDescription.length}/250`}
                    </Typography>
                </div>
            </Grid>
            
            {/* Upload CSV */}
            <Grid item xs={12}>
                <Typography variant="h6" sx={{ marginBottom: 1, mt: 1 }}>
                    Upload CSV *
                </Typography>
            </Grid>
            <Grid item xs={12}>
            <Stack>
                <Typography variant="body2" component="span">
                    Click{" "}
                    <Typography
                        variant="body2"
                        component="a"
                        href={sampleFileURL}
                        download="sample.csv"
                        style={{ textDecoration: "none", cursor: "pointer", color: "blue" }}
                    >
                        here
                    </Typography>{" "}
                    to download sample file
                </Typography>
                <label htmlFor="csv-file-upload">
                    <Button
                        variant="contained"
                        component="span"
                        sx={{ mt: 1 }}
                    >
                        Choose .CSV file*
                    </Button>
                </label>
                
            </Stack>
                <input
                    id="csv-file-upload"
                    type="file"
                    accept=".csv"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                />
                {error && <Typography color="error">{error}</Typography>}
                {csvFile && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2">
                            Selected File: {csvFile.name ?? ''}
                        </Typography>
                        <IconButton
                            aria-label="delete"
                            onClick={handleDeleteHeaderFile}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </div>
                )}
            </Grid>
        </Grid>
    );
};
export default UploadCSV;