import * as React from "react";
 
// importing material UI components
import { useState } from 'react';
import Container from '@mui/material/Container';

import RMDashboardTab from "./RMDashboardTab";

const RMDashboard = (props) => {

  return (
    <div className="App">
      <Container maxWidth={false}>
        <>
          <RMDashboardTab />
        </>
      </Container>

    </div>
  );
}
export default RMDashboard;