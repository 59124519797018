import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { CopyAll } from '@mui/icons-material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs, oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';

const WAChatLink = () => {
  const [phoneNumber, setPhoneNumber] = useState('9999999999');
  const [countryCode, setCountryCode] = useState('+91');
  const [message, setMessage] = useState('Sample Text');
  const [generatedScript, setGeneratedScript] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState("");
  const countryPhoneLengths = {
    "+1": 10, // USA, Canada
    "+44": 10, // UK
    "+91": 10, // India
    "+971": 9, // UAE
    "+61": 9, // Australia
  };

  const handleGenerateScript = () => {
    if (!phoneNumber || !message) {
      window.alert('Please fill in all fields.');
      return;
    }
    if (message.length > 100) {
      setErrorMessage('Message cannot exceed 100 characters.');
      return;
    }
    setErrorMessage('');
    const script = `https://api.whatsapp.com/send/?phone=${countryCode}${phoneNumber}&text=${encodeURIComponent(message.trim())}`;
    setGeneratedScript(script.trim());
    setShowAlert(true);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(generatedScript).then(() => {
      window.alert('Link copied to clipboard!');
    });
  };

  const validatePhoneNumber = (phone, code = countryCode) => {
    const cleanedPhone = phone.replace(/\D/g, ""); // Remove non-digit characters
    const expectedLength = countryPhoneLengths[code];
      
      if (cleanedPhone.length !== expectedLength) {
      // setGeneratedScript("");
      // setShowAlert(false);
      return `Phone number must be ${expectedLength} digits long for ${code}.`;
        }
    return "";
      };

  const handleCountryCodeChange = (e) => {

    const newCountryCode = e.target.value;
    setCountryCode(newCountryCode);
    // Validate phone number length for new country code
    const errorMessage = validatePhoneNumber(phoneNumber, newCountryCode);
    setError(errorMessage);
  };
  
  const handlePhoneNumberChange = (e) => {
    const input = e.target.value;
    setPhoneNumber(input);
    setError(validatePhoneNumber(input));
  };

  const handleAlertClose = () => {
    setShowAlert(false);
  };

  useEffect(() => {
      let timeoutId;
      
      if (showAlert) {
        timeoutId = setTimeout(() => {
            handleAlertClose();
        }, 5000);
      }
      
      // Cleanup function to clear the timeout when component unmounts or when alert changes
      return () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
      };
  }, [showAlert]);


  return (
    <Card sx={{ maxWidth: 1100, margin: 'auto', mt: 2, p: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Generate WhatsApp Widget Script
        </Typography>
        <Box sx={{ mb: 2 }}>
        <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Country Code</InputLabel>
            <Select
              value={countryCode}
              onChange={handleCountryCodeChange}
              label="Country Code"
              // required={true}
            >
              {Object.keys(countryPhoneLengths).map((code) => (
                <MenuItem key={code} value={code}>
                  {code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Phone Number"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            onKeyDown={(e) => {
              if (!/^\d$/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete") {
                e.preventDefault();
              }
            }}
            placeholder="Enter phone number"
            error={!!error}
            helperText={error}
            sx={{ mb: 2 }}
            inputProps={{ maxLength: 10 }}
            required={true}
          />
          {/* <TextField
            fullWidth
            label="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter phone number"
            sx={{ mb: 2 }}
          /> */}
          <TextField
            fullWidth
            label="Welcome Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Enter default message"
            sx={{ mb: 2 }}
            multiline="true"
            rows={2}
            inputProps={{ maxLength: 100 }}
            helperText={message.trim() === "" ? "Message is required." : "Maximum length: 100 characters."}
            error={message.trim() === ""}
            required={true}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGenerateScript}
          disabled={(!message.trim()) || error}
        >
          Generate Link
        </Button>
        {showAlert && (
          <Alert severity="success" onClose={() => setShowAlert(false)} sx={{ mt: 2 }}>
            Script generated successfully! Copy and paste it into your project.
          </Alert>
        )}
        {generatedScript && (
          <Box sx={{ mt: 2, position: 'relative' }}>
            <SyntaxHighlighter
              language="html"
              style={oneLight}
              customStyle={{
                padding: '15px',
                borderRadius: '5px',
                backgroundColor: '#f9f9f9',
              }}
            >
              {generatedScript}
            </SyntaxHighlighter>
            <IconButton
              onClick={handleCopyToClipboard}
              sx={{
                position: 'absolute',
                top: 6,
                right: 6,
                color: 'white',
                backgroundColor: '#25d366',
                '&:hover': { backgroundColor: '#1da851' },
              }}
            >
              <CopyAll />
            </IconButton>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default WAChatLink;
