// import React, { useState, useEffect } from 'react';
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   IconButton,
//   Button,
//   Collapse,
//   Alert,
//   Box,
//   Grid,
//   Select,
//   MenuItem,
//   TextField,
//   FormControlLabel,
//   Checkbox,
//   CircularProgress,
//   Typography,
// } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import { styled } from '@mui/material/styles';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import axios from 'axios';

// import { 
//   AUDIENCE_UPDATE_ENDPOINT,
//   SEGMENT_CREATE_ENDPOINT,
//   SEGMENT_EDIT_ENDPOINT,
//   AUDIENCE_FILE_UPLOAD_ENDPOINT
// } from '../../config/urls';

// import { useAuth } from '../../hooks/AuthProvider';

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   '& .MuiDialogContent-root': {
//     padding: theme.spacing(2),
//   },
//   '& .MuiDialogActions-root': {
//     padding: theme.spacing(1),
//   },
// }));

// const AudienceFileFormDialog = ({ modalState, handleModalState }) => {
  
//   const { getValidToken } = useAuth();
  
//   const [files, setFiles] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const tenant = localStorage.getItem("tenant");

//   const handleFileChange = (event) => {
//     const selectedFiles = event.target.files;
//     if (selectedFiles && selectedFiles.length > 0) {
//       setFiles(Array.from(selectedFiles));
//     }
//   };

//   const handleClose = () => {
//     handleModalState({ isOpen: false });
//     // handleAlertClose();
//   };

//   const handleUpload = async () => {
//     if (files.length === 0) {
//       alert("No files selected");
//       return;
//     }
  
//     setLoading(true);
//     const uploadFile = async (file) => {
//       const formData = new FormData();
//       formData.append("file", file);
  
//       try {
//         const response = await fetch(AUDIENCE_FILE_UPLOAD_ENDPOINT, {
//           method: "POST",
//           body: formData,
//           headers: {
//           "X-Tenant-Id": tenant,  // ✅ Moved inside the headers object
//           },
//         });
//         return response.ok ? file.name : null;
//       } catch {
//         return null;
//       }
//     };
  
//     const results = await Promise.all(files.map(uploadFile));
  
//     alert(`Uploaded: ${results.filter(Boolean).length} files`);
//     setFiles([]);
//     setLoading(false);
//   };


//   return (
//     <BootstrapDialog
//       onClose={handleClose}
//       aria-labelledby="customized-dialog-title"
//       open={modalState.isOpen}
//       maxWidth="sm"
//       fullWidth
//     >
//       <DialogTitle sx={{ m: 0, p: 2, position: 'relative' }} id="customized-dialog-title">
//         Upload CSV

//         <IconButton
//           aria-label="close"
//           onClick={handleClose}
//           sx={{
//             position: 'absolute',
//             right: 0,
//             top: 8,
//             color: (theme) => theme.palette.grey[500],
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//       </DialogTitle>


//       <DialogContent dividers>
//       <Box display="flex" flexDirection="column" alignItems="center" p={2}>
//       <input
//         type="file"
//         multiple
//         accept="image/*,video/*,application/pdf"
//         onChange={handleFileChange}
//         style={{ display: "none" }}
//         id="file-input"
//       />
//       <label htmlFor="file-input">
//         <Button variant="contained" component="span" startIcon={<CloudUploadIcon />}
//           disabled={loading}>
//           Select Files
//         </Button>
//       </label>
//       {files.length > 0 && (
//         <Box mt={2}>
//           <Typography variant="subtitle1">Selected Files:</Typography>
//           <ul>
//             {files.map((file, index) => (
//               <li key={index}>{file.name}</li>
//             ))}
//           </ul>
//         </Box>
//       )}
//       <Button
//         variant="contained"
//         color="primary"
//         onClick={handleUpload}
//         disabled={files.length === 0 || loading}
//         sx={{ mt: 2 }}
//       >
//         {loading ? <CircularProgress size={24} /> : "Upload Files"}
//       </Button>
//     </Box>
//       </DialogContent>
//     </BootstrapDialog>
//   );
// }

// export default AudienceFileFormDialog;


import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  Box,
  CircularProgress,
  Typography,
  Collapse,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';

import { AUDIENCE_FILE_UPLOAD_ENDPOINT } from '../../config/urls';
import { useAuth } from '../../hooks/AuthProvider';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
}));

const AudienceFileFormDialog = ({ modalState, handleModalState }) => {
  const { getValidToken } = useAuth();
  
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  
  const tenant = localStorage.getItem("tenant");

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      setFiles(Array.from(selectedFiles));
    }
  };

  const handleClose = () => {
    handleModalState({ isOpen: false });
    setSuccessMessage('');
    setErrorMessage('');
    setFiles([]);
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      setErrorMessage("No files selected");
      return;
    }
    
    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');

    const uploadFile = async (file) => {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await fetch(AUDIENCE_FILE_UPLOAD_ENDPOINT, {
          method: "POST",
          body: formData,
          headers: {
            "X-Tenant-Id": tenant,
          },
        });
        
        if (!response.ok) {
          throw new Error(`Failed to upload ${file.name}`);
        }
        return file.name;
      } catch (error) {
        return { file: file.name, error: error.message };
      }
    };

    const results = await Promise.all(files.map(uploadFile));
    
    const successfulUploads = results.filter((res) => typeof res === 'string');
    const failedUploads = results.filter((res) => typeof res === 'object');

    if (successfulUploads.length > 0) {
      setSuccessMessage(`Successfully uploaded ${successfulUploads.length} files`);
    }
    
    if (failedUploads.length > 0) {
      setErrorMessage(`Failed to upload: ${failedUploads.map(f => f.file).join(', ')}`);
    }

    setFiles([]);
    setLoading(false);
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={modalState.isOpen}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2, position: 'relative' }} id="customized-dialog-title">
        Upload CSV
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Collapse in={!!successMessage}>
          <Alert
            severity="success"
            sx={{ mb: 2 }}
            action={
              <IconButton size="small" onClick={() => setSuccessMessage('')}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {successMessage}
          </Alert>
        </Collapse>

        <Collapse in={!!errorMessage}>
          <Alert
            severity="error"
            sx={{ mb: 2 }}
            action={
              <IconButton size="small" onClick={() => setErrorMessage('')}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {errorMessage}
          </Alert>
        </Collapse>

        <Box display="flex" flexDirection="column" alignItems="center" p={2}>
          <input
            type="file"
            multiple
            accept="image/*,video/*,application/pdf"
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="file-input"
          />
          <label htmlFor="file-input">
            <Button variant="contained" component="span" startIcon={<CloudUploadIcon />}
              disabled={loading}>
              Select Files
            </Button>
          </label>

          {files.length > 0 && (
            <Box mt={2}>
              <Typography variant="subtitle1">Selected Files:</Typography>
              <ul>
                {files.map((file, index) => (
                  <li key={index}>{file.name}</li>
                ))}
              </ul>
            </Box>
          )}

          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            disabled={files.length === 0 || loading}
            sx={{ mt: 2 }}
          >
            {loading ? <CircularProgress size={24} /> : "Upload Files"}
          </Button>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
}

export default AudienceFileFormDialog;
