import React, { useState, useEffect } from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import dayjs from 'dayjs';
import { WHATSAPP_META_CARD_ENDPOINT } from '../../config/urls';
import { useAuth } from '../../hooks/AuthProvider';

const tenant = localStorage.getItem('tenant');

const columns = [
  { id: 'message_type', label: 'Message Type' },
  { id: 'rate', label: `Rate (${tenant === 'carepms' ? 'INR' : 'USD'})` },
  { id: 'description', label: 'Description' },
];

export default function MetaCardTable({ refreshKey, dateInput }) {
    const { getValidToken } = useAuth();
    const [rowCount, setRowCount] = useState(0);
    
    const [metaRateData, setMetaRateData] = useState([]);

    const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:8000/api`;
    const [controller, setController] = useState({
        page: 0,
        rowsPerPage: 20
    });

    const fetchMetaRateData = async () => {
        let searchDateURL = '';
        if (dateInput) {
        let dateString = dayjs(dateInput).format("YYYY-MM-DD");
        searchDateURL = `&search_date=${dateString}`;
        }

        // Construct query parameters for server-side filtering and pagination
        const queryParams = new URLSearchParams({
        limit: controller.rowsPerPage.toString(),
        offset: (controller.serverSidePage * controller.rowsPerPage).toString()
        });

        const token = await getValidToken();

        const tenant = localStorage.getItem('tenant');
        const headers = {
            "Content-Type": "application/json",
            "X-Tenant-Id": tenant,
            'Authorization': `Bearer ${token}`
        };

        try {
        const response = await fetch(`${WHATSAPP_META_CARD_ENDPOINT}?${queryParams}${searchDateURL}`, {
            headers: headers
        });
        if (!response.ok) {
            throw new Error('Failed to fetch rate data');
        }
        const jsonData = await response.json();

        console.log("GET Meta Rate Data: ", jsonData);

        setMetaRateData(jsonData.results);
        setRowCount(jsonData.count);
        } catch (error) {
        console.error('Error fetching rate data:', error.message);
        }
    };
  
    useEffect(() => {
        fetchMetaRateData();
    }, [
        controller.serverSidePage, 
        controller.rowsPerPage, 
        refreshKey, 
        dateInput, 
    ]);

    // pagination
    const handlePageChange = (event, newPage) => {
        setController(prev => ({
        ...prev,
        page: newPage,
        serverSidePage: newPage  // Sync server-side page with UI page
        }));
    };

    const handleChangeRowsPerPage = (event) => {
        setController({
        ...prev => ({
            ...prev,
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
            serverSidePage: 0  // Reset to first page
        })
        });
    };

    return (
        <div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align="left"
                                    style={{ minWidth: column.minWidth, border: '1px solid rgba(224, 224, 224, 1)' }}
                                >
                                    <h3>{column.label}</h3>
                                </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {metaRateData.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={columns.length} align="center">
                                    No match found
                                </TableCell>
                            </TableRow>
                        ) : (
                            metaRateData.map((rateCard, index) => (
                            <TableRow key={index}>
                                <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }} sx={{ width: '15%' }} >{rateCard.message_type}</TableCell>
                                <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }} sx={{ width: '15%' }} >{rateCard.rate}</TableCell>
                                <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{rateCard.description}</TableCell>
                            </TableRow>
                            ))
                        )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[20]}
                    component="div"
                    count={rowCount}
                    rowsPerPage={controller.rowsPerPage}
                    page={controller.page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
    }
