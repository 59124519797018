import React, { useState, useEffect } from 'react';

import {
  Grid,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Alert,
} from '@mui/material';

import EditTemplate from './EditTemplate';
import MessageNode from "./MessageNode";

import axios from 'axios';

import { TEMPLATE_CREATE_ENDPOINT } from '../../config/urls';
import { useAuth } from '../../hooks/AuthProvider';

const SetupTemplate = (props) => {
    const { getValidToken } = useAuth();
    const [templateOps, setTemplateOps] = useState('setup-template');
    const [templateType, setTemplateType] = useState('');
    const [marketingOption, setMarketingOption] = useState('');
    const [utilityOption, setUtilityOption] = useState('');
    const templateSteps = ['setup-template', 'edit-template'];

    const { templatesNamesData, setComponent } = props;

    const columns = [
        { id: 'header', label: 'Header' },
        { id: 'body', label: 'Body' },
        { id: 'footer', label: 'Footer' },
    ];

    // edit template form
    const [templateName, setTemplateName] = useState('');
    const [language, setLanguage] = useState('english');
    const [headerType, setHeaderType] = useState('none');
    const [headerText, setHeaderText] = useState('');
    const [headerFile, setHeaderFile] = useState(null);
    const [body, setBody] = useState('');
    const [sampleBodyFields, setsampleBodyFields] = useState([]);
    const [sampleHeaderFields, setsampleHeaderFields] = useState([]);
    const [footer, setFooter] = useState('');
    const [allButtons, setAllButtons] = useState([]);
    const [isButtonsValid, setIsButtonsValid] = useState(true);
    const [error, setError] = useState(false);

    const [formData, setFormData] = useState({
        templateName: '',
        language: '',
        headerType: '',
        headerText: '',
        headerFile: '',
        sampleHeaderFields: [],
        body: '',
        sampleBodyFields: [],
        footer: '',
        marketingOption: '',
        templateType: '',
        allButtons: [],
    });

    const [alertObj, setAlertObj] = useState({
        active: false,
        msg: "",
        type: "",
        statusCode: ""
    });

    const handleSuccessAlert = () => {
        setAlertObj({
        active: true,
        type: "success",
        msg: "Template submitted successfully",
        statusCode: 201
        });
        setComponent(1);
    };
      
    const handleErrorAlert = (statusCode, responseText) => {
        setAlertObj({
        active: true,
        type: "error",
        msg: `Failed to submit template: ${responseText}`,
        statusCode: statusCode
        });
    };

    const handleAlertClose = () => {
        setAlertObj({
        active: false,
        type: "",
        msg: "",
        statusCode: ""
        });
    };

    useEffect(() => {
        setFormData({
        templateName,
        language,
        headerType,
        headerText,
        headerFile,
        sampleHeaderFields,
        body,
        sampleBodyFields,
        footer,
        marketingOption,
        templateType,
        allButtons,
        });
    }, [templateName, language, headerType, headerText, headerFile, sampleHeaderFields, body, sampleBodyFields, footer, marketingOption, templateType, allButtons]);

    const handleSubmitData = async(event) => {

        const apiUrl = TEMPLATE_CREATE_ENDPOINT;

        const data = { ...formData };
        data.sampleHeaderFields = (data.sampleHeaderFields.length === 0) ? "" : JSON.stringify(data.sampleHeaderFields);    // parameters issue fixed
        data.sampleBodyFields = (data.sampleBodyFields.length === 0) ? "" : JSON.stringify(data.sampleBodyFields);
        data.allButtons = (data.allButtons.length === 0) ? "" : JSON.stringify(data.allButtons);

        console.log("Payload: ", data);

        const token = await getValidToken();
        const tenant = localStorage.getItem('tenant');
        
        axios.post(apiUrl, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
                "X-Tenant-Id": tenant

            },
          })
            .then(response => {
            console.log('API Response:', response.data);
            if (response.status === 201) {
                handleSuccessAlert();
            } else {
                let message = response.data.message ? response.data.message : response.statusText;
                handleErrorAlert(response.status, message);
            }

            })
            .catch(error => {
            setAlertObj({
                active: true,
                type: "error",
                msg: `Failed to submit form: ${error.response.statusText}`,
                statusCode: error.response.status
            });
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const currentIndex = templateSteps.indexOf(templateOps);
        if (!isButtonsValid || error) {
            return;
        }
        if (currentIndex === templateSteps.length - 1) {
        // Submit the form data here
            setFormData({
                templateName,
                language,
                headerType,
                headerText,
                headerFile,
                sampleHeaderFields,
                body,
                sampleBodyFields,
                footer,
                marketingOption,
                templateType,
                allButtons,
            });
            handleSubmitData();
            console.log("Called the API");
        } else {
            setTemplateOps(templateSteps[currentIndex + 1]);
        }
    };

    const handleMarketingOptionChange = (event) => {
        setMarketingOption(event.target.value);
        setUtilityOption('');
    };

    const handleUtilityOptionChange = (event) => {
        setUtilityOption(event.target.value);
        setMarketingOption('');
    };

    const handlePrevious = () => {
        const currentIndex = templateSteps.indexOf(templateOps);
        if (currentIndex > 0) {
            setTemplateOps(templateSteps[currentIndex - 1]);
        }
    };

    useEffect(() => {
        let timeoutId;
        
        if (alertObj.active) {
        timeoutId = setTimeout(() => {
            handleAlertClose();
        }, 5000);
        }
        
        // Cleanup function to clear the timeout when component unmounts or when alertObj changes
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [alertObj]);

    return (
        <div sx={{ padding: 2 }}>
            <Typography variant="h4" sx={{ marginTop: 2, marginBottom: 2 }}>
                Create Template
            </Typography>
            <form onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                        <Grid item>
                            <Button
                            variant={templateOps === 'setup-template' ? 'contained' : 'outlined'}
                            color="primary"
                            onClick={() => setTemplateOps('setup-template')}
                            sx={{ width: 200 }}
                            >
                                Set up template
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                            variant={templateOps === 'edit-template' ? 'contained' : 'outlined'}
                            color="primary"
                            onClick={() => setTemplateOps('edit-template')}
                            sx={{ width: 200 }}
                            >
                                Edit template
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {templateOps === 'setup-template' && (
                    <Grid item xs={12}>
                    <Typography variant="h6" sx={{ marginBottom: 1 }}>
                        Set up your template
                    </Typography>
                        <RadioGroup
                            name="template-type"
                            value={templateType}
                            onChange={(event) => setTemplateType(event.target.value)}
                            row
                            >
                            <FormControlLabel value="marketing-template" control={<Radio color="primary" />} label="Marketing" />
                            <FormControlLabel value="utility-template" control={<Radio color="primary" />} label="Utility" />
                        </RadioGroup>
                        {templateType === 'marketing-template' && (
                            <Grid item xs={12}>
                                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                                    Marketing
                                </Typography>
                                <RadioGroup
                                    aria-label="marketing-option"
                                    name="marketing-option"
                                    value={marketingOption}
                                    onChange={handleMarketingOptionChange}
                                    row
                                >
                                    <FormControlLabel value="custom" control={<Radio color="primary" />} label="Custom" />
                                    <FormControlLabel value="carousel" control={<Radio color="primary" />} label="Carousel" />
                                    <FormControlLabel value="flows" control={<Radio color="primary" />} label="Flows" />
                                </RadioGroup>
                                {marketingOption === 'custom' && (
                                    <Grid item xs={12}>
                                        <Typography variant="h6" sx={{ marginBottom: 1 }}>
                                            Click 'Next' to proceed
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                        {templateType === 'utility-template' && (
                            <Grid item xs={12}>
                                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                                    Utility
                                </Typography>
                                <RadioGroup
                                    aria-label="utility-option"
                                    name="utility-option"
                                    value={utilityOption}
                                    onChange={handleUtilityOptionChange}
                                    row
                                >
                                    <FormControlLabel value="custom" control={<Radio color="primary" />} label="Custom" />
                                    <FormControlLabel value="flows" control={<Radio color="primary" />} label="Flows" />
                                </RadioGroup>
                                {utilityOption === 'custom' && (
                                    <Grid item xs={12}>
                                        <Typography variant="h6" sx={{ marginBottom: 1 }}>
                                            Click 'Next' to proceed
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </Grid>
                )}
                </Grid>
                {templateOps === 'edit-template' && (
                    (templateType === 'marketing-template' && marketingOption === 'custom') ||
                    (templateType === 'utility-template' && utilityOption === 'custom')
                ) && (
                    <>
                        <Grid item xs={12} md={2} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <EditTemplate 
                                templateType={templateType}
                                templateName={templateName}
                                setTemplateName={setTemplateName}
                                language={language}
                                setLanguage={setLanguage}
                                headerType={headerType}
                                setHeaderType={setHeaderType}
                                headerText={headerText}
                                setHeaderText={setHeaderText}
                                headerFile={headerFile}
                                setHeaderFile={setHeaderFile}
                                body={body}
                                setBody={setBody}
                                sampleBodyFields={sampleBodyFields}
                                setsampleBodyFields={setsampleBodyFields}
                                sampleHeaderFields={sampleHeaderFields}
                                setsampleHeaderFields={setsampleHeaderFields}
                                footer={footer}
                                setFooter={setFooter}
                                templatesNamesData={templatesNamesData}
                                allButtons={allButtons}
                                setAllButtons={setAllButtons}
                                isButtonsValid={isButtonsValid}
                                setIsButtonsValid={setIsButtonsValid}
                                error={error}
                                setError={setError}
                            />
                            <Grid item xs={12} md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <MessageNode 
                                    templateName={templateName}
                                    language={language}
                                    headerType={headerType}
                                    headerText={headerText}
                                    headerFile={headerFile}
                                    body={body}
                                    sampleBodyFields={sampleBodyFields}
                                    footer={footer}
                                    allButtons={allButtons}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Button type="button" variant="contained" color="primary" onClick={handlePrevious} sx={{ marginRight: 2 }}>
                            Previous
                        </Button>
                        <Button type="submit" variant="contained" color="primary">
                            {templateOps === 'edit-template' ? "Submit" : "Next"}
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                    {alertObj.active && (
                        <Alert severity={alertObj.type} onClose={handleAlertClose}>
                        {alertObj.msg}
                        </Alert>
                    )}
                </Grid>
            </form>
        </div>
    );
};
export default SetupTemplate;
