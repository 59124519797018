import * as React from "react";
 
// importing material UI components
import { useState } from 'react';
import CampaignsTable from './CampaignsTable';
import {
  Grid,
  TextField,
  Typography,
  Container,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';

const Campaigns = (props) => { 
  
  const [componentState, setComponent] = useState(1);
  
  const [searchQuery, setSearchQuery] = useState('');
  const [charCount, setCharCount] = useState(0);

  const handleSearchQueryChange = (event) => {
    if (event.target.value.length > 50) {
      return;
    }

    const value = event.target.value;
    setSearchQuery(event.target.value);
    setCharCount(value.length);
  };

  const handleCreateTemplateClick = (newState) => {
    setComponent(newState);
  };

  return (
    <div className="App">
      <Container maxWidth={false}>
        <h2 className="heading-title">Campaigns</h2>

        {/* Search Field */}
        <Grid item xs={12} md={2} mb={2}>
          <TextField
            sx={{ width: 250 }}
            size="small"
            placeholder="Search"
            title='Search by Campaign Name'
            value={searchQuery}
            onChange={handleSearchQueryChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Typography variant="caption">{charCount}/50</Typography>
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <CampaignsTable 
            refreshkey={props.refreshKey} 
            searchQuery={searchQuery} 
        />
      </Container>

    </div>
  );
}
export default Campaigns;
