// CustomMarkdownEditor.js
import React, { useState, useEffect, useRef } from 'react';
import {
Grid,
Typography,
IconButton,
Tooltip,
Divider
} from '@mui/material';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import CodeIcon from '@mui/icons-material/Code';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import UndoIcon from '@mui/icons-material/Undo';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import Picker from 'emoji-picker-react';

const CustomMarkdownEditor = ({ value, onChange, maxLength }) => {
    const [text, setText] = useState(value || '');
    const [history, setHistory] = useState([value || '']);
    const [historyIndex, setHistoryIndex] = useState(0);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const textareaRef = useRef(null);
    
    useEffect(() => {
        // Function to handle keyboard shortcuts
        const handleKeyDown = (e) => {
            // Check for Ctrl+Z (Undo)
            if (e.ctrlKey && e.key === 'z') {
                e.preventDefault(); // Prevent the browser's default undo behavior
                handleUndo();
            }
        };
    
        // Add event listener to the textarea
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.addEventListener('keydown', handleKeyDown);
        }
    
        // Cleanup: remove event listener when component unmounts
        return () => {
            if (textarea) {
                textarea.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, [historyIndex]); // Dependency on historyIndex ensures the latest state is used

    const RichTextArea = ({ value, onChange, maxLength, textareaRef }) => {
        // Function to convert markdown to HTML for display
        const convertMarkdownToDisplayHtml = (content) => {
            if (!content) return '';
            
            let html = content;
            
            // We'll preserve the markdown marks but add styling
            // Bold
            html = html.replace(/\*([^*]+)\*/g, '<span class="markdown-bold">*$1*</span>');
            
            // Italic
            html = html.replace(/_([^_]+)_/g, '<span class="markdown-italic">_$1_</span>');
            
            // Strikethrough
            html = html.replace(/~([^~]+)~/g, '<span class="markdown-strikethrough">~$1~</span>');
            
            // Monospace
            html = html.replace(/```([^`]+)```/g, '<span class="markdown-mono">```$1```</span>');
            html = html.replace(/`([^`]+)`/g, '<span class="markdown-inline-code">`$1`</span>');
            
            // Lists
            html = html.replace(/^(\* .+)$/gm, '<span class="markdown-list-item">$&</span>');
            html = html.replace(/^(- .+)$/gm, '<span class="markdown-list-item">$&</span>');
            html = html.replace(/^(\d+\. .+)$/gm, '<span class="markdown-list-item">$&</span>');
            
            // Quotes
            html = html.replace(/^(> .+)$/gm, '<span class="markdown-quote">$&</span>');
            
            return html;
        };

        // Style definitions
        const styles = `
            .markdown-bold { font-weight: bold; color: #000; }
            .markdown-italic { font-style: italic; color: #000; }
            .markdown-strikethrough { text-decoration: line-through; color: #000; }
            .markdown-mono, .markdown-inline-code { font-family: monospace; background-color: #f0f0f0; padding: 0 2px; color: #000; }
            .markdown-list-item { display: block; color: #000; }
            .markdown-quote { display: block; border-left: 3px solid #ccc; padding-left: 10px; color: #666; }
        `;

        // The editor container needs to handle input and maintain selection
        return (
            <div className="rich-text-editor-container" style={{ position: 'relative' }}>
            <style>{styles}</style>
            <div
                className="rich-text-display"
                style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                padding: '12px',
                fontFamily: 'monospace',
                whiteSpace: 'pre-wrap',
                overflow: 'auto',
                pointerEvents: 'none',
                backgroundColor: 'transparent',
                zIndex: 1
                }}
                dangerouslySetInnerHTML={{ __html: convertMarkdownToDisplayHtml(value) }}
            />
            <textarea
                ref={textareaRef}
                value={value}
                onChange={onChange}
                style={{
                width: '100%',
                height: '150px',
                padding: '12px',
                fontFamily: 'monospace',
                border: '1px solid #ddd',
                borderTop: 'none',
                resize: 'vertical',
                outline: 'none',
                whiteSpace: 'pre-wrap',
                color: 'transparent',
                caretColor: 'black',
                backgroundColor: 'transparent',
                position: 'relative',
                zIndex: 2
                }}
                spellCheck={true}
                maxLength={maxLength}
            />
            </div>
        );
    };

    // Handle text changes
    const handleChange = (e) => {
        const newText = e.target.value;
        
        if (newText.length <= maxLength) {
        setText(newText);
        
        // Add to history for undo functionality
        if (newText !== history[historyIndex]) {
            const newHistory = history.slice(0, historyIndex + 1);
            newHistory.push(newText);
            setHistory(newHistory);
            setHistoryIndex(newHistory.length - 1);
        }
        
        // Call the parent onChange
        onChange(newText);
        }
    };

    // Handle formatting button clicks
    const applyFormatting = (type) => {
        if (!textareaRef.current) return;
        
        const textarea = textareaRef.current;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const selectedText = text.substring(start, end);
        
        let formattedText = '';
        let newCursorPos = 0;
        let cursorAdjustment = 0;
        
        // Helper function to check if text already has formatting
        const hasFormatting = (text, format) => {
            switch(format) {
                case 'bold':
                    return text.startsWith('*') && text.endsWith('*');
                case 'italic':
                    return text.startsWith('_') && text.endsWith('_');
                case 'strikethrough':
                    return text.startsWith('~') && text.endsWith('~');
                case 'monospace':
                    return text.startsWith('```') && text.endsWith('```');
                case 'inline-code':
                    return text.startsWith('`') && text.endsWith('`');
                default:
                    return false;
            }
        };
        
        // Check if the selected text already has the formatting we're trying to apply
        const alreadyFormatted = hasFormatting(selectedText, type);
        
        if (alreadyFormatted) {
            // Remove formatting
            switch (type) {
                case 'bold':
                    formattedText = selectedText.substring(1, selectedText.length - 1);
                    cursorAdjustment = -2; // Adjust cursor for removed asterisks
                    break;
                case 'italic':
                    formattedText = selectedText.substring(1, selectedText.length - 1);
                    cursorAdjustment = -2; // Adjust cursor for removed underscores
                    break;
                case 'strikethrough':
                    formattedText = selectedText.substring(1, selectedText.length - 1);
                    cursorAdjustment = -2; // Adjust cursor for removed tildes
                    break;
                case 'monospace':
                    formattedText = selectedText.substring(3, selectedText.length - 3);
                    cursorAdjustment = -6; // Adjust cursor for removed backticks
                    break;
                case 'inline-code':
                    formattedText = selectedText.substring(1, selectedText.length - 1);
                    cursorAdjustment = -2; // Adjust cursor for removed backticks
                    break;
                default:
                    formattedText = selectedText;
            }
        } else {
            // Add formatting
            switch (type) {
                case 'bold':
                    formattedText = `*${selectedText}*`;
                    newCursorPos = 1;
                    cursorAdjustment = 2; // Adjust cursor for added asterisks
                    break;
                case 'italic':
                    formattedText = `_${selectedText}_`;
                    newCursorPos = 1;
                    cursorAdjustment = 2; // Adjust cursor for added underscores
                    break;
                case 'strikethrough':
                    formattedText = `~${selectedText}~`;
                    newCursorPos = 1;
                    cursorAdjustment = 2; // Adjust cursor for added tildes
                    break;
                case 'monospace':
                    formattedText = `\`\`\`${selectedText}\`\`\``;
                    newCursorPos = 3;
                    cursorAdjustment = 6; // Adjust cursor for added backticks
                    break;
                case 'inline-code':
                    formattedText = `\`${selectedText}\``;
                    newCursorPos = 1;
                    cursorAdjustment = 2; // Adjust cursor for added backticks
                    break;
                case 'bullet-list':
                    formattedText = selectedText
                    .split('\n')
                    .map(line => line ? `- ${line}` : line)
                    .join('\n');
                    newCursorPos = 2;
                    break;
                case 'number-list':
                    formattedText = selectedText
                    .split('\n')
                    .map((line, i) => line ? `${i + 1}. ${line}` : line)
                    .join('\n');
                    newCursorPos = 3;
                    break;
                case 'quote':
                    formattedText = selectedText
                    .split('\n')
                    .map(line => line ? `> ${line}` : line)
                    .join('\n');
                    newCursorPos = 2;
                    break;
                default:
                    formattedText = selectedText;
            }
        }
        
        // Insert the formatted text
        const newText = 
        text.substring(0, start) + 
        formattedText + 
        text.substring(end);
        
        setText(newText);
        
        // Add to history
        const newHistory = history.slice(0, historyIndex + 1);
        newHistory.push(newText);
        setHistory(newHistory);
        setHistoryIndex(newHistory.length - 1);
        
        // Call the parent onChange
        onChange(newText);
        
        // Set focus back to the textarea and position cursor
        setTimeout(() => {
            textarea.focus();
            if (start === end) {
                // If no text was selected, position cursor between markers
                const newPos = start + newCursorPos;
                textarea.setSelectionRange(newPos, newPos);
            } else {
                // If text was selected, select the newly formatted text
                const newStart = start;
                const newEnd = start + formattedText.length;
                textarea.setSelectionRange(newStart, newEnd);
            }
        }, 0);
    };

    // Handle undo
    const handleUndo = () => {
        if (historyIndex > 0) {
        setHistoryIndex(historyIndex - 1);
        const previousText = history[historyIndex - 1];
        setText(previousText);
        onChange(previousText);
        }
    };

    // Handle emoji selection
    const onEmojiClick = (emojiData, event) => {
        // The API of emoji-picker-react has changed, emoji data is now the first parameter
        // Check if we have valid emoji data
        if (!emojiData || !emojiData.emoji) {
            console.error("Invalid emoji data:", emojiData);
            return;
        }
        
        const emoji = emojiData.emoji;
        const textarea = textareaRef.current;
        
        if (!textarea) {
            console.error("Textarea reference is undefined");
            return;
        }
        
        const start = textarea.selectionStart;
        
        const newText = 
            text.substring(0, start) + 
            emoji + 
            text.substring(start);
        
        // Check if newText is within maxLength before updating state
        if (maxLength && newText.length > maxLength) {
            console.warn("Adding emoji would exceed maxLength");
            return;
        }
        
        setText(newText);
        
        // Add to history
        const newHistory = history.slice(0, historyIndex + 1);
        newHistory.push(newText);
        setHistory(newHistory);
        setHistoryIndex(newHistory.length - 1);
        
        // Call the parent onChange
        onChange(newText);
        
        // Close emoji picker
        setShowEmojiPicker(false);
        
        // Set focus back to textarea
        setTimeout(() => {
            textarea.focus();
            const newPos = start + emoji.length;
            textarea.setSelectionRange(newPos, newPos);
        }, 0);
    };

    return (
        <div style={{ width: '100%' }}>
        {/* Toolbar */}
        <div style={{ 
            display: 'flex', 
            alignItems: 'center', 
            padding: '8px',
            backgroundColor: '#f5f5f5',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            borderBottom: '1px solid #ddd'
        }}>
            <Tooltip title="Bold (*text*)">
            <IconButton onClick={() => applyFormatting('bold')}>
                <FormatBoldIcon />
            </IconButton>
            </Tooltip>
            
            <Tooltip title="Italic (_text_)">
            <IconButton onClick={() => applyFormatting('italic')}>
                <FormatItalicIcon />
            </IconButton>
            </Tooltip>
            
            <Tooltip title="Strikethrough (~text~)">
            <IconButton onClick={() => applyFormatting('strikethrough')}>
                <FormatStrikethroughIcon />
            </IconButton>
            </Tooltip>
            
            <Tooltip title="Monospace (```text```)">
            <IconButton onClick={() => applyFormatting('monospace')}>
                <CodeIcon />
            </IconButton>
            </Tooltip>
            
            <Divider orientation="vertical" flexItem style={{ margin: '0 8px' }} />
            
            <Tooltip title="Bulleted List (* text)">
            <IconButton onClick={() => applyFormatting('bullet-list')}>
                <FormatListBulletedIcon />
            </IconButton>
            </Tooltip>
            
            <Tooltip title="Numbered List (1. text)">
            <IconButton onClick={() => applyFormatting('number-list')}>
                <FormatListNumberedIcon />
            </IconButton>
            </Tooltip>
            
            <Tooltip title="Quote (> text)">
            <IconButton onClick={() => applyFormatting('quote')}>
                <FormatQuoteIcon />
            </IconButton>
            </Tooltip>
            
            <Tooltip title="Inline Code (`text`)">
            <IconButton onClick={() => applyFormatting('inline-code')}>
                <CodeIcon fontSize="small" />
            </IconButton>
            </Tooltip>
            
            <Divider orientation="vertical" flexItem style={{ margin: '0 8px' }} />
            
            <Tooltip title="Undo">
            <IconButton 
                onClick={handleUndo}
                disabled={historyIndex === 0}
            >
                <UndoIcon />
            </IconButton>
            </Tooltip>
            
            <Tooltip title="Emoji">
            <IconButton onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                <EmojiEmotionsIcon />
            </IconButton>
            </Tooltip>
            
            <div style={{ marginLeft: 'auto' }}>
            <Typography variant="body2" color={text.length > maxLength * 0.9 ? 'error' : 'textSecondary'}>
                {text.length}/{maxLength}
            </Typography>
            </div>
        </div>
        
        {/* Editor and Preview Layout */}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {/* Text Editor */}
            <textarea
                ref={textareaRef}
                value={text}
                onChange={handleChange}
                style={{
                    width: '100%',
                    height: '150px',
                    padding: '12px',
                    fontFamily: 'monospace',
                    border: '1px solid #ddd',
                    borderTop: 'none',
                    resize: 'vertical',
                    outline: 'none',
                }}
                spellCheck={true}
            />
        </div>
        
        {/* Emoji Picker */}
        {showEmojiPicker && (
            <div style={{ 
                position: 'absolute',
                zIndex: 1000,
                marginTop: '8px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                border: '1px solid #ddd',
                borderRadius: '4px'
            }}>
                <Picker 
                    onEmojiClick={onEmojiClick}
                    lazyLoadEmojis={true}
                />
            </div>
        )}
        </div>
    );
};

export default CustomMarkdownEditor;