import React, { useState, useEffect } from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Chip from '@mui/material/Chip';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { TextField, Menu, IconButton, Tabs, Tab, Alert } from '@mui/material';
import { MenuItem } from '@mui/material';
import { Select } from '@mui/material';
import { Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CheckIcon from '@mui/icons-material/Check';
import CampaignIcon from '@mui/icons-material/Campaign';
import PeopleIcon from '@mui/icons-material/People';
import FlagIcon from '@mui/icons-material/Flag';
import DescriptionIcon from '@mui/icons-material/Description';
import EventIcon from '@mui/icons-material/Event';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

import dayjs from 'dayjs';
import { WHATSAPP_BROADCAST_LOG_ENDPOINT } from '../../config/urls';
import { useAuth } from '../../hooks/AuthProvider';


const BroadcastCampaign = ({ refreshKey, searchQuery }) => {
  const { getValidToken } = useAuth();
  const [rowCount, setRowCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:8000/api`;
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 20
  });

  // Filter states
  const [campaignTypeFilter, setCampaignTypeFilter] = useState('');
  const [audienceFilter, setAudienceFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  
  // Menu states for filter dropdowns
  const [campaignTypeAnchorEl, setCampaignTypeAnchorEl] = useState(null);
  const [audienceAnchorEl, setAudienceAnchorEl] = useState(null);
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);
  const [templateAnchorEl, setTemplateAnchorEl] = useState(null);
  const [dateAnchorEl, setDateAnchorEl] = useState(null);
  
  // Action menu state
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [appliedDateRange, setAppliedDateRange] = useState([null, null]);
  
  // Reset all filters function
  const resetAllFilters = () => {
    setCampaignTypeFilter('');
    setAudienceFilter('');
    setStatusFilter('');
    setDateRange([null, null]);
    setAppliedDateRange([null, null]);
    // Reset to page 0
    setController(prev => ({
      ...prev,
      page: 0
    }));
  };
  
  // Open/close filter dropdowns
  const handleCampaignTypeClick = (event) => {
    setCampaignTypeAnchorEl(event.currentTarget);
  };
  
  const handleAudienceClick = (event) => {
    setAudienceAnchorEl(event.currentTarget);
  };
  
  const handleStatusClick = (event) => {
    setStatusAnchorEl(event.currentTarget);
  };
  
  const handleTemplateClick = (event) => {
    setTemplateAnchorEl(event.currentTarget);
  };
  
  const handleDateClick = (event) => {
    setDateAnchorEl(event.currentTarget);
  };
  
  // Actions menu handlers
  const handleActionClick = (event, row) => {
    setActionMenuAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };
  
  const handleActionClose = () => {
    setActionMenuAnchorEl(null);
    setSelectedRow(null);
  };
  
  // Filter handlers - updated to reset pagination immediately
  const handleCampaignTypeSelect = (type) => {
    const newValue = campaignTypeFilter === type ? '' : type;
    setCampaignTypeFilter(newValue);
    setCampaignTypeAnchorEl(null);
    // Immediately reset to page 0
    setController(prev => ({
      ...prev,
      page: 0
    }));
  };
  
  const handleAudienceSelect = (audience) => {
    const newValue = audienceFilter === audience ? '' : audience;
    setAudienceFilter(newValue);
    setAudienceAnchorEl(null);
    // Immediately reset to page 0
    setController(prev => ({
      ...prev,
      page: 0
    }));
  };
  
  const handleStatusSelect = (status) => {
    const newValue = statusFilter === status ? '' : status;
    setStatusFilter(newValue);
    setStatusAnchorEl(null);
    // Immediately reset to page 0
    setController(prev => ({
      ...prev,
      page: 0
    }));
  };
  
  const handleDateRangeApply = () => {
    setAppliedDateRange(dateRange);
    setDateAnchorEl(null);
    // Immediately reset to page 0
    setController(prev => ({
      ...prev,
      page: 0
    }));
  };
  
  const handleDateRangeCancel = () => {
    setDateRange([null, null]);
    setAppliedDateRange([null, null]);
    setDateAnchorEl(null);
    // Only reset page if we had a date range before
    if (appliedDateRange[0] && appliedDateRange[1]) {
      setController(prev => ({
        ...prev,
        page: 0
      }));
    }
  };

  // Handle pagination
  const handleChangePage = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: +event.target.value,
      page: 0,
    });
  };

  // Function to fetch campaigns data from API - with added error handling and debugging
  const fetchCampaignsData = async () => {
    setLoading(true);
    setError(null);
    
    // Construct query parameters for server-side filtering and pagination
    const queryParams = new URLSearchParams({
      limit: controller.rowsPerPage.toString(),
      offset: (controller.page * controller.rowsPerPage).toString(),
      search_query: searchQuery || '',
      // Add filters if applicable - ensure parameter names match backend expectations
      scheduing_type: campaignTypeFilter || '',
      audience_type: audienceFilter || '',
      status: statusFilter || '',
      from_date: appliedDateRange[0] ? appliedDateRange[0].format("YYYY-MM-DD") : '',
      to_date: appliedDateRange[1] ? appliedDateRange[1].format("YYYY-MM-DD") : ''
    });
    
    // Log query params for debugging
    console.log("Query params:", Object.fromEntries(queryParams.entries()));

    try {
      const token = await getValidToken();
      const tenant = localStorage.getItem('tenant');
      
      const response = await fetch(`${WHATSAPP_BROADCAST_LOG_ENDPOINT}?${queryParams}`, {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
          "X-Tenant-Id": tenant
        }
      });
      
      if (!response.ok) {
        throw new Error(`Failed to fetch campaigns data: ${response.status} ${response.statusText}`);
      }
      
      const jsonData = await response.json();
      console.log("GET Campaigns Data Response:", jsonData);
      
      // Transform the data to match the table structure
      const transformedData = jsonData.results.map(campaign => ({
        id: campaign.id,
        campaign: campaign.campaign,
        name: campaign?.campaign?.campaign_name || "Sample Campaign",
        type: campaign.scheduled_type || 'Now', // Try to get actual type if available
        audience: { 
          type: campaign.campaign.audience_type, 
          count: campaign.segments ? campaign.segments.length : 0 
        },
        status: campaign.status ? 'Success' : 'Failed',
        template: campaign.template || 'N/A',
        description: campaign.campaign_description || '',
        createdBy: campaign.broadcast_by?.name || 'Unknown',
        nextRun: campaign.created_at, // Using created_at as broadcast date
        scheduled: campaign.scheduled_datetime, 
        segments: campaign.segments ? campaign.segments.join(', ') : '',
      }));
      
      setRows(transformedData);
      setRowCount(jsonData.count);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching campaigns data:', error.message);
      setError(error.message);
      setRows([]);
      setRowCount(0);
      setLoading(false);
    }
  };

  // Effect to reset page when filters change
  useEffect(() => {
    // When any filter changes, reset to page 0
    if (campaignTypeFilter || audienceFilter || statusFilter || 
        (appliedDateRange[0] && appliedDateRange[1]) || searchQuery) {
      setController(prev => ({
        ...prev,
        page: 0
      }));
    }
  }, [campaignTypeFilter, audienceFilter, statusFilter, appliedDateRange, searchQuery]);
  
  // Effect to fetch data when any relevant state changes
  useEffect(() => {
    fetchCampaignsData();
  }, [
    controller.page, 
    controller.rowsPerPage, 
    refreshKey, 
    searchQuery, 
    campaignTypeFilter, 
    audienceFilter, 
    statusFilter, 
    appliedDateRange
  ]);

  return (
    <Box>
      {/* Error alert if API call fails */}
      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 2 }}
          onClose={() => setError(null)}
        >
          {error}
        </Alert>
      )}

      <Box sx={{ mb: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {/* Campaign Type Filter */}
        <Button 
          variant="outlined" 
          onClick={handleCampaignTypeClick}
          startIcon={<CampaignIcon />}
          endIcon={<Box component="span" sx={{ ml: 1 }}>▼</Box>}
          sx={{ textTransform: 'none', borderRadius: 1 }}
        >
          Campaign Type
          {campaignTypeFilter && (
            <Chip 
              label={campaignTypeFilter} 
              size="small" 
              onDelete={() => handleCampaignTypeSelect(campaignTypeFilter)}
              sx={{ ml: 1 }}
            />
          )}
        </Button>
        <Menu
          anchorEl={campaignTypeAnchorEl}
          open={Boolean(campaignTypeAnchorEl)}
          onClose={() => setCampaignTypeAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              width: campaignTypeAnchorEl ? campaignTypeAnchorEl.offsetWidth : undefined,
            },
          }}
        >
          <MenuItem onClick={() => handleCampaignTypeSelect('one_time')}>
            One-time
            {campaignTypeFilter === 'One-time' && <CheckIcon sx={{ ml: 1 }} color="success" />}
          </MenuItem>
          <MenuItem onClick={() => handleCampaignTypeSelect('recurring')}>
            Recurring
            {campaignTypeFilter === 'Recurring' && <CheckIcon sx={{ ml: 1 }} color="success" />}
          </MenuItem>
          <MenuItem onClick={() => handleCampaignTypeSelect('now')}>
            Now
            {campaignTypeFilter === 'Now' && <CheckIcon sx={{ ml: 1 }} color="success" />}
          </MenuItem>
        </Menu>

        {/* Audience Filter */}
        <Button 
          variant="outlined" 
          onClick={handleAudienceClick}
          startIcon={<PeopleIcon />}
          endIcon={<Box component="span" sx={{ ml: 1 }}>▼</Box>}
          sx={{ textTransform: 'none', borderRadius: 1 }}
        >
          Audience
          {audienceFilter && (
            <Chip 
              label={audienceFilter} 
              size="small" 
              onDelete={() => handleAudienceSelect(audienceFilter)}
              sx={{ ml: 1 }}
            />
          )}
        </Button>
        <Menu
          anchorEl={audienceAnchorEl}
          open={Boolean(audienceAnchorEl)}
          onClose={() => setAudienceAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              width: audienceAnchorEl ? audienceAnchorEl.offsetWidth : undefined,
              maxWidth: '500px',
            },
          }}
        >
          <MenuItem onClick={() => handleAudienceSelect('CSV')}>
            CSV
            {audienceFilter === 'CSV' && <CheckIcon sx={{ ml: 1 }} color="success" />}
          </MenuItem>
          <MenuItem onClick={() => handleAudienceSelect('Segment')}>
            Segment
            {audienceFilter === 'Segment' && <CheckIcon sx={{ ml: 1 }} color="success" />}
          </MenuItem>
          <MenuItem onClick={() => handleAudienceSelect('Single')}>
            Single Contact
            {audienceFilter === 'Single' && <CheckIcon sx={{ ml: 1 }} color="success" />}
          </MenuItem>
        </Menu>

        {/* Status Filter */}
        <Button 
          variant="outlined" 
          onClick={handleStatusClick}
          startIcon={<FlagIcon />}
          endIcon={<Box component="span" sx={{ ml: 1 }}>▼</Box>}
          sx={{ textTransform: 'none', borderRadius: 1 }}
        >
          Status
          {statusFilter && (
            <Chip 
              label={statusFilter} 
              size="small" 
              onDelete={() => handleStatusSelect(statusFilter)}
              sx={{ ml: 1 }}
            />
          )}
        </Button>
        <Menu
          anchorEl={statusAnchorEl}
          open={Boolean(statusAnchorEl)}
          onClose={() => setStatusAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              width: statusAnchorEl ? statusAnchorEl.offsetWidth : undefined,
              maxWidth: '500px',
            },
          }}
        >
          <MenuItem onClick={() => handleStatusSelect('success')}>
            Success
            {statusFilter === 'success' && <CheckIcon sx={{ ml: 1 }} color="success" />}
          </MenuItem>
          <MenuItem onClick={() => handleStatusSelect('error')}>
            Failed
            {statusFilter === 'error' && <CheckIcon sx={{ ml: 1 }} color="success" />}
          </MenuItem>
        </Menu>

        {/* Template Filter - TBD */}
        <Button 
          variant="outlined" 
          onClick={handleTemplateClick}
          startIcon={<DescriptionIcon />}
          endIcon={<Box component="span" sx={{ ml: 1 }}>▼</Box>}
          sx={{ textTransform: 'none', borderRadius: 1 }}
        >
          Template
        </Button>
        <Menu
          anchorEl={templateAnchorEl}
          open={Boolean(templateAnchorEl)}
          onClose={() => setTemplateAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              width: templateAnchorEl ? templateAnchorEl.offsetWidth : undefined,
              maxWidth: '500px',
            },
          }}
        >
          {/* Empty as per requirements */}
        </Menu>

        {/* Broadcast Date Filter */}
        <Button 
          variant="outlined" 
          onClick={handleDateClick}
          startIcon={<EventIcon />}
          endIcon={<Box component="span" sx={{ ml: 1 }}>▼</Box>}
          sx={{ textTransform: 'none', borderRadius: 1 }}
        >
          Broadcast Date
          {appliedDateRange[0] && appliedDateRange[1] && (
            <Chip 
              label={`${appliedDateRange[0].format('MM/DD/YYYY')} - ${appliedDateRange[1].format('MM/DD/YYYY')}`} 
              size="small" 
              onDelete={handleDateRangeCancel}
              sx={{ ml: 1 }}
            />
          )}
        </Button>
        <Menu
          anchorEl={dateAnchorEl}
          open={Boolean(dateAnchorEl)}
          onClose={() => setDateAnchorEl(null)}
          sx={{ width: 500 }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              width: dateAnchorEl ? dateAnchorEl.offsetWidth * 2 : undefined,
              maxWidth: '500px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography variant="subtitle2">Select date range:</Typography>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <DatePicker
                    disableFuture
                    label="From"
                    value={dateRange[0]}
                    onChange={(newValue) => {
                      setDateRange([newValue, dateRange[1]]);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <DatePicker
                    disableFuture
                    label="To"
                    value={dateRange[1]}
                    onChange={(newValue) => {
                      setDateRange([dateRange[0], newValue]);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                  <Button onClick={handleDateRangeCancel} variant="outlined">Cancel</Button>
                  <Button 
                    onClick={handleDateRangeApply} 
                    variant="contained" 
                    disabled={!dateRange[0] || !dateRange[1]}
                  >
                    Apply
                  </Button>
                </Box>
              </Box>
            </LocalizationProvider>
          </Box>
        </Menu>
        
        {/* Reset All Filters button */}
        <Button
          variant="outlined"
          onClick={resetAllFilters}
          startIcon={<FilterAltOffIcon />}
          sx={{ 
            textTransform: 'none',
            borderRadius: 1,
            ml: 'auto'
          }}
        >
          Reset Filters
        </Button>
      </Box>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Campaign Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Audience</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Template</TableCell>
                <TableCell>Campaign Description</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Broadcast DateTime</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={9} sx={{ textAlign: 'center' }}>Loading...</TableCell>
                </TableRow>
              ) : !rows.length ? (
                <TableRow>
                  <TableCell colSpan={9} sx={{ textAlign: 'center' }}>
                    {campaignTypeFilter || audienceFilter || statusFilter || 
                     (appliedDateRange[0] && appliedDateRange[1]) || searchQuery 
                      ? 'No data found with current filters'
                      : 'No data found'}
                  </TableCell>
                </TableRow>
              ) : (
                rows.map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell>{row.campaign.campaign_name}</TableCell>
                    <TableCell>
                      <Chip 
                        label={row.type} 
                        color={
                          row.type === 'recurring' ? 'primary' : 
                          row.type === 'now' ? 'success' : 
                          row.type === 'one_time' ? 'warning' : 
                          'default'
                        } 
                        variant="outlined"
                        size="small"
                      />
                    </TableCell>
                   <TableCell>
                      {row.audience.type}
                    </TableCell>
                    <TableCell>
                      <Chip 
                        label={row.status}
                        color={
                          row.status === 'Success' ? 'success' : 
                          row.status === 'Failed' ? 'error' : 
                          'warning'
                        }
                        size="small"
                      />
                    </TableCell>
                    <TableCell>{row.template?.template_name || 'N/A'}</TableCell>
                    <TableCell>{row.campaign.campaign_description || 'N/A'}</TableCell>
                    <TableCell>{row.createdBy}</TableCell>
                    <TableCell>{row.scheduled}</TableCell>
                    {/* <TableCell>{row.scheduled}</TableCell> */}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20]}
          component="div"
          count={rowCount}
          rowsPerPage={controller.rowsPerPage}
          page={controller.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

    </Box>
  );
}

export default BroadcastCampaign;