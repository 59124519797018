import React, { useState, useEffect } from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { TextField } from '@mui/material';
import { Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { 
  ORDER_ITEM_GET_ENDPOINT
} from '../../config/urls';
import { useAuth } from '../../hooks/AuthProvider';

const columns = [
  { id: 'order_id', label: 'Order ID' },
  { id: 'product_id', label: 'Product ID' },
  { id: 'currency', label: 'Currency' },
  { id: 'quantity', label: 'Quantity' },
  { id: 'amount', label: 'Amount' },
];

export default function OrderItemTable({ refreshKey }) {
  const { getValidToken } = useAuth();
  const [rowCount, setRowCount] = useState(0);
  const [productModalState, setProductModalState] = useState({ isOpen: false, selectedClient: null, mode: 'add', jsonData: '' });
  const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:8000/api`;
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 20
  });

  const [orderItemData, setOrderItemData] = useState([]);

  const handleAddProductButton = () => {
    setProductModalState({ isOpen: true, selectedClient: null, mode: 'add_product', jsonData: '' });
  };

  // initilized state variables to store data
  const [searchQuery, setSearchQuery] = useState('');

  const fetchData = async () => {
    let searchDateURL = '';

    // Construct query parameters for server-side filtering and pagination
    const queryParams = new URLSearchParams({
      limit: controller.rowsPerPage.toString(),
      offset: (controller.serverSidePage * controller.rowsPerPage).toString(),
      search_query: searchQuery
    });

    const token = await getValidToken();
    const tenant = localStorage.getItem('tenant');

    try {
      const response = await fetch(`${ORDER_ITEM_GET_ENDPOINT}?${queryParams}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json",
          "X-Tenant-Id": tenant
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const jsonData = await response.json();

      console.log("GET API response: ", jsonData);

      setOrderItemData(jsonData.results);
      setRowCount(jsonData.count);
      
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, [
    controller.serverSidePage, 
    controller.rowsPerPage, 
    refreshKey,
    searchQuery
  ]);

  // pagination
  const handlePageChange = (event, newPage) => {
    setController(prev => ({
      ...prev,
      page: newPage,
      serverSidePage: newPage  // Sync server-side page with UI page
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...prev => ({
        ...prev,
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
        serverSidePage: 0  // Reset to first page
      })
    });
  };

  const handleEditClick = (product) => {
    setProductModalState({ isOpen: true, selectedClient: product, mode: 'update_product' });
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredOrderItems  = orderItemData.filter((orderitem) => {

    const searchQueryMatch = orderitem.product_id.toLowerCase().includes(searchQuery.toLowerCase());

    return searchQueryMatch;
  });

  return (
    <div>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column',
            p: 1,
            m: 1,
            bgcolor: 'background.paper',
            borderRadius: 1,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                label="Search"
                value={searchQuery}
                onChange={handleSearchQueryChange}
              />
            </Grid>
          </Grid>

        </Box>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="left"
                    style={{ minWidth: column.minWidth, border: '1px solid rgba(224, 224, 224, 1)' }}
                  >
                    <h3>{column.label}</h3>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredOrderItems.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    No match found
                  </TableCell>
                </TableRow>
              ) : (
                filteredOrderItems.map((orderitem, index) => (
                  <TableRow key={index}>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)', width: '10%' }}>{orderitem.order_id}</TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)', width: '10%' }}>{orderitem.product_id}</TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)', width: '10%' }}>{orderitem.currency}</TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)', width: '20%' }}>{orderitem.quantity}</TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{orderitem.amount}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        
        <TablePagination
          rowsPerPageOptions={[20]}
          component="div"
          count={rowCount}
          rowsPerPage={controller.rowsPerPage}
          page={controller.page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

      </Paper>
    </div>
  );
}
